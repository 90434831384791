import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt, faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'

import MainLayout from '../../containers/MainLayout'
import ReportTable from '../../components/ReportTable'
import ErrorPage from '../ErrorPage'
import ButtonLink from '../../components/ButtonLink'

import { useList } from '../../frankentangel'

const TotalTracksDownloaded = props => {
  const currentDate = moment().format('dddd, MMM DD, YYYY')
  const [Paginator, results, error] = useList('dashboard-music-download', 'list')

  if (error) {
    return <ErrorPage error={error} />
  }

  const searchResults = results
    ? results.map(aff => aff.doc).map(aff => {
        return [
          aff.song?.song_title,
          aff.song?.album?.album_name,
          aff.song?.album?.library?.library_name,
          aff.user ? `${aff.user.firstName} ${aff.user.lastName} (${aff.user.email})` : '\xa0',
          new Date(aff.timestamp).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric' })
        ]
      })
    : null

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns'>
          <div className='column is-full'>
            <div className='columns'>
              <div className='column is-6'>
                <h1 className='title'>Tracks Downloaded</h1>
                <ButtonLink target='/' icon={faArrowLeft} linkText='Back' />
              </div>
              <div className='column is-6'>
                <h2 className='title has-text-right-tablet is-size-5-mobile'>{currentDate}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className='columns'>
          <div className='column is-full'>
            <Paginator spinner={<div className='has-text-centered my-4'><FontAwesomeIcon icon={faSyncAlt} spin size='3x' /></div>}>
              <ReportTable
                headings={['Song', 'Album', 'Library', 'User', 'Downloaded On']}
                tableData={searchResults}
              />
            </Paginator>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default TotalTracksDownloaded
