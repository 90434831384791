import React from 'react'
import { useHistory } from 'react-router-dom'
import { useFrankentangel } from '../frankentangel'
import useModal from './useModal'
import Button from './Button'

const ObjectDelete = props => {
  const { objectName, tableName, objectId, objectTitle, returnTo } = props
  const frankentangel = useFrankentangel()
  const history = useHistory()
  const [modal, showModal] = useModal()

  const handleDelete = async () => {
    const response = await showModal(
      'Delete Confirmation',
      `Are you sure you'd like to delete ${objectName} ${objectTitle}?`,
      [{
        text: 'No',
        response: 'no',
        isCancel: true
      }, {
        text: `Yes, delete ${objectName}`,
        className: 'is-danger',
        response: 'yes'
      }]
    )
    if (response !== 'yes') return
    await frankentangel.deleteDocument(tableName, objectId)
    history.push(returnTo || `/${tableName}`)
  }

  return (
    <>
      <Button onClick={handleDelete} icon={['fas', 'trash-alt']} classes={['is-dark']}>Delete</Button>
      {modal}
    </>
  )
}

export default ObjectDelete
