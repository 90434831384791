export const apiRoot = 'https://api.taamusic.com'
// export const apiRoot = 'http://localhost:3011'

export const entangelPath = `${apiRoot.startsWith('https') ? 'wss' : 'ws'}://${new URL(apiRoot).host}/frankentangel`

const stuff = { // aSsIgN oBjEcT tO a vArIaBlE bEfOrE eXpOrTiNg aS mOdUlE dEaFuLt
  apiRoot,
  entangelPath
}

export default stuff // seriously react, what the hell is the point in this?
