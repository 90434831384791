import React, { useState } from 'react'
import MainLayout from '../../containers/MainLayout'
import { useSearchableList } from '../../frankentangel'
import ButtonLink from '../../components/ButtonLink'
import InputField from '../../components/InputField'
import ContractCard from '../../components/ContractCard'
import ErrorPage from '../ErrorPage'

const Contracts = props => {
  const [searchText, setSearchText] = useState('')

  const [Paginator, result, error, loading] = useSearchableList('contracts', searchText)

  if (error) {
    return <ErrorPage error={error} />
  }

  const contracts = result ? result.map(contract => <ContractCard contract={contract.doc} key={contract.doc.id} />) : null

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns is-multiline'>
          <div className='column is-full'>
            <h1 className='title'>Contracts</h1>
            <ButtonLink target='/contracts/create' classes={['is-dark']} icon={['fas', 'file-plus']} linkText='Create Contract' />
          </div>
        </div>
        <div className='columns is-multiline'>
          <div className='column is-half'>
            <InputField loading={loading} label='Search Contracts' placeholder='Type here' value={searchText} onChange={ev => setSearchText(ev.target.value)} />
          </div>
        </div>
        <Paginator className='columns is-multiline'>
          {contracts}
        </Paginator>
      </div>
    </MainLayout>
  )
}

export default Contracts
