import React from 'react'
import { Link } from 'react-router-dom'
import MainLayout from '../containers/MainLayout'
import Button from '../components/Button'

const Error404 = props => {
  return (
    <MainLayout fullheight>
      <div className='container'>
        <div className='columns is-centered is-multiline'>
          <div className='column is-half is-offset-one-quarter'>
            <h1 className='title is-1 has-text-weight-bold'>404 Not Found</h1>
          </div>
          <div className='column is-half is-offset-one-quarter'>
            <p>We're sorry, but we couldn't find the page you were looking for</p>
          </div>
          <div className='column is-half is-offset-one-quarter'>
            <Link to='/'>
              <Button classes={['is-dark', 'has-medium-top-margin']} icon={['fas', 'redo']}>
                Return to the home page
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Error404
