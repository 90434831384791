import React from 'react'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import MainLayout from '../../containers/MainLayout'
import { useFrankentangel } from '../../frankentangel'
import FormWrapper from '../../components/FormWrapper'
import FromInputField from '../../components/FormInputField'
import FormNumberInput from '../../components/FormNumberInput'
import ExpCalculator from '../../components/ExpCalculator'
import FromTextarea from '../../components/FormTextarea'
import DatePicker from '../../components/DatePicker'
import SimpleSelect from '../../components/SimpleSelect'
import SubmitButton from '../../components/SubmitButton'
import FormErrorsList from '../../components/FormErrorsList'
import FormStatus from '../../components/FormStatus'
import FormContext from '../../components/FormContext'
import ButtonLink from '../../components/ButtonLink'

const CreateContract = props => {
  const frankentangel = useFrankentangel()
  const history = useHistory()

  const submitContractData = async values => {
    const newContract = { ...values }

    if (newContract.downloadsUnlimited) newContract.downloadsLeft = -1
    delete newContract.downloadsUnlimited

    const result = await frankentangel.createDocument('contracts', newContract)
    history.push(`/contracts/${result.id}`)
  }

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns is-multiline'>
          <div className='column is-full'>
            <h1 className='title'>Create Contract</h1>
            <ButtonLink target='/contracts' icon={['fas', 'arrow-left']} linkText='Back to Contracts' />
          </div>
        </div>
        <div className='columns'>
          <div className='column is-half'>
            <FormWrapper
              fields={yup.object({
                name: yup.string().required('Required').max(255),
                comments: yup.string().max(15000),
                startDate: yup.date().required('Required').typeError('Please provide a valid date'),
                expirationDate: yup.date().required('Required').typeError('Please provide a valid date'),
                autoRenew: yup.boolean().required('Required'),
                contractType: yup.string().required('Required').oneOf(['Cash', 'Barter', 'Barter Settlement', 'Library Rights Only']),
                minutesPerDay: yup.number().required('Required'),
                isActive: yup.boolean().required('Required'),
                downloadsLeft: yup.number().required('Required'),
                downloadsUnlimited: yup.boolean().required('Required')
              })}
              submit={submitContractData}
            >
              <FromInputField name='name' placeholder='Contract Name' label='Contract Name' iconLeft={['fas', 'file']} />
              <SimpleSelect name='contractType' opts={[{ label: 'Cash', value: 'Cash' }, { label: 'Barter', value: 'Barter' }, { label: 'Barter Settlement', value: 'Barter Settlement' }, { label: 'Library Rights Only', value: 'Library Rights Only' }]} label='Contract Type' placeholder='Choose contract type' />
              <DatePicker name='startDate' label='Start Date' />
              <DatePicker name='expirationDate' label='Expiration Date' />
              <ExpCalculator label='Term in Months' placeholder='Term in Months' base='startDate' target='expirationDate' step='1' />
              <SimpleSelect name='autoRenew' opts={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} label='Automatic Renewal' placeholder='Choose renewal method' />
              <FormContext.Consumer>
                {formValue => (
                  <>
                    <div className='mb-3' style={{ display: ['Barter', 'Barter Settlement'].includes(formValue.values.contractType) ? '' : 'none' }}>
                      <FormNumberInput name='minutesPerDay' placeholder='Minutes per Day' label='Minutes per Day' step='0.5' strictStep />
                    </div>
                    <div className='mb-3' style={{ display: ['Barter', 'Barter Settlement'].includes(formValue.values.contractType) ? 'none' : '' }}>
                      <SimpleSelect name='downloadsUnlimited' opts={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} label='Unlimited Song Downloads' placeholder='Select' />
                      <div className='mb-3' style={{ display: formValue.values.downloadsUnlimited ? 'none' : '' }}>
                        <FormNumberInput name='downloadsLeft' placeholder='Song Downloads Remaining' label='Song Downloads Remaining' step='1' strictStep />
                      </div>
                    </div>
                  </>
                )}
              </FormContext.Consumer>
              <SimpleSelect name='isActive' opts={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} label='Active' placeholder='Active status' />
              <FromTextarea name='comments' placeholde='Comments' label='Comments (visible to TAA employees only)' rows='10' />
              <FormErrorsList />
              <SubmitButton buttonText='Save' classes={['has-medium-top-margin']} />
              <FormStatus />
            </FormWrapper>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CreateContract
