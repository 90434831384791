import { useEffect } from 'react'

const RedirectPage = ({ url }) => {
  useEffect(() => {
    window.location.href = url
  }, [url])

  return 'Redirecting...'
}

export default RedirectPage
