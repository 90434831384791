const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const toWeekday = (date, day) => {
  if (!days.includes(day)) throw new TypeError(`Unknown day '${day}'`)

  const result = new Date(date)
  result.setDate(result.getDate() - (result.getDay() + 6) % 7 + days.indexOf(day))

  return result
}

export default toWeekday
