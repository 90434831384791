import React, { useState } from 'react'
import MainLayout from '../../containers/MainLayout'
import { useSearchableList } from '../../frankentangel'
import ButtonLink from '../../components/ButtonLink'
import InputField from '../../components/InputField'
import ErrorPage from '../ErrorPage'
import AccountCard from '../../components/AccountCard'

const Accounts = props => {
  const [searchText, setSearchText] = useState('')

  const [Paginator, result, error, loading] = useSearchableList('accounts', searchText)

  if (error) {
    return <ErrorPage error={error} />
  }

  const accounts = result ? result.map(account => <AccountCard cardLinkMode='edit' account={account.doc} key={account.doc.id} />) : null

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns is-multiline'>
          <div className='column is-full'>
            <h1 className='title'>Accounts</h1>
            <ButtonLink target='/accounts/create' classes={['is-dark']} icon={['fas', 'file-plus']} linkText='Create Account' />
          </div>
        </div>
        <div className='columns is-multiline'>
          <div className='column is-half'>
            <InputField loading={loading} label='Search Accounts' placeholder='Type here' value={searchText} onChange={ev => setSearchText(ev.target.value)} />
          </div>
        </div>
        <Paginator className='columns is-multiline'>
          {accounts}
        </Paginator>
      </div>
    </MainLayout>
  )
}

export default Accounts
