import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt, faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'

import MainLayout from '../../containers/MainLayout'
import ReportTable from '../../components/ReportTable'
import ErrorPage from '../ErrorPage'
import ButtonLink from '../../components/ButtonLink'

import { useList } from '../../frankentangel'

const OverdueAffidavits = props => {
  const mondayTwoWeeksAgo = moment().subtract(14, 'days').startOf('isoWeek').format('YYYY-MM-DD')
  const previousFirstDayTwoWeeksAgo = moment().startOf('isoWeek').format('dddd, MMM DD, YYYY')
  const [Paginator, results, error] = useList('dashboard-stations-with-affidavit', 'affidavitOverdueList', { week: mondayTwoWeeksAgo })

  if (error) {
    return <ErrorPage error={error} />
  }

  const searchResults = results
    ? results.map(aff => aff.doc).map(aff => {
        return [
          `${aff.account?.callsign} - ${aff.account?.name}`,
          new Date(aff.actualDate.replaceAll('-', '/')).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', weekday: 'long' }),
          aff.playbacksRequested
        ]
      })
    : null

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns'>
          <div className='column is-full'>
            <div className='columns'>
              <div className='column is-6'>
                <h1 className='title'>Overdue Affidavits 2 Weeks</h1>
                <ButtonLink target='/' icon={faArrowLeft} linkText='Back' />
              </div>
              <div className='column is-6'>
                <h2 className='title has-text-right-tablet is-size-5-mobile'>{previousFirstDayTwoWeeksAgo}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className='columns'>
          <div className='column is-full'>
            <Paginator spinner={<div className='has-text-centered my-4'><FontAwesomeIcon icon={faSyncAlt} spin size='3x' /></div>}>
              <ReportTable
                headings={['Account', 'Week', 'Air Times Requested']}
                tableData={searchResults}
              />
            </Paginator>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default OverdueAffidavits
