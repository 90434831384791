import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ButtonLink = props => {
  const { classes, target, icon, linkText } = props
  return (
    <Link to={target} className={classNames('button', classes)}>
      {icon && (<span className='icon'><FontAwesomeIcon icon={icon} /></span>)}
      <span>{linkText}</span>
    </Link>
  )
}

export default ButtonLink
