import React from 'react'
import moment from 'moment'

import StatCard from '../StatCard'
import { useAction } from '../../frankentangel'

const OverdueTwoWeeks = props => {
  const mondayTwoWeeksAgo = moment().subtract(14, 'days').startOf('isoWeek').format('YYYY-MM-DD')

  const [affidavitsOverdue,, affidavitsOverdueLoading] = useAction('dashboard-stations-assigned', 'affidavitOverdueStatus', { week: mondayTwoWeeksAgo })

  return (
    <StatCard
      title='Compliance'
      firstValue={affidavitsOverdue?.total}
      content='Overdue Affidavits 2 Weeks'
      linksTo='/reports/overdue-affidavits'
      loading={affidavitsOverdueLoading}
    />
  )
}

export default OverdueTwoWeeks
