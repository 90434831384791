import React from 'react'

import StatCard from '../StatCard'
import { useAction } from '../../frankentangel'

const OrphanedAccountsBox = props => {
  const [orphanedAccounts,, orphanedAccountsLoading] = useAction('accounts', 'numOrphaned')

  return (
    <StatCard
      title='Accounts'
      firstValue={orphanedAccounts?.total}
      content='Unlinked Accounts'
      linksTo='/reports/unlinked-accounts'
      loading={orphanedAccountsLoading}
      mode='wide'
    />
  )
}

export default OrphanedAccountsBox
