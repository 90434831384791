import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import MainLayout from '../../containers/MainLayout'
import { useFrankentangel } from '../../frankentangel'
import FormWrapper from '../../components/FormWrapper'
import FromInputField from '../../components/FormInputField'
import FromTextarea from '../../components/FormTextarea'
import SimpleSelect from '../../components/SimpleSelect'
import SubmitButton from '../../components/SubmitButton'
import FormErrorsList from '../../components/FormErrorsList'
import FormStatus from '../../components/FormStatus'
import ButtonLink from '../../components/ButtonLink'
import { Tabs, Tab } from '../../components/Tabs'

const CreateAccount = props => {
  const frankentangel = useFrankentangel()
  const [showLanguage, setShowLanguage] = useState(false)
  const history = useHistory()

  const submitAccountData = async values => {
    const result = await frankentangel.createDocument('addresses', { ...values.address })
    const { address, ...otherValues } = values
    const resultAccount = await frankentangel.createDocument('accounts', { ...otherValues, address: result.id })
    history.push(`/accounts/${resultAccount.id}`)
  }

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns is-multiline'>
          <div className='column is-full'>
            <h1 className='title'>Create Account</h1>
            <ButtonLink target='/accounts' icon={['fas', 'arrow-left']} linkText='Back to Accounts' />
          </div>
        </div>
        <div className='columns'>
          <div className='column is-half'>
            <FormWrapper
              fields={yup.object({
                name: yup.string().required('Required').max(255),
                type: yup.string().oneOf(['Radio Station', 'Retail Customer'], 'Please choose one of these values: Radio Station, Retail Customer').required('Required'),
                callsign: yup.string().when('type', {
                  is: 'Radio Station',
                  then: yup.string().matches(/^[A-Z0-9]{3,25}(?:-[A-Z0-9]{2})?$/, 'Invalid format').required('Required'),
                  otherwise: yup.string().matches(/^[A-Z0-9]{3,25}(?:-[A-Z0-9]{2})?$/, 'Invalid format')
                }),
                language: yup.string().when('type', {
                  is: 'Radio Station',
                  then: yup.string().oneOf(['English', 'Spanish'], 'Please choose one of these values: English, Spanish').required('Required'),
                  otherwise: yup.string().oneOf(['English', 'Spanish'], 'Please choose one of these values: English, Spanish')
                }),
                billingRate: yup.string().matches(/^[0-9]+(?:\.[0-9]{1,2})?$/, 'Numbers and <dot> only').required('Required'),
                tag: yup.string().max(255).required('Required'),
                comments: yup.string().max(15000),
                address: yup.object({
                  firstName: yup.string().max(255),
                  lastName: yup.string().max(255),
                  line1: yup.string().max(255).required('Required'),
                  line2: yup.string().max(255),
                  city: yup.string().max(255).required('Required'),
                  state: yup.string().max(255),
                  zip: yup.string().max(255).matches(/^\d{5}(-\d{4})?$/, 'Invalid ZIP code').required('Required'),
                  country: yup.string().max(255).required('Required'),
                  phone: yup.string().max(255).min(9, 'Minimal length is 9 chars').phone('Invalid phone number').required('Required'),
                  fax: yup.string().max(255).phone('Invalid phone number')
                })
              })}
              submit={submitAccountData}
            >
              <Tabs
                mode='is-boxed'
                headers={['Basic Information', 'Address']}
                headerIcons={[['fas', 'info-square'], ['fas', 'address-book']]}
              >
                <Tab>
                  <FromInputField name='name' placeholder='Account Name' label='Account Name' iconLeft={['fas', 'file']} />
                  <SimpleSelect
                    name='type'
                    opts={[{ label: 'Radio Station', value: 'Radio Station' }, { label: 'Retail Customer', value: 'Retail Customer' }]} label='Account Type'
                    placeholder='Choose account type'
                    onChange={(ev) => setShowLanguage(ev.target.value === 'Radio Station')}
                  />
                  <FromInputField name='callsign' placeholder='Call Letters' label='Call Letters' iconLeft={['fas', 'id-card-alt']} />
                  {showLanguage && <SimpleSelect name='language' opts={[{ label: 'English', value: 'English' }, { label: 'Spanish', value: 'Spanish' }]} label='Language' placeholder='Choose language' />}
                  <FromInputField name='billingRate' placeholder='Billing Rate' label='Billing Rate (USD)' iconLeft={['fas', 'dollar-sign']} />
                  <FromInputField name='tag' placeholder='Tag' label='Tag' iconLeft={['fas', 'tag']} />
                  <FromTextarea name='comments' placeholder='Comments' label='Comments (visible to TAA employees only)' rows='10' />
                </Tab>
                <Tab>
                  <FromInputField name='address.firstName' placeholder='First Name' label='First Name' iconLeft={['fas', 'user']} />
                  <FromInputField name='address.lastName' placeholder='Last Name' label='Last Name' iconLeft={['fas', 'user']} />
                  <FromInputField name='address.line1' placeholder='Address Line 1' label='Address Line 1' iconLeft={['fas', 'archway']} />
                  <FromInputField name='address.line2' placeholder='Address Line 2' label='Address Line 2' iconLeft={['fas', 'archway']} />
                  <FromInputField name='address.city' placeholder='City' label='City' iconLeft={['fas', 'city']} />
                  <FromInputField name='address.state' placeholder='State' label='State' iconLeft={['fas', 'route-interstate']} />
                  <FromInputField name='address.zip' placeholder='ZIP' label='ZIP' iconLeft={['fas', 'mailbox']} />
                  <FromInputField name='address.country' placeholder='Country' label='Country' iconLeft={['fas', 'flag']} />
                  <FromInputField
                    name='address.phone'
                    placeholder='Phone'
                    label='Phone'
                    iconLeft={['fas', 'phone']}
                    phoneType
                    type='tel'
                  />
                  <FromInputField
                    name='address.fax'
                    placeholder='Fax'
                    label='Fax'
                    iconLeft={['fas', 'fax']}
                    phoneType
                    type='tel'
                  />
                </Tab>
              </Tabs>
              <div className='divider has-no-gap' />
              <FormErrorsList />
              <SubmitButton buttonText='Save' classes={['has-medium-top-margin']} />
              <FormStatus />
            </FormWrapper>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CreateAccount
