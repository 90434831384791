import { useDocument } from '../frankentangel'

const DocChecker = props => {
  const { docInfo, docTest } = props
  const [result, error] = useDocument(docInfo.table, docInfo.id)
  if (error || !result) return null
  if (docTest(result)) return props.children
  return null
}

export default DocChecker
