import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import MainLayout from '../../containers/MainLayout'
import { useFrankentangel, useDocument } from '../../frankentangel'
import FormWrapper from '../../components/FormWrapper'
import FromInputField from '../../components/FormInputField'
import FormNumberInput from '../../components/FormNumberInput'
import ExpCalculator from '../../components/ExpCalculator'
import FromTextarea from '../../components/FormTextarea'
import DatePicker from '../../components/DatePicker'
import SimpleSelect from '../../components/SimpleSelect'
import SubmitButton from '../../components/SubmitButton'
import FormErrorsList from '../../components/FormErrorsList'
import FormStatus from '../../components/FormStatus'
import FormJoinSelect from '../../components/FormJoinSelect'
import FormJoinChecklist from '../../components/FormJoinChecklist'
import FormContext from '../../components/FormContext'
import ButtonLink from '../../components/ButtonLink'
import { Tabs, Tab } from '../../components/Tabs'
import ObjectDelete from '../../components/ObjectDelete'

const LibraryRightsSection = ({ formValue }) => {
  const [libraryRightsPackage] = useDocument('packages', formValue.values.libraryRightsPackage)
  const baseLibraries = libraryRightsPackage ? libraryRightsPackage.libraries.map(lib => lib.id).join(', ') : ''

  return (
    <>
      <FormJoinSelect name='libraryRightsPackage' placeholder='Select Library Package' label='Library Package' table='packages' rows={libraryPackage => libraryPackage.name} />
      <FormJoinChecklist base={baseLibraries} overridePositive='libraryRightsExtra' overrideNegative='libraryRightsExceptions' label='Library Rights' table='library' rows={lib => lib.library_name} columnLabel='Library' />
    </>
  )
}

const Contract = props => {
  const { id } = useParams()
  const frankentangel = useFrankentangel()
  const [contract, setContract] = useState({})
  const [contractLoading, setContractLoading] = useState(false)

  const fetchContract = async () => {
    setContractLoading(true)
    const fetchedContract = await frankentangel.viewDocument('contracts', Number(id))

    // minor fix because the server doesn't like mixing floats and longs
    fetchedContract.minutesPerDay = Number(fetchedContract.minutesPerDay) || 0

    fetchedContract.libraryRightsPackage = fetchedContract.libraryRightsPackage ? fetchedContract.libraryRightsPackage.id : 0
    fetchedContract.stations = (fetchedContract.stations || []).map(account => account.id).join(', ')

    fetchedContract.downloadsUnlimited = fetchedContract.downloadsLeft === Infinity
    fetchedContract.downloadsLeft = isFinite(fetchedContract.downloadsLeft) ? fetchedContract.downloadsLeft : 0

    setContract(fetchedContract)
    setContractLoading(false)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    fetchContract()
  }, []) // eslint-disable-line

  const submitContractData = async values => {
    const newContract = { ...values }

    if (newContract.downloadsUnlimited) newContract.downloadsLeft = -1
    delete newContract.downloadsUnlimited

    await frankentangel.updateDocument('contracts', contract.id, newContract)
    fetchContract()
    return 'Contract changes saved'
  }

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns is-multiline'>
          <div className='column is-full'>
            <h1 className='title'>Contract Details</h1>
            <ButtonLink target='/contracts' icon={['fas', 'arrow-left']} linkText='Back to Contracts' />&nbsp;
            <ObjectDelete objectName='contract' tableName='contracts' objectId={contract.id} objectTitle={contract.name} />
          </div>
        </div>
        <div className='columns'>
          <div className='column is-half'>
            <FormWrapper
              fields={yup.object({
                name: yup.string().required('Required').max(255),
                comments: yup.string().max(15000),
                startDate: yup.date().required('Required').typeError('Please provide a valid date'),
                expirationDate: yup.date().required('Required').typeError('Please provide a valid date'),
                autoRenew: yup.boolean().required('Required'),
                contractType: yup.string().required('Required').oneOf(['Cash', 'Barter', 'Barter Settlement', 'Library Rights Only']),
                minutesPerDay: yup.number().required('Required'),
                isActive: yup.boolean().required('Required'),
                downloadsLeft: yup.number().required('Required'),
                downloadsUnlimited: yup.boolean().required('Required'),
                libraryRightsPackage: yup.number(),
                libraryRightsExtra: yup.string(),
                libraryRightsExceptions: yup.string(),
                stations: yup.string()
              })}
              initialValuesFrom={contract}
              submit={submitContractData}
            >
              <Tabs
                mode='is-boxed'
                headers={['Basic Information', 'Connections', 'Library Rights']}
                headerIcons={[['fas', 'info-square'], ['fas', 'link'], ['fas', 'album']]}
                loading={contractLoading}
              >
                <Tab>
                  <FromInputField name='name' placeholder='Contract Name' label='Contract Name' iconLeft={['fas', 'file']} />
                  <SimpleSelect name='contractType' opts={[{ label: 'Cash', value: 'Cash' }, { label: 'Barter', value: 'Barter' }, { label: 'Barter Settlement', value: 'Barter Settlement' }, { label: 'Library Rights Only', value: 'Library Rights Only' }]} label='Contract Type' placeholder='Choose contract type' />
                  <DatePicker name='startDate' label='Start Date' />
                  <DatePicker name='expirationDate' label='Expiration Date' />
                  <ExpCalculator label='Term in Months' placeholder='Term in Months' base='startDate' target='expirationDate' step='1' />
                  <SimpleSelect name='autoRenew' opts={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} label='Automatic Renewal' placeholder='Choose renewal method' />
                  <FormContext.Consumer>
                    {formValue => (
                      <>
                        <div className='mb-3' style={{ display: ['Barter', 'Barter Settlement'].includes(formValue.values.contractType) ? '' : 'none' }}>
                          <FormNumberInput name='minutesPerDay' placeholder='Minutes per Day' label='Minutes per Day' step='0.5' strictStep />
                        </div>
                        <div className='mb-3' style={{ display: ['Barter', 'Barter Settlement'].includes(formValue.values.contractType) ? 'none' : '' }}>
                          <SimpleSelect name='downloadsUnlimited' opts={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} label='Unlimited Song Downloads' placeholder='Select' />
                          <div className='mb-3' style={{ display: formValue.values.downloadsUnlimited ? 'none' : '' }}>
                            <FormNumberInput name='downloadsLeft' placeholder='Song Downloads Remaining' label='Song Downloads Remaining' step='1' strictStep />
                          </div>
                        </div>
                      </>
                    )}
                  </FormContext.Consumer>
                  <SimpleSelect name='isActive' opts={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} label='Active' placeholder='Active status' />
                  <FromTextarea name='comments' placeholder='Comments' label='Comments (visible to TAA employees only)' rows='10' />
                </Tab>
                <Tab>
                  <FormJoinSelect
                    name='stations'
                    placeholder='Select Accounts'
                    label='Accounts'
                    table='accounts'
                    multi
                    rows={account => `${account.callsign} ${account.name} (${account.type})`}
                    url='/accounts/%id'
                  />
                </Tab>
                <Tab>
                  <FormContext.Consumer>
                    {formValue => (
                      <LibraryRightsSection formValue={formValue} />
                    )}
                  </FormContext.Consumer>
                </Tab>
              </Tabs>
              <div className='divider has-no-gap' />
              <FormErrorsList />
              <SubmitButton buttonText='Save' classes={['has-medium-top-margin']} />
              <FormStatus />
            </FormWrapper>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Contract
