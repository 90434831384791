import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import FormContext from '../../components/FormContext'

import MainLayout from '../../containers/MainLayout'
import { useFrankentangel, useDocument, useMulti } from '../../frankentangel'
import FormWrapper from '../../components/FormWrapper'
import FromInputField from '../../components/FormInputField'
import FormTextarea from '../../components/FormTextarea'
import SimpleSelect from '../../components/SimpleSelect'
import SubmitButton from '../../components/SubmitButton'
import FormErrorsList from '../../components/FormErrorsList'
import FormStatus from '../../components/FormStatus'
import ButtonLink from '../../components/ButtonLink'
import FormJoinSelect from '../../components/FormJoinSelect'
import FormJoinChecklist from '../../components/FormJoinChecklist'
import DocChecker from '../../components/DocChecker'
import { Tabs, Tab } from '../../components/Tabs'
import ObjectDelete from '../../components/ObjectDelete'

const LibraryRightsSection = ({ formValue }) => {
  const [contract] = useDocument('contracts', formValue.values.contract)
  const baseLibraries = (contract && contract.libraryRightsPackage) ? contract.libraryRightsPackage.libraries.split(',').map(s => s.trim()) : []
  const extraLibraries = (contract && contract.libraryRightsExtra) ? contract.libraryRightsExtra.split(',').map(s => s.trim()) : []
  const exceptions = (contract && contract.libraryRightsExceptions) ? contract.libraryRightsExceptions.split(',').map(s => s.trim()) : []

  const contractLibraries = [...baseLibraries, ...extraLibraries].filter(lib => !exceptions.includes(lib)).join(', ')

  return (
    <>
      <FormJoinChecklist base={contractLibraries} overridePositive='libraryRightsExtra' overrideNegative='libraryRightsExceptions' label='Library Rights' table='library' rows={lib => lib.library_name} columnLabel='Library' />
    </>
  )
}

const LoadUserOptions = ({ formValue, children }) => {
  const [users] = useMulti('users', formValue.values.users.split(',').map(Number))
  const userOptions = [
    { label: 'None', value: 0 },
    ...users.map(user => user.doc).map(user => ({
      label: `${user.firstName} ${user.lastName} (${user.email})`,
      value: user.id
    }))
  ]

  return children(userOptions)
}

const Account = props => {
  const { id } = useParams()
  const frankentangel = useFrankentangel()
  const [account, setAccount] = useState({})
  const [accountLoading, setAccountLoading] = useState(false)
  const [accountAddress, setAccountAddress] = useState({})

  const fetchAccount = async () => {
    setAccountLoading(true)
    const fetchedAccount = await frankentangel.viewDocument('accounts', Number(id))
    fetchedAccount.contract = fetchedAccount.contract ? fetchedAccount.contract.id : 0
    fetchedAccount.format = fetchedAccount.format ? fetchedAccount.format.id : 0
    fetchedAccount.market = fetchedAccount.market ? fetchedAccount.market.id : 0
    fetchedAccount.region = fetchedAccount.region ? fetchedAccount.region.id : 0
    fetchedAccount.complianceBoss = fetchedAccount.complianceBoss ? fetchedAccount.complianceBoss.id : 0
    fetchedAccount.complianceUser = fetchedAccount.complianceUser ? fetchedAccount.complianceUser.id : 0
    fetchedAccount.users = (fetchedAccount.users || []).map(user => user.id).join(', ')

    setAccount(fetchedAccount)
    setAccountAddress(fetchedAccount.address)
    setAccountLoading(false)
  }

  useEffect(() => {
    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100)
    fetchAccount()
  }, []) // eslint-disable-line

  const submitAccountData = async values => {
    let result
    if (accountAddress) {
      result = await frankentangel.updateDocument('addresses', accountAddress.id, values.address)
    } else {
      result = await frankentangel.createDocument('addresses', { ...values.address })
    }
    const { address, ...otherValues } = values

    otherValues.complianceBoss = Number(otherValues.complianceBoss) || 0
    otherValues.complianceUser = Number(otherValues.complianceUser) || 0

    await frankentangel.updateDocument('accounts', account.id, { ...otherValues, address: result.id })
    fetchAccount()
    return 'Account changes saved'
  }

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns is-multiline'>
          <div className='column is-full'>
            <h1 className='title'>Account Details</h1>
            <div className='is-flex'>
              <ButtonLink target='/accounts' icon={['fas', 'arrow-left']} linkText='Back to Accounts' />&nbsp;
              <ObjectDelete objectName='account' tableName='accounts' objectId={account.id} objectTitle={account.name} />
            </div>
          </div>
        </div>
        <div className='columns'>
          <div className='column is-four-fifths'>
            <FormWrapper
              fields={yup.object({
                name: yup.string().required('Required').max(255),
                type: yup.string().oneOf(['Radio Station', 'Retail Customer'], 'Please choose one of these values: Radio Station, Retail Customer').required('Required'),
                callsign: yup.string().when('type', {
                  is: 'Radio Station',
                  then: yup.string().matches(/^[A-Z0-9]{3,25}(?:-[A-Z0-9]{2})?$/, 'Invalid format').required('Required'),
                  otherwise: yup.string().matches(/^[A-Z0-9]{3,25}(?:-[A-Z0-9]{2})?$/, 'Invalid format')
                }),
                language: yup.string().when('type', {
                  is: 'Radio Station',
                  then: yup.string().oneOf(['English', 'Spanish'], 'Please choose one of these values: English, Spanish').required('Required'),
                  otherwise: yup.string().oneOf(['English', 'Spanish'], 'Please choose one of these values: English, Spanish')
                }),
                billingRate: yup.string().matches(/^[0-9]+(?:\.[0-9]{1,2})?$/, 'Numbers and <dot> only').required('Required'),
                tag: yup.string().max(255).required('Required'),
                comments: yup.string().max(15000),
                address: yup.object({
                  firstName: yup.string().max(255),
                  lastName: yup.string().max(255),
                  line1: yup.string().max(255).required('Required'),
                  line2: yup.string().max(255),
                  city: yup.string().max(255).required('Required'),
                  state: yup.string().max(255),
                  zip: yup.string().max(255).matches(/^\d{5}(-\d{4})?$/, 'Invalid ZIP code').required('Required'),
                  country: yup.string().max(255).required('Required'),
                  phone: yup.string().max(255).min(9, 'Minimal length is 9 chars').phone('Invalid phone number').required('Required'),
                  fax: yup.string().max(255).phone('Invalid phone number')
                }),
                contract: yup.number(),
                users: yup.string(),
                format: yup.number(),
                market: yup.number(),
                region: yup.number(),
                complianceBoss: yup.number(),
                complianceUser: yup.number(),
                libraryRightsExtra: yup.string(),
                libraryRightsExceptions: yup.string()
              })}
              initialValuesFrom={account}
              submit={submitAccountData}
            >
              <Tabs
                mode='is-boxed'
                headers={['Basic Information', 'Address', 'Connections', 'Library Rights']}
                headerIcons={[['fas', 'info-square'], ['fas', 'address-book'], ['fas', 'link'], ['fas', 'album']]}
                loading={accountLoading}
              >
                <Tab>
                  <FromInputField name='name' placeholder='Account Name' label='Account Name' iconLeft={['fas', 'file']} />
                  <SimpleSelect name='type' opts={[{ label: 'Radio Station', value: 'Radio Station' }, { label: 'Retail Customer', value: 'Retail Customer' }]} label='Account Type' placeholder='Choose account type' />
                  <FromInputField name='callsign' placeholder='Call Letters' label='Call Letters' iconLeft={['fas', 'id-card-alt']} />
                  <FormContext.Consumer>
                    {value => {
                      if (value.values.type === 'Radio Station') {
                        return <SimpleSelect name='language' opts={[{ label: 'English', value: 'English' }, { label: 'Spanish', value: 'Spanish' }]} label='Language' placeholder='Choose renewal method' />
                      }
                    }}
                  </FormContext.Consumer>
                  <FromInputField name='billingRate' placeholder='Billing Rate' label='Billing Rate (USD)' iconLeft={['fas', 'dollar-sign']} />
                  <FromInputField name='tag' placeholder='Tag' label='Tag' iconLeft={['fas', 'tag']} />
                  <FormTextarea name='comments' placeholder='Comments' label='Comments (visible to TAA employees only)' rows='10' />
                </Tab>
                <Tab>
                  <FromInputField name='address.firstName' placeholder='First Name' label='First Name' iconLeft={['fas', 'user']} />
                  <FromInputField name='address.lastName' placeholder='Last Name' label='Last Name' iconLeft={['fas', 'user']} />
                  <FromInputField name='address.line1' placeholder='Address Line 1' label='Address Line 1' iconLeft={['fas', 'archway']} />
                  <FromInputField name='address.line2' placeholder='Address Line 2' label='Address Line 2' iconLeft={['fas', 'archway']} />
                  <FromInputField name='address.city' placeholder='City' label='City' iconLeft={['fas', 'city']} />
                  <FromInputField name='address.state' placeholder='State' label='State' iconLeft={['fas', 'route-interstate']} />
                  <FromInputField name='address.zip' placeholder='ZIP' label='ZIP' iconLeft={['fas', 'mailbox']} />
                  <FromInputField name='address.country' placeholder='Country' label='Country' iconLeft={['fas', 'flag']} />
                  <FromInputField
                    name='address.phone'
                    placeholder='Phone'
                    label='Phone'
                    iconLeft={['fas', 'phone']}
                    phoneType
                    type='tel'
                  />
                  <FromInputField
                    name='address.fax'
                    placeholder='Fax'
                    label='Fax'
                    iconLeft={['fas', 'fax']}
                    phoneType
                    type='tel'
                  />
                </Tab>
                <Tab>
                  <FormJoinSelect name='contract' placeholder='Select Contract' label='Contract' table='contracts' rows={contract => contract.name} url='/contracts/%id' />
                  <FormJoinSelect name='users' placeholder='Select Users' label='Users' table='users' multi rows={user => `${user.firstName} ${user.lastName} (${user.email})`} url='/users/%id' />
                  <FormContext.Consumer>
                    {value => {
                      if (value.values.type === 'Radio Station') {
                        return (
                          <>
                            <FormJoinSelect name='region' placeholder='Select Commercial Region' label='Commercial Region' table='commsregion' rows={region => region.name} />
                            <FormJoinSelect name='format' placeholder='Select Radio Format' label='Radio Format' table='commsformat' rows={format => format.name} />
                            <FormJoinSelect name='market' placeholder='Select Market' label='Market' table='commsmarket' rows={market => market.name} />
                            <DocChecker docInfo={{ table: 'contracts', id: value.values.contract }} docTest={doc => doc.contractType === 'Barter'}>
                              <LoadUserOptions formValue={value}>
                                {
                                  userOptions => (
                                    <>
                                      <SimpleSelect name='complianceBoss' placeholder='Select Compliance Boss' label='Compliance Boss' opts={userOptions} />
                                      <SimpleSelect name='complianceUser' placeholder='Select Compliance User' label='Compliance User' opts={userOptions} />
                                    </>
                                  )
                                }
                              </LoadUserOptions>
                            </DocChecker>
                          </>
                        )
                      }
                    }}
                  </FormContext.Consumer>
                </Tab>
                <Tab>
                  <FormContext.Consumer>
                    {formValue => (
                      <LibraryRightsSection formValue={formValue} />
                    )}
                  </FormContext.Consumer>
                </Tab>
              </Tabs>
              <div className='divider has-no-gap' />
              <FormErrorsList />
              <SubmitButton buttonText='Save' classes={['has-medium-top-margin']} />
              <FormStatus />
            </FormWrapper>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Account
