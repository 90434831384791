import React from 'react'

const ReportTable = props => {
  const { headings, tableData } = props

  const tableHead = (
    <tr>
      {headings.map((heading, index) => <th key={`th-${index}`}>{heading}</th>)}
    </tr>
  )

  const tableBody = tableData.map((tdata, index) => {
    const trIndex = index
    return (
      <tr key={`tr-${index}`}>
        {tdata.map((dataEl, index) => (
          <td key={`td-${trIndex}-${index}`} data-heading={headings[index]}>{dataEl === '' ? <>&nbsp;</> : dataEl}</td>
        ))}
      </tr>
    )
  })

  return (
    <table className='table is-striped is-fullwidth report-table'>
      <thead>
        {tableHead}
      </thead>
      <tbody>
        {tableBody}
      </tbody>
    </table>
  )
}

export default ReportTable
