import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSyncAlt } from '@fortawesome/pro-solid-svg-icons'

import MainLayout from '../../containers/MainLayout'
import ReportTable from '../../components/ReportTable'
import InputField from '../../components/InputField'
import ErrorPage from '../../pages/ErrorPage'
import ButtonLink from '../../components/ButtonLink'

import { useSearchableList } from '../../frankentangel'

const TrackManager = props => {
  const [searchText, setSearchText] = useState('')

  const [Paginator, result, error, loading] = useSearchableList('song', searchText, 'list')

  if (error) {
    return <ErrorPage error={error} />
  }

  const searchResults = result
    ? result.map(track => track.doc).map(track => {
        return [
          track.song_file,
          track.mix,
          track.song_length,
          track.album?.library?.library_name,
          track.album?.album_name,
          (
            <React.Fragment key={track.song_file}>
              <ButtonLink target={`/track-manager/tracks/${track.id}/mix`} classes={['is-dark']} icon={faEdit} linkText='Edit' />&nbsp;&nbsp;
              <ButtonLink target={`/track-manager/tracks/${track.id}`} icon={faEdit} classes={['is-warning']} linkText='Edit Single' />
            </React.Fragment>
          )
        ]
      })
    : null

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns'>
          <div className='column is-full'>
            <div className='columns'>
              <div className='column is-12'>
                <h1 className='title'>Track Manager</h1>
                <ButtonLink target='/track-manager/tracks/create' classes={['is-dark']} icon={['fas', 'file-plus']} linkText='Add New Track' />
              </div>
            </div>
          </div>
        </div>
        <div className='columns is-multiline'>
          <div className='column is-half'>
            <InputField loading={loading} label='Search Tracks' placeholder='Type here' value={searchText} onChange={ev => setSearchText(ev.target.value)} />
          </div>
        </div>
        <div className='columns'>
          <div className='column is-full'>
            <Paginator spinner={<div className='has-text-centered my-4'><FontAwesomeIcon icon={faSyncAlt} spin size='3x' /></div>}>
              <ReportTable
                headings={['Song File', 'Mix', 'Length', 'Library', 'Album', '']}
                tableData={searchResults}
              />
            </Paginator>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default TrackManager
