import React, { useContext, useEffect } from 'react'
import FormContext from './FormContext'

const FormStatus = props => {
  const formProps = useContext(FormContext)

  useEffect(() => {
    if (!formProps.isFormStatus) formProps.setIsFormStatus(true)
  }, [formProps])

  const visibilityClass = formProps.status.initial ? '' : ' active'
  const colorClass = formProps.status.success ? 'is-success' : 'is-danger'
  const handleCloseNotification = (e) => {
    e.target.parentNode.parentNode.removeChild(e.target.parentNode)
  }
  return (
    <div className={`field has-medium-top-margin form__notification${visibilityClass}`}>
      <div className={`notification ${colorClass}`}>
        <button className='delete' onClick={e => handleCloseNotification(e)} />
        {formProps.status.error || formProps.status.success}
      </div>
    </div>
  )
}

export default FormStatus
