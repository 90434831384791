import React, { useContext } from 'react'
import FormContext from './FormContext'

const FormRequired = props => {
  const formProps = useContext(FormContext)

  const messagesToDisplay = Object.keys(formProps.states).map(key => formProps.states[key].state === 'invalid' ? <li key={key}>{`${key}: ${formProps.states[key].errorMsg}`}</li> : '').filter(val => val !== '')

  return (
    <div className={`message is-warning${(formProps.showErrorsList && messagesToDisplay.length > 0) ? '' : ' is-hidden'}`}>
      <div className='message-header'>
        <p>Please complete the following before submitting:</p>
      </div>
      <div className='message-body content'>
        <ul className='has-no-top-margin'>
          {messagesToDisplay}
        </ul>
      </div>
    </div>
  )
}

export default FormRequired
