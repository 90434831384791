import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'

import useForm from './useForm'

import { useList } from '../frankentangel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCheckSquare, faMinusSquare, faTimesCircle, faTimesSquare } from '@fortawesome/pro-solid-svg-icons'

const transformUseForm = ([fieldValue, fieldState, handleFieldChange, handleFieldBlur]) => ({ fieldValue, fieldState, handleFieldChange, handleFieldBlur })

const FormJoinChecklist = props => {
  const { name, base, enableAll, invert, autoFocus, overridePositive, overrideNegative, onChange, label, table, action = 'list', params = {}, rows, isEnabled, isVisible, columnLabel } = props

  const nameField = transformUseForm(useForm(name || ''))
  const overrideFields = {
    positive: transformUseForm(useForm(overridePositive || '')),
    negative: transformUseForm(useForm(overrideNegative || ''))
  }

  const [Paginator, results] = useList(table, action, params)

  const deduplicate = (v, i, a) => a.indexOf(v) === i
  const filterArray = values => values.map(s => String(s).trim()).filter(n => n && n !== '0').filter(deduplicate)

  const arrayValue = field => filterArray(String(field.fieldValue || '').split(','))
  const transformValue = value => filterArray(value).join(', ')

  const overrideMode = !name
  const overrideValues = {}

  if (overrideMode) {
    overrideValues.base = arrayValue({ fieldValue: enableAll ? results.map(doc => doc.id) : base })
    overrideValues.positive = arrayValue(overrideFields.positive)
    overrideValues.negative = arrayValue(overrideFields.negative)
  }

  const processOverrides = (positive, negative) => {
    return [...overrideValues.base, ...positive]
      .filter(value => !negative.includes(value))
      .filter(deduplicate)
  }

  const currentValue = name ? arrayValue(nameField) : processOverrides(overrideValues.positive, overrideValues.negative)

  const setValue = (row, value) => {
    const newValue = transformValue(currentValue.filter(v => v !== row).concat(value ? [row] : []))

    nameField.handleFieldChange(name, newValue)
    if (onChange) onChange(newValue)
  }

  const setOverride = (row, overrideValue) => {
    const newPositive = transformValue(overrideValues.positive.filter(v => v !== row).concat(overrideValue === 'enabled' ? [row] : []))
    const newNegative = transformValue(overrideValues.negative.filter(v => v !== row).concat(overrideValue === 'disabled' ? [row] : []))

    overrideFields.positive.handleFieldChange(overridePositive, newPositive)
    overrideFields.negative.handleFieldChange(overrideNegative, newNegative)
  }

  // const handleOnChange = value => {
  //   const newValue = transformValue([value, ...arrayValue])

  //   handleFieldChange(name, newValue)
  //   if (onChange) onChange(newValue)
  // }

  const displayRows = rows || ((doc, meta) => `Document ${meta.id}`)
  const displayedOptions = (results || []).map(result => ({
    value: String(result.id || result.doc),
    label: displayRows(result.doc, result),
    underlying: result
  })).filter(row => isVisible ? isVisible(row.underlying.doc, row.underlying) : true)

  const [magic] = useState({
    click: () => console.log('this should never happen'),
    current: null,
    lastScrolled: null
  })
  const ref = useCallback(element => {
    if (!element) return
    magic.current = element

    if (autoFocus) magic.current.focus()
  }, [autoFocus, magic])

  const [selectedRow, setSelectedRow] = useState(0)
  const [isFocused, setFocused] = useState(false)

  useEffect(() => {
    if (!isFocused) return

    const handleKeypress = (event) => {
      event.preventDefault()

      if (event.key === 'ArrowDown') setSelectedRow(Math.min(selectedRow + 1, results.length - 1))
      if (event.key === 'ArrowUp') setSelectedRow(Math.max(selectedRow - 1, 0))
      if (event.key === 'PageDown') setSelectedRow(Math.min(selectedRow + 12, results.length - 1))
      if (event.key === 'PageUp') setSelectedRow(Math.max(selectedRow - 12, 0))

      if (event.key === ' ') magic.click()
    }

    window.addEventListener('keydown', handleKeypress)
    return () => window.removeEventListener('keydown', handleKeypress)
  }, [isFocused, selectedRow, results.length, magic])

  const scrollCallback = (element) => {
    if (!element) return element

    if (magic.lastScrolled !== selectedRow) element.scrollIntoView({ block: 'nearest' })
    magic.lastScrolled = selectedRow
  }

  const handleClickFocus = (index) => (event) => {
    if (event.target.nodeName !== 'TD') return

    setSelectedRow(index)
    magic.current.focus()
  }

  // useEffect(() => {
  //   const listener = () => setFocused(false)

  //   window.addEventListener('wheel', listener)
  //   window.addEventListener('mousewheel', listener)
  //   // window.addEventListener('scroll', listener)
  //   return () => {
  //     window.removeEventListener('wheel', listener)
  //     window.removeEventListener('mousewheel', listener)
  //     // window.removeEventListener('scroll', listener)
  //   }
  // })

  return (
    <div className='form-join-checklist' tabIndex={0} ref={ref} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}>
      <div className='label'>{label}</div>
      <Paginator>
        <table className='table is-fullwidth is-hoverable is-striped has-cells-vertically-centered form-join-checklist__table'>
          <thead>
            <tr>
              <th>{columnLabel}</th>
              {
                overrideMode
                  ? (
                    <>
                      <th className='form-join-checklist__status-column'>Status</th>
                      <th>Override</th>
                    </>
                    )
                  : (
                    <th>Status</th>
                    )
              }
            </tr>
          </thead>
          <tbody>
            {
              displayedOptions.map((row, index) => {
                const overridden = overrideMode && (overrideValues.positive.includes(row.value) || overrideValues.negative.includes(row.value))
                const overrideMarkup = overridden
                  ? (
                    <>
                      {/* <br /> */}
                      {
                        overrideValues.negative.includes(row.value)
                          ? (
                            <>
                              <span className='icon has-text-danger'>
                                <FontAwesomeIcon icon={faTimesCircle} />
                              </span>
                              Disabled
                            </>
                            )
                          : (
                            <>
                              <span className='icon has-text-success'>
                                <FontAwesomeIcon icon={faCheckCircle} />
                              </span>
                              Enabled
                            </>
                            )
                      }
                    </>
                    )
                  : undefined

                const statusMarkup = overrideMode
                  ? (
                      overrideValues.base.includes(row.value) || (typeof isEnabled === 'function' ? isEnabled(row.underlying.doc, row.underlying) : false)
                        ? (
                          <>
                            <span className={classNames({ 'has-text-grey-light has-text-strike is-hidden': overridden })}>
                              <span className={classNames('icon', {
                                'has-text-success': !overridden
                              })}
                              >
                                <FontAwesomeIcon icon={faCheckCircle} />
                              </span>
                              Enabled
                            </span>
                            {overrideMarkup}
                          </>
                          )
                        : (
                          <>
                            <span className={classNames({ 'has-text-grey-light has-text-strike is-hidden': overridden })}>
                              <span className={classNames('icon', {
                                'has-text-danger': !overridden
                              })}
                              >
                                <FontAwesomeIcon icon={faTimesCircle} />
                              </span>
                              Disabled
                            </span>
                            {overrideMarkup}
                          </>
                          )
                    )
                  : undefined

                const isHighlighted = isFocused && (index === selectedRow)
                if (isHighlighted) {
                  magic.click = overrideMode
                    ? () => setOverride(row.value,
                        overrideValues.negative.includes(row.value)
                          ? 'default'
                          : (
                              overrideValues.positive.includes(row.value) ? 'disabled' : 'enabled'
                            )
                      )
                    : () => setValue(row.value, !currentValue.includes(row.value))
                }

                return (
                  <tr
                    key={row.value}
                    className={classNames('form-join-checklist__tr', {
                      'has-text-light': isHighlighted,
                      'has-background-dark': isHighlighted
                    })}
                    // ref={isHighlighted ? scrollCallback : undefined}
                    onClick={handleClickFocus(index)}
                  >
                    <td>
                      {
                        isHighlighted ? <div className='form-join-checklist__highlight-scroller' ref={scrollCallback} /> : undefined
                      }
                      {row.label}
                      {
                        overrideMode
                          ? (
                            <span className='form-join-checklist__status-mobile'>
                              <br />
                              {statusMarkup}
                            </span>
                            )
                          : undefined
                      }
                    </td>
                    {
                      overrideMode
                        ? (
                          // override mode row controls
                          <>
                            <td className='form-join-checklist__status-column'>
                              {statusMarkup}
                            </td>
                            <td className='form-join-checklist__control-cell'>
                              <button
                                className={classNames('button mr-2', {
                                  'is-success': overrideValues.positive.includes(row.value),
                                  'is-danger': overrideValues.negative.includes(row.value)
                                })}
                                onClick={e => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  setOverride(row.value,
                                    overrideValues.negative.includes(row.value)
                                      ? 'default'
                                      : (
                                          overrideValues.positive.includes(row.value) ? 'disabled' : 'enabled'
                                        )
                                  )
                                }}
                                onFocus={e => {
                                  e.target.blur()
                                }}
                              >
                                <span className='icon'>
                                  <FontAwesomeIcon
                                    icon={
                                      overrideValues.negative.includes(row.value)
                                        ? faTimesSquare
                                        : (overrideValues.positive.includes(row.value) ? faCheckSquare : faMinusSquare)
                                    }
                                  />
                                </span>
                              </button>
                              <div className='select'>
                                <select
                                  value={
                                    overrideValues.negative.includes(row.value)
                                      ? 'disabled'
                                      : (overrideValues.positive.includes(row.value) ? 'enabled' : 'default')
                                  }
                                  onChange={(ev) => setOverride(row.value, ev.target.value)}
                                >
                                  <option value='default'>Default</option>
                                  <option value='enabled'>Enabled</option>
                                  <option value='disabled'>Disabled</option>
                                </select>
                              </div>
                            </td>
                          </>
                          )
                        : (
                          // base mode row controls
                          <td className='form-join-checklist__control-cell'>
                            <button
                              className={classNames('button is-small-mobile mr-2', {
                                'is-success': currentValue.includes(row.value) !== Boolean(invert),
                                'is-danger': !currentValue.includes(row.value) !== Boolean(invert)
                              })}
                              onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                setValue(row.value, !currentValue.includes(row.value))
                              }}
                              onFocus={e => {
                                e.target.blur()
                              }}
                            >
                              <span className='icon'>
                                <FontAwesomeIcon icon={(currentValue.includes(row.value) !== Boolean(invert)) ? faCheckSquare : faTimesSquare} />
                              </span>
                            </button>
                            {/* <div className='select'>
                              <select
                                value={(currentValue.includes(row.value) !== Boolean(invert)) ? 'enabled' : 'disabled'}
                                onChange={(ev) => setValue(row.value, (ev.target.value === 'enabled') !== Boolean(invert))}
                              >
                                <option value='enabled'>Enabled</option>
                                <option value='disabled'>Disabled</option>
                              </select>
                            </div> */}
                          </td>
                          )
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </Paginator>
    </div>
  )
}

export default FormJoinChecklist
