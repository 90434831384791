import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faTrash, faUpload } from '@fortawesome/pro-solid-svg-icons'
import unwrap from 'async-unwrap'

import ReportTable from '../../components/ReportTable'
import Button from '../../components/Button'
import classNames from 'classnames'
import useModal from '../../components/useModal'
import config from '../../config'

const allowedFormats = ['audio/mpeg', 'audio/mp3', 'audio/wav']

const makeId = (() => {
  let nextId = 1
  return () => `new-${nextId++}`
})()

const FileManager = (props) => {
  const { isMix, getTickets, files, setFiles } = props

  // const [files, setFiles] = useState([])
  const [modal, showModal] = useModal()

  const distinctSongNames = files.map(file => file.songName).filter((v, i, a) => a.indexOf(v) === i).sort()
  const [activeDropdown, setActiveDropdown] = useState(0)

  const sortFiles = (f1, f2) => f1.songFile.localeCompare(f2.songFile)

  const setSongName = (id, name) => setFiles(val => val.map(file => file.id !== id ? file : { ...file, songName: name }).sort(sortFiles))
  const editSongName = (id) => (event) => setSongName(id, event.target.value)
  const deleteSong = async (id, filename) => {
    const response = await showModal(
      'Delete Track',
      `Do you wish to delete the track corresponding to ${filename}? This will remove it from the database once you save the current ${isMix ? 'track' : 'mix'}.`,
      [{
        text: 'Cancel',
        response: 'no',
        isCancel: true
      }, {
        text: 'Delete',
        className: 'is-danger',
        response: 'yes'
      }]
    )
    if (response !== 'yes') return

    setFiles(val => val.filter(file => file.id !== id).sort(sortFiles))
  }
  const addSongs = (songs) => setFiles(val => {
    const safeCopy = val.map(song => ({ ...song }))

    const withoutExtension = name => (name.match(/^(.*)\.\d+$/) || [null, name])[1]
    const lut = safeCopy.map(song => withoutExtension(song.songFile))

    for (const song of songs) {
      const index = lut.indexOf(withoutExtension(song.name))

      if (index < 0) {
        safeCopy.push({
          id: song.id,
          songFile: song.name,
          mix: song.mix,
          length: song.length,
          has128: song.type === '128' ? song.descriptor : false,
          has320: song.type === '320' ? song.descriptor : false,
          hasWav: song.type === 'wav' ? song.descriptor : false,
          songName: song.songName
        })
      } else {
        const prop = ['has128', 'has320', 'hasWav'][['128', '320', 'wav'].indexOf(song.type)]
        if (!prop) continue // this should never happen
        safeCopy[index][prop] = song.descriptor
      }
    }

    return safeCopy.sort(sortFiles)
  })

  const [dropping, setDropping] = useState(false)
  const handleDragEnter = () => setDropping(true)
  const handleDragLeave = (event) => {
    if (!event.target.classList.contains('music-file-manager__dnd-area')) return

    const rect = event.target.getBoundingClientRect()
    const threshold = parseInt(window.getComputedStyle(document.documentElement).fontSize)

    if (event.clientX < rect.left + threshold || event.clientX > rect.right - threshold) return setDropping(false)
    if (event.clientY < rect.top + threshold || event.clientY > rect.bottom - threshold) return setDropping(false)
  }
  const handleDragOver = (e) => e.preventDefault()
  const handleDrop = (event) => {
    event.preventDefault()
    setDropping(false)

    const droppedFiles = Array.from(event.dataTransfer.files)
    consumeFiles(droppedFiles)
  }

  const [magic] = useState({})
  useEffect(() => {
    const inputField = document.createElement('input')
    inputField.type = 'file'
    inputField.multiple = true
    inputField.accept = allowedFormats.join(',')
    inputField.style.display = 'none'
    document.body.appendChild(inputField)

    magic.inputField = inputField

    inputField.addEventListener('change', (event) => consumeFiles(Array.from(event.target.files)))

    return () => document.body.removeChild(inputField)
  })

  const handleManualUpload = () => magic.inputField.click()

  const [filesUploading, setFilesUploading] = useState([])
  const statuses = filesUploading.map(descriptor => `Uploading ${descriptor.name} (${descriptor.type})... ${descriptor.percentUploaded.toFixed(1)}%`)

  const addUploadingFiles = (newFiles) => setFilesUploading(val => [...val, ...newFiles])
  const updateUploadingFile = (id, fn) => setFilesUploading(val => val.map(file => file.id === id ? fn(file) : file))
  const removeUploadingFiles = (idList) => setFilesUploading(val => val.filter(file => !idList.includes(file.id)))

  /**
   * @param {File[]} files
   */
  // ^ I like autocomplete
  const consumeFiles = async (files) => {
    if (!files.length) return

    const filteredFiles = files.filter(file => allowedFormats.includes(file.type))

    const parsedFiles = await Promise.all(filteredFiles.map(async file => {
      const getMp3Bitrate = () => new Promise(resolve => {
        const audioTag = document.createElement('audio')
        audioTag.src = URL.createObjectURL(file)

        audioTag.oncanplay = () => {
          const approximateBitrate = file.size * 8 / audioTag.duration / 1024
          const is128 = Math.min(128 / approximateBitrate, approximateBitrate / 128) > 0.75
          const is320 = Math.min(320 / approximateBitrate, approximateBitrate / 320) > 0.75

          if (is128) return resolve('128')
          if (is320) return resolve('320')

          return resolve('unknown')
        }
      })

      const [, rawSongName, mix, length] = file.name.match(/^(.*)_(\w)_(\d+)\.\w+$/) || [null, '', null, null]
      const songName = (rawSongName.match(/^\d+_(.*)$/) || [null, rawSongName])[1].replaceAll('_', ' ')

      return {
        id: makeId(),
        name: file.name,
        type: file.type === 'audio/wav' ? 'wav' : await getMp3Bitrate(),
        songName,
        mix,
        length,
        file,
        percentUploaded: 0,
        done: false,
        descriptor: null
      }
    }))

    const validFiles = parsedFiles.filter(file => file.mix && file.length && file.type !== 'unknown')
    if (!validFiles.length) {
      const reasons = []
      if (!filteredFiles.length) reasons.push('wrong file format')
      if (parsedFiles.filter(file => !file.mix).length) reasons.push('mix not recognized')
      if (parsedFiles.filter(file => !file.length).length) reasons.push('length not recognized')
      if (parsedFiles.filter(file => file.type === 'unknown').length) reasons.push('unknown bitrate')

      showModal('Upload Error', `File naming format not recognized. Use FILENAME_MIX_DURATION.MP3 ex: Mariachi_A_30.mp3 (${reasons.join(', ')})`)
      return
    }

    addUploadingFiles(validFiles)

    const [ticketError, tickets] = await Promise.resolve(getTickets ? getTickets(validFiles) : Promise.reject(new Error('No getTickets() function specified for FileManager')))[unwrap]
    if (ticketError) {
      showModal('Upload error', `Error while retrieving upload tickets: ${ticketError.message || String(ticketError)}`)
      removeUploadingFiles(validFiles.map(file => file.id))
      return
    }

    await Promise.all(validFiles.map(async (file, index) => {
      try {
        const ticket = tickets[index]

        const uploadEvent = await new Promise((resolve, reject) => {
          const uploadRequest = new window.XMLHttpRequest()

          uploadRequest.addEventListener('load', resolve)
          uploadRequest.addEventListener('error', reject)

          // uploadRequest.upload.addEventListener('progress', event => setUploadState(state => ({ ...state, progress: event.loaded / event.total })))
          uploadRequest.upload.addEventListener('progress', event => updateUploadingFile(file.id, f => ({ ...f, percentUploaded: event.loaded / event.total * 100 })))

          uploadRequest.open('POST', new URL('upload', config.apiRoot))

          uploadRequest.setRequestHeader('upload-ticket', ticket)

          const formData = new window.FormData()
          formData.append('file', file.file)

          uploadRequest.send(formData)
        })

        if (uploadEvent.target.responseText === 'Not Found') throw new Error('Upload route returned 404')

        const uploadResponse = JSON.parse(uploadEvent.target.responseText)
        if (!uploadResponse.ok) throw new Error(uploadResponse.error || uploadResponse)

        file.descriptor = JSON.stringify(uploadResponse.descriptor)
        file.done = true

        addSongs([file])
        removeUploadingFiles([file.id])
      } catch (err) {
        console.log('[FileManager] error while uploading:', err)
        showModal('Upload Error', `Error while uploading file ${file.name}: ${err.message || String(err)}`)
        removeUploadingFiles([file.id])
      }
    }))

    // const uploadedFiles = validFiles.filter(file => file.done)
    // addSongs(uploadedFiles)

    // removeUploadingFiles(validFiles.map(file => file.id))
  }

  return (
    <div className='music-file-manager'>
      <div className='music-file-manager__table-container'>
        <ReportTable
          headings={['Song File', 'Mix', 'Length', '128', '320', 'WAV', 'Song Name', '']}
          tableData={files.map(file => [
            file.songFile,
            file.mix,
            file.length,
            <FontAwesomeIcon key={`has128-${file.id}`} icon={file.has128 ? faCheck : faTimes} className={classNames({ 'has-text-danger': !file.has128 })} />,
            <FontAwesomeIcon key={`has320-${file.id}`} icon={file.has320 ? faCheck : faTimes} className={classNames({ 'has-text-danger': !file.has320 })} />,
            <FontAwesomeIcon key={`hasWav-${file.id}`} icon={file.hasWav ? faCheck : faTimes} className={classNames({ 'has-text-danger': !file.hasWav })} />,
            <div key={`songName-${file.id}`} className={classNames('dropdown is-width-100', { 'is-active': activeDropdown === file.id })}>
              <div className='dropdown-trigger is-width-100'>
                <input className='input' value={file.songName} onChange={editSongName(file.id)} onFocus={() => setActiveDropdown(file.id)} onBlur={() => setActiveDropdown(0)} />
              </div>
              <div className='dropdown-menu is-width-100'>
                <div className='dropdown-content is-scrollable-clamped'>
                  {distinctSongNames.map(name => {
                    const handleClick = () => setSongName(file.id, name)

                    return <a className='dropdown-item' key={name} onMouseDown={handleClick} onTouchStart={handleClick}>{name}</a> // eslint-disable-line
                  })}
                </div>
              </div>
            </div>,
            <Button key={`delete-${file.id}`} icon={faTrash} onClick={() => deleteSong(file.id, file.songFile)} />
          ])}
        />
      </div>
      <div className='music-file-manager__spacer' />
      <div className='music-file-manager__dnd-container'> {/* roll for upload */}
        <div
          className={classNames('music-file-manager__dnd-area', { 'music-file-manager__dnd-area--dropping': dropping })}
          onDragOver={handleDragOver} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDrop={handleDrop}
        >
          <div className='music-file-manager__dnd-label'>
            <div className='music-file-manager__dnd-title'>Drag and Drop Files Here</div>
            <div className='music-file-manager__dnd-or'>or</div>
            <div className='music-file-manager__dnd-button-container'>
              <Button classes='music-file-manager__dnd-button' icon={faUpload} onClick={handleManualUpload}>Upload Files</Button>
            </div>
          </div>
          {
            statuses.length
              ? (
                <div className='music-file-manager__dnd-statuses'>
                  {
                    statuses.map((status, index) => <div className='music-file-manager__dnd-status' key={index}>{status}</div>)
                  }
                </div>
                )
              : undefined
          }
        </div>
      </div>
      {modal}
    </div>
  )
}

export default FileManager
