import React from 'react'

import StatCard from '../StatCard'
import { useAction } from '../../frankentangel'

const OrphanedUsersBox = props => {
  const [orphanedUsers,, orphanedUsersLoading] = useAction('users', 'numOrphaned')

  return (
    <StatCard
      title='Users'
      firstValue={orphanedUsers?.total}
      content='Unlinked Users'
      linksTo='/reports/unlinked-users'
      loading={orphanedUsersLoading}
      mode='wide'
    />
  )
}

export default OrphanedUsersBox
