import { useContext } from 'react'
import FormContext from './FormContext'

const useForm = fieldName => {
  const fieldNameProcessed = fieldName.split('.')
  const context = useContext(FormContext)
  const fieldValue = fieldNameProcessed.length > 1 ? context.values[fieldNameProcessed[0]][fieldNameProcessed[1]] : context.values[fieldNameProcessed[0]]
  const fieldState = fieldNameProcessed.length > 1 ? context.states[fieldNameProcessed[0]][fieldNameProcessed[1]] : context.states[fieldNameProcessed[0]]
  return [fieldValue, fieldState, context.handleFieldChange, context.handleFieldBlur]
}

export default useForm
