import React from 'react'
import moment from 'moment'

import StatCard from '../StatCard'
import { useAction } from '../../frankentangel'

const CommercialDownloadsSum = props => {
  const [commsWeeks,, commWeeksLoading] = useAction('commercial-weeks', 'listPublished')
  const [commDownloadsCurrent,, commDownloadsLoading] = useAction('dashboard-stations-assigned', 'commercialDownloadStatus', { week: commsWeeks?.list[0]?.doc.actualDate })

  return (
    <StatCard
      title='Commercials'
      firstValue={commDownloadsCurrent?.downloaded}
      secondValue={commDownloadsCurrent?.total}
      content={`Commercial Downloads by Account-Grouped Call Letters ${moment(commsWeeks?.list[0]?.doc.actualDate.replaceAll('-', '/')).format('dddd, MMM DD')}`}
      linksTo='/reports/commercial-downloads'
      loading={commDownloadsLoading || commWeeksLoading}
    />
  )
}

export default CommercialDownloadsSum
