import React from 'react'

const Footer = props => (
  <div className='hero-foot'>
    <footer className='footer'>
      <p className='is-size-6'>
        &copy;{new Date().getFullYear()} TAAMusic All rights reserved.
      </p>
    </footer>
  </div>
)

export default Footer
