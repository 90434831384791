import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import moment from 'moment'

import FormContext from './FormContext'

const ExpCalculator = props => {
  const { id, name, onBlur, onChange, placeholder, label, classes, step, min, max, target, base } = props
  const formProps = useContext(FormContext)
  const [numberInputValue, setInputValue] = useState(0)

  const [valid, setValidity] = useState(true)
  const [errorMsg, setErrorMsg] = useState('')

  const dateCalculator = numMonths => moment(formProps.values[base]).add(Number(numMonths), 'months').toDate()

  useEffect(() => {
    if (formProps.values[base] && formProps.values[target]) {
      const startDateMoment = moment(formProps.values[base])
      const expirationDateMoment = moment(formProps.values[target])
      setInputValue(expirationDateMoment.diff(startDateMoment, 'months'))
    } else if (!formProps.values[base]) {
      setInputValue(0)
    }
  }, [formProps.values, base, target])

  const handleOnBlur = ev => {
    if (isNaN(ev.target.value)) {
      setValidity(false)
      setErrorMsg('Please provide a whole number')
      return false
    }
    setInputValue(ev.target.value)
    const newDate = dateCalculator(ev.target.value)
    formProps.handleFieldBlur(target, newDate)
    if (onBlur) onBlur(ev)
  }

  const handleOnChange = ev => {
    if (isNaN(ev.target.value) || ev.target.value === '') return
    setInputValue(ev.target.value)
    const newDate = dateCalculator(parseFloat(ev.target.value))
    formProps.handleFieldBlur(target, newDate)

    if (onChange) onChange(ev)
  }

  const handleSubtractValue = () => {
    if (isNaN(numberInputValue)) return
    let newValue = parseFloat(numberInputValue)
    newValue -= parseFloat(step)
    setInputValue(newValue)
    const newDate = dateCalculator(newValue)
    formProps.handleFieldBlur(target, newDate)
  }

  const handleAddValue = () => {
    if (isNaN(numberInputValue)) return
    let newValue = parseFloat(numberInputValue)
    newValue += parseFloat(step)
    setInputValue(newValue)
    const newDate = dateCalculator(newValue)
    formProps.handleFieldBlur(target, newDate)
  }

  const numberInputProperties = {
    type: 'text',
    className: classNames('input', 'has-text-centered', 'form-number-input', { 'is-danger': !valid }, classes),
    placeholder,
    id,
    name,
    disabled: formProps.values[base] === '',
    value: numberInputValue,
    onChange: ev => handleOnChange(ev),
    onBlur: ev => handleOnBlur(ev),
    onFocus: ev => ev.target.select()
  }

  const numberInputMarkup = (
    <>
      {label && <label className='label'>{label}</label>}
      <div className='field has-addons'>
        <div className='control'>
          <button className='button is-dark' type='button' disabled={numberInputValue <= (parseFloat(min) || 0)} onClick={handleSubtractValue}>
            <span className='icon'><FontAwesomeIcon icon={['fas', 'minus']} /></span>
          </button>
        </div>
        <div className='control'>
          <input {...numberInputProperties} />
        </div>
        <div className='control'>
          <button className='button is-dark' type='button' disabled={max ? numberInputValue >= parseFloat(max) : formProps.values[base] === ''} onClick={handleAddValue}>
            <span className='icon'><FontAwesomeIcon icon={['fas', 'plus']} /></span>
          </button>
        </div>
      </div>
      {!valid && (<p className='help is-danger'>{errorMsg}</p>)}
    </>
  )

  return numberInputMarkup
}

export default ExpCalculator
