import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js'
import * as yup from 'yup'

const phoneValidation = () => {
  yup.addMethod(yup.string, 'phone', function (errorMessage) {
    return this.test('test-card-type', errorMessage, function (value) {
      const { path, createError } = this
      const usCheck = isPossiblePhoneNumber(value, 'US') && isValidPhoneNumber(value, 'US')
      const lengthCheck = value.length === 0
      return (
        (usCheck || lengthCheck) ||
        createError({ path, message: errorMessage })
      )
    })
  })
}

export default phoneValidation
