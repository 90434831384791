import React, { useState } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as yup from 'yup'

const InputField = React.forwardRef((props, ref) => {
  const { id, name, value, onChange, onBlur, placeholder, disabled, required, validator, classes, label, type, iconLeft, iconRight, buttonProps, buttonText, onKeyUp, step, loading } = props

  const [valid, setValidity] = useState(true)
  const [errorMsg, setErrorMsg] = useState('')

  const validate = inputValue => {
    if (!validator || !yup.isSchema(validator)) return
    validator.validate(inputValue, { abortEarly: true })
      .then(value => {
        setValidity(true)
      })
      .catch(err => {
        setValidity(false)
        setErrorMsg(err.errors[0])
      })
  }

  const handleOnBlur = ev => {
    if (validator) validate(ev.target.value)
    if (onBlur) onBlur(ev)
  }
  const handleOnChange = ev => {
    if (validator) validate(ev.target.value)
    if (onChange) onChange(ev)
  }

  const inputProperties = {
    type: type || 'text',
    disabled,
    required,
    className: classNames('input', { 'is-danger': !valid }, classes),
    placeholder,
    id,
    name,
    ref,
    value,
    step,
    onChange: ev => handleOnChange(ev),
    onBlur: ev => handleOnBlur(ev),
    onKeyUp: onKeyUp ? ev => onKeyUp(ev) : null
  }

  let inputMarkup = (
    <>
      <input {...inputProperties} />
      {!valid && (<p className='help is-danger'>{errorMsg}</p>)}
    </>
  )

  if (label) {
    inputMarkup = (
      <div className='field'>
        <label className='label'>{label}</label>
        <div className={classNames('control', { 'is-loading': loading })}>
          <input {...inputProperties} />
        </div>
        {!valid && (<p className='help is-danger'>{errorMsg}</p>)}
      </div>
    )
  }

  if (iconLeft || iconRight) {
    const wrapperClasses = classNames({ 'has-icons-left': iconLeft }, { 'has-icons-right': iconRight }, 'control')
    inputMarkup = (
      <div className={wrapperClasses}>
        <input {...inputProperties} />
        {iconLeft && <span className='icon is-small is-left'>{iconLeft && (<span className='icon'><FontAwesomeIcon icon={iconLeft} /></span>)}</span>}
        {iconRight && <span className='icon is-small is-right'>{iconRight && (<span className='icon'><FontAwesomeIcon icon={iconRight} /></span>)}</span>}
      </div>
    )
  }

  if (buttonProps) {
    const wrapperClasses = classNames({ 'has-icons-left': iconLeft }, { 'has-icons-right': iconRight }, 'control')
    inputMarkup = (
      <div className='field has-addons'>
        {label && <label className='label'>{label}</label>}
        <div className={wrapperClasses}>
          <input {...inputProperties} />
          {iconLeft && <span className='icon is-small is-left'>{iconLeft && (<span className='icon'><FontAwesomeIcon icon={iconLeft} /></span>)}</span>}
          {iconRight && <span className='icon is-small is-right'>{iconRight && (<span className='icon'><FontAwesomeIcon icon={iconRight} /></span>)}</span>}
        </div>
        <div className='control'>
          <button {...buttonProps} className={classNames('button', buttonProps.classes)} type='button'>
            {buttonProps.icon && (<span className='icon'><FontAwesomeIcon icon={buttonProps.icon} /></span>)}
            {buttonText}
          </button>
        </div>
      </div>
    )
  }

  return inputMarkup
})

export default InputField
