import React from 'react'
import { useMulti } from '../../frankentangel'

const LoadOpts = (table, formData) => {
  const [objVals] = useMulti(table, String(formData).split(',').map(Number))
  return objVals
}

const TrackReview = props => {
  const { formData, labels, tables, targetFields } = props
  const content = Object.keys(formData).map((data, index) => {
    if (formData[data] == null) return null
    let reviewValue
    if (tables[index] === null) {
      reviewValue = formData[data]
    } else {
      const objVals = LoadOpts(tables[index], formData[data])
      if (objVals.length === 1) reviewValue = objVals[0].doc[targetFields[index] === null ? tables[index] : targetFields[index]]
      else reviewValue = objVals.map(o => o.doc).map(o => o[targetFields[index] === null ? tables[index] : targetFields[index]]).join(', ')
    }
    return (
      <React.Fragment key={index}>
        <div className='field is-horizontal'>
          <div className='field-label'>
            <label className='label'>{labels[index]}:</label>
          </div>
          <div className='field-body'>{reviewValue === true ? 'Yes' : (reviewValue === false ? 'No' : reviewValue)}</div>
        </div>
      </React.Fragment>
    )
  })
  return content
}

export default TrackReview
