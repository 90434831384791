import React from 'react'

import StatCard from '../StatCard'
import { useAction } from '../../frankentangel'

const SearchesLastSeven = props => {
  const [numOfSearches,, searchLoading] = useAction('search-logs', 'numLastWeek')

  return (
    <StatCard
      title='Searches'
      firstValue={numOfSearches}
      content='Number of Music Searches In The Past 7 Days'
      linksTo='/reports/searches'
      loading={searchLoading}
    />
  )
}

export default SearchesLastSeven
