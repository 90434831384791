import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { faVolume, faCompactDisc, faUserMusic, faGuitarElectric, faSmileBeam, faAmpGuitar, faDrum, faAudioDescription, faSave, faMusicAlt, faHeadSideHeadphones, faTimes, faCheck } from '@fortawesome/pro-solid-svg-icons'
import * as yup from 'yup'

import MainLayout from '../../containers/MainLayout'
import ButtonLink from '../../components/ButtonLink'
import FormWrapper from '../../components/FormWrapper'
import FormContext from '../../components/FormContext'
import FromTextarea from '../../components/FormTextarea'
import FormJoinSelect from '../../components/FormJoinSelect'
import FormJoinChecklist from '../../components/FormJoinChecklist'
import SubmitButton from '../../components/SubmitButton'
import FormErrorsList from '../../components/FormErrorsList'
import FormStatus from '../../components/FormStatus'
import SimpleSelect from '../../components/SimpleSelect'
import { Tabs, Tab } from '../../components/Tabs'
import TrackReview from './TrackReview'
import { useFrankentangel } from '../../frankentangel'
import FileManager from './FileManager'
import ReportTable from '../../components/ReportTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

const CreateTrack = props => {
  const frankentangel = useFrankentangel()
  const history = useHistory()

  const [uploadedFiles, setUploadedFiles] = useState([])

  const submitAlbum = async values => {
    const xTreme = {
      params: values,
      files: uploadedFiles
    }
    const result = await frankentangel.runAction('song', 'addSongs', xTreme)
    history.push(`/album-manager/albums/${result.album}`)
  }

  const tempoOpts = [{ label: 'Fast', value: 'Fast' }, { label: 'Medium', value: 'Medium' }, { label: 'Medium/Fast', value: 'Medium/Fast' }, { label: 'Slow', value: 'Slow' }, { label: 'Medium/Slow', value: 'Medium/Slow' }, { label: 'NA', value: 'NA' }]

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns'>
          <div className='column is-full'>
            <div className='columns'>
              <div className='column is-12'>
                <h1 className='title'>Create Track</h1>
                <ButtonLink target='/track-manager' classes={['is-dark']} icon={['fas', 'arrow-left']} linkText='Back to Track Manager' />
              </div>
            </div>
          </div>
        </div>
        <div className='columns'>
          <div className='column is-full'>
            <FormWrapper
              fields={yup.object({
                albid: yup.number(),
                composer: yup.number(),
                genre: yup.string(),
                instrument: yup.string(),
                mood: yup.string(),
                style: yup.string(),
                tempo: yup.string(),
                format_style: yup.string(),
                description: yup.string(),
                active: yup.boolean()
              })}
              submit={submitAlbum}
            >
              <Tabs
                mode='is-boxed'
                headers={['Album', 'File', 'Composer', 'Genres', 'Instruments', 'Moods', 'Styles', 'Tempo', 'Formats', 'Description', 'Submit']}
                headerIcons={[
                  faCompactDisc, faVolume, faUserMusic, faHeadSideHeadphones, faGuitarElectric, faSmileBeam, faAmpGuitar, faDrum, faMusicAlt, faAudioDescription, faSave
                ]}
              >
                <Tab>
                  <FormJoinSelect name='albid' placeholder='Album' label='Album' table='album' rows={alb => alb.album_name} />
                  <SimpleSelect name='active' opts={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} preSelectedValue label='Active' />
                </Tab>
                <Tab>
                  <FileManager
                    getTickets={async files => (await frankentangel.runAction('song', 'getUploadTickets', { filenames: files.map(file => file.name) })).tickets}
                    files={uploadedFiles}
                    setFiles={setUploadedFiles}
                  />
                </Tab>
                <Tab>
                  <FormJoinSelect name='composer' placeholder='Composer' label='Composer' table='composer' rows={comp => comp.composer} />
                </Tab>
                <Tab>
                  <FormJoinChecklist name='genre' table='genre' rows={genre => genre.genre} columnLabel='Genres' autoFocus />
                </Tab>
                <Tab>
                  <FormJoinChecklist name='instrument' table='instrument' rows={format => format.instrument} columnLabel='Instrument' autoFocus />
                </Tab>
                <Tab>
                  <FormJoinChecklist name='mood' table='mood' rows={format => format.mood} columnLabel='Mood' autoFocus />
                </Tab>
                <Tab>
                  <FormJoinChecklist name='style' table='style' rows={format => format.style} columnLabel='Style' autoFocus />
                </Tab>
                <Tab>
                  <SimpleSelect name='tempo' placeholder='Select Tempo' label='Tempo' opts={tempoOpts} />
                </Tab>
                <Tab>
                  <FormJoinChecklist name='format_style' table='format' rows={format => format.format} columnLabel='Format Style' autoFocus />
                </Tab>
                <Tab>
                  <FromTextarea name='description' placeholder='Description' label='Description (visible to TAA employees only)' rows='10' />
                </Tab>
                <Tab>
                  <FormContext.Consumer>
                    {
                      formValues => <TrackReview
                        formData={formValues.values}
                        labels={['Album', 'Composer', 'Genres', 'Instruments', 'Moods', 'Styles', 'Tempo', 'Formats', 'Description', 'Active']}
                        tables={['album', 'composer', 'genre', 'instrument', 'mood', 'style', null, 'format', null, null]}
                        targetFields={['album_name', null, null, null, null, null, null, null, null, null]}
                                    />
                    }
                  </FormContext.Consumer>
                  <h3 className='title is-4 mt-6'>Uploaded Files</h3>
                  <ReportTable
                    headings={['Song File', 'Mix', 'Length', '128', '320', 'WAV', 'Song Name']}
                    tableData={uploadedFiles.map(file => [
                      file.songFile,
                      file.mix,
                      file.length,
                      <FontAwesomeIcon key={`has128-${file.id}`} icon={file.has128 ? faCheck : faTimes} className={classNames({ 'has-text-danger': !file.has128 })} />,
                      <FontAwesomeIcon key={`has320-${file.id}`} icon={file.has320 ? faCheck : faTimes} className={classNames({ 'has-text-danger': !file.has320 })} />,
                      <FontAwesomeIcon key={`hasWav-${file.id}`} icon={file.hasWav ? faCheck : faTimes} className={classNames({ 'has-text-danger': !file.hasWav })} />,
                      file.songName
                    ])}
                  />
                  <FormErrorsList />
                  <SubmitButton buttonText='Save' classes={['has-medium-top-margin']} />
                  <FormStatus />
                </Tab>
              </Tabs>
            </FormWrapper>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CreateTrack
