import React from 'react'
import moment from 'moment'

import Button from '../../components/Button'
import { useFrankentangel } from '../../frankentangel'
import useModal from '../../components/useModal'

const CommercialWeek = props => {
  const { numItems, sumTime, week } = props
  const monday = week.doc.monday
  const frankentangel = useFrankentangel()
  // const currentWeek = moment().week()
  const weekNumber = moment(new Date(monday)).week()
  const weekDate = moment(new Date(monday)).format('dddd, MMMM DD, YYYY')
  const buttonProps = {}

  const currentDate = new Date().toISOString()
  const mondayDate = new Date(new Date(monday).getTime() + 7 * 24 * 3600 * 1000).toISOString()

  buttonProps.text = week.doc.published === 0 ? 'Locked' : (mondayDate.localeCompare(currentDate) > -1 ? 'Available' : 'Complete')
  buttonProps.icon = week.doc.published === 0 ? ['fas', 'lock-alt'] : (mondayDate.localeCompare(currentDate) > -1 ? ['fas', 'broadcast-tower'] : ['fas', 'check'])

  const [modal, showModal] = useModal()

  const handleWeekStateChange = async () => {
    // if (moment().isAfter(new Date(monday))) return

    if (!week.doc.published) {
      const response = await showModal(
        'Publish Commercials',
        'This will make available all commercials for downloading by subscribers in the customer portal. Users listed in this system as Compliance Users will receive an email notification informing them that this set of commercials is available for download. Would you like to continue with publishing?',
        [{
          text: 'Cancel',
          response: 'no',
          isCancel: true
        }, {
          text: 'Publish',
          className: 'is-danger',
          response: 'yes'
        }]
      )
      if (response !== 'yes') return
    }

    await frankentangel.updateDocument('commercial-weeks', week.id, { published: week.doc.published === 0 })
  }

  return (
    <div className='column is-full mt-6'>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item is-flex-direction-column'>
            <p className='title is-4'>
              {`Week ${weekNumber} - ${weekDate}`}
              <br />
              <span className='subtitle has-text-weight-normal mt-1 is-block'>
                {`${numItems} Spot(s), ${sumTime} Minute(s)`}
              </span>
            </p>
          </div>
        </div>
        <div className='level-right'>
          <div className='level-item'>
            <Button onClick={handleWeekStateChange} icon={buttonProps.icon}>{buttonProps.text}</Button>
          </div>
        </div>
      </div>
      {modal}
    </div>
  )
}

export default CommercialWeek
