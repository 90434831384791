import React, { useEffect } from 'react'
import classNames from 'classnames'

import useForm from './useForm'

const SimpleSelect = props => {
  const { id, name, onBlur, onChange, placeholder, disabled, required, label, classes, type, opts, preSelectedValue } = props
  const [fieldValue, fieldState, handleFieldChange, handleFieldBlur] = useForm(name)

  const selectOpts = opts.map(option => (<option key={option.value} value={option.value}>{option.label}</option>))
  if (placeholder) selectOpts.unshift(<option key='placeholder' value='' disabled>{placeholder}</option>)

  useEffect(() => {
    if (preSelectedValue && fieldValue === '') handleFieldChange(name, preSelectedValue)
  }, []) // eslint-disable-line

  const handleOnBlur = ev => {
    let valueToUpdate = ev.target.value
    if (valueToUpdate === 'false') valueToUpdate = false
    if (valueToUpdate === 'true') valueToUpdate = true
    handleFieldBlur(name, valueToUpdate)
    if (onBlur) onBlur(ev)
  }
  const handleOnChange = ev => {
    let valueToUpdate = ev.target.value
    if (valueToUpdate === 'false') valueToUpdate = false
    if (valueToUpdate === 'true') valueToUpdate = true
    handleFieldChange(name, valueToUpdate)
    if (onChange) onChange(ev)
  }

  const handleKeyDown = ev => {
    if (ev.ctrlKey || ev.metaKey) ev.preventDefault()
  }

  const selectProperties = {
    type: type || 'text',
    disabled,
    required,
    className: classNames('input', { 'is-danger': fieldState.state === 'invalid' }, classes),
    id,
    name,
    value: fieldValue,
    onChange: ev => handleOnChange(ev),
    onBlur: ev => handleOnBlur(ev),
    onKeyDown: ev => handleKeyDown(ev)
  }

  let selectMarkup = (
    <div className='select'>
      <select {...selectProperties}>
        {selectOpts}
      </select>
      {fieldState.state === 'invalid' && (<p className='help is-danger'>{fieldState.errorMsg}</p>)}
    </div>
  )

  if (label) {
    selectMarkup = (
      <div className='field'>
        <label className='label'>{label}</label>
        <div className='control'>
          <div className='select'>
            <select {...selectProperties}>
              {selectOpts}
            </select>
          </div>
        </div>
        {fieldState.state === 'invalid' && (<p className='help is-danger'>{fieldState.errorMsg}</p>)}
      </div>
    )
  }

  return selectMarkup
}

export default SimpleSelect
