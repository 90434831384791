import React from 'react'
// import LoadingScreen from '../LoadingScreen'
import ErrorPage from '../ErrorPage'
import MainLayout from '../../containers/MainLayout'
import AdminUserCard from '../../components/AdminUserCard'
import { useList } from '../../frankentangel'
import ButtonLink from '../../components/ButtonLink'

const AdminUsers = props => {
  const [Paginator, result, error] = useList('users', 'listAdmins')

  // if (loading) return <LoadingScreen />
  if (error) return <ErrorPage error={error} />

  const users = result.map(user => <AdminUserCard user={user.doc} key={user.id} />)

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <Paginator className='columns is-multiline'>
          <div className='column is-full'>
            <h1 className='title'>Admin Users</h1>
            <ButtonLink target='/admin-users/create' classes={['is-dark']} icon={['fas', 'user-plus']} linkText='Create Admin User' />
          </div>
          {users}
        </Paginator>
      </div>
    </MainLayout>
  )
}

export default AdminUsers
