import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CheckCompliance = props => {
  const { accounts, userId, children } = props
  const compliances = accounts.map(account => {
    const compliance = {}
    if (account.complianceBoss === userId) compliance.boss = true
    if (account.complianceUser === userId) compliance.user = true
    if (!compliance.boss && !compliance.user) return null
    if (compliance.boss || compliance.user) compliance.name = account.name
    return compliance
  }).map(compliance => {
    if (compliance === null) return null
    const content = []
    if (compliance.boss) {
      content.push(
        <div className='control' key={`boss-${compliance.name}`}>
          <div className='tags has-addons'>
            <span className='tag has-background-grey-dark has-text-white'><span className='icon'><FontAwesomeIcon icon={['fas', 'tag']} /></span>&nbsp;Compliance Boss</span>
            <span className='tag has-background-grey-light'>{compliance.name}</span>
          </div>
        </div>
      )
    }
    if (compliance.user) {
      content.push(
        <div className='control' key={`user-${compliance.name}`}>
          <div className='tags has-addons'>
            <span className='tag has-background-grey-dark has-text-white'><span className='icon'><FontAwesomeIcon icon={['fas', 'tag']} /></span>&nbsp;Compliance User</span>
            <span className='tag has-background-grey-light'>{compliance.name}</span>
          </div>
        </div>
      )
    }
    return content
  })

  const complianceMarkup = (
    <div className='field is-grouped is-grouped-multiline mt-4'>
      {compliances}
      {children}
    </div>
  )

  return complianceMarkup
}

export default CheckCompliance
