import React from 'react'
import MainLayout from '../containers/MainLayout'
import useGlobalState from '../components/useGlobalState'
import { useSearch } from '../frankentangel'
import ErrorPage from './ErrorPage'
import SearchResult from '../components/SearchResult'

const Search = props => {
  const [searchQuery] = useGlobalState('search', '')
  const [Paginator, result, error] = useSearch(searchQuery)

  if (error) {
    return <ErrorPage error={error} />
  }

  const results = result.filter(res => ['users', 'contracts', 'accounts'].includes(res.table)).map(res => <SearchResult type={res.table} result={res.doc} key={`${res.table}-${res.id}`} />)

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <Paginator className='columns is-multiline'>
          {results}
        </Paginator>
      </div>
    </MainLayout>
  )
}

export default Search
