import React, { useState } from 'react'
import DayPicker from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import classNames from 'classnames'
import moment from 'moment'

import useForm from './useForm'

import { formatDate, parseDate } from 'react-day-picker/moment'

const DatePicker = props => {
  const { id, name, onBlur, onChange, disabled, label, classes, full, placeholder, format, weekSelector, startDay, justFirstDayOfWeek } = props
  const [fieldValue, fieldState, handleFieldChange] = useForm(name)

  const handleOnChange = day => {
    const changeValue = justFirstDayOfWeek ? day[0] : day
    handleFieldChange(name, changeValue)
  }

  const [hoverRange, setHoverRange] = useState(undefined)
  const [selectedDays, setSelectedDays] = useState([])

  const getWeekDays = weekStart => {
    const days = [weekStart]
    for (let i = 1; i < 7; i += 1) {
      days.push(
        moment(weekStart)
          .add(i, 'days')
          .toDate()
      )
    }
    return days
  }

  const getWeekRange = date => {
    return {
      from: moment(date)
        .startOf('isoWeek')
        .toDate(),
      to: moment(date)
        .endOf('isoWeek')
        .toDate()
    }
  }

  const handleDayChange = date => {
    setSelectedDays(getWeekDays(getWeekRange(date).from))
    onChange ? onChange(getWeekDays(getWeekRange(date).from)) : handleOnChange(getWeekDays(getWeekRange(date).from))
  }

  const handleDayEnter = date => setHoverRange(getWeekRange(date))

  const handleDayLeave = () => setHoverRange(undefined)

  const handleWeekClick = (weekNumber, days, e) => {
    setSelectedDays(days)
    onChange ? onChange(days) : handleOnChange(days)
  }

  const daysAreSelected = selectedDays.length > 0

  const modifiers = {
    hoverRange,
    selectedRange: daysAreSelected && {
      from: selectedDays[0],
      to: selectedDays[6]
    },
    hoverRangeStart: hoverRange && hoverRange.from,
    hoverRangeEnd: hoverRange && hoverRange.to,
    selectedRangeStart: daysAreSelected && selectedDays[0],
    selectedRangeEnd: daysAreSelected && selectedDays[6]
  }

  const dayPickerInputProps = {
    value: fieldValue === '' ? fieldValue : formatDate(fieldValue),
    onDayChange: onChange ? day => onChange(day) : day => handleOnChange(day),
    inputProps: {
      name,
      onBlur: onBlur,
      type: 'text',
      id,
      disabled,
      className: classNames('input', { 'is-danger': fieldState.state === 'invalid' }, classes)
    },
    formatDate: formatDate,
    parseDate: parseDate,
    format: format || 'MM/DD/YY',
    placeholder: `${placeholder || formatDate(new Date(), 'MM/DD/YY')}`,
    keepFocus: false,
    dayPickerProps: {
      selectedDays: fieldValue,
      firstDayOfWeek: startDay || 0
    }
  }

  let dayPickerExpandedProps = {
    className: classNames({ 'is-danger': fieldState.state === 'invalid' }, classes),
    fromMonth: new Date(),
    selectedDays: fieldValue || null,
    fixedWeeks: true,
    onDayClick: onChange ? day => onChange(day) : day => handleOnChange(day),
    showWeekNumbers: true,
    firstDayOfWeek: startDay || 0,
    onBlur: onBlur
  }

  if (weekSelector) {
    dayPickerExpandedProps = {
      onBlur: onBlur,
      className: classNames('is-weekSelector', { 'is-danger': fieldState.state === 'invalid' }, classes),
      fromMonth: new Date(),
      selectedDays: fieldValue || null,
      fixedWeeks: true,
      showWeekNumbers: true,
      showOutsideDays: true,
      firstDayOfWeek: startDay || 0,
      modifiers,
      onDayClick: handleDayChange,
      onDayMouseEnter: handleDayEnter,
      onDayMouseLeave: handleDayLeave,
      onWeekClick: handleWeekClick
    }
  }

  let dayPickerMarkup

  if (full) {
    dayPickerMarkup = (
      <div className='field'>
        {label && <label className='label'>{label}</label>}
        <div className='control'>
          <DayPicker {...dayPickerExpandedProps} />
        </div>
        {fieldState.state === 'invalid' && (<p className='help is-danger'>{fieldState.errorMsg}</p>)}
      </div>
    )
  } else {
    dayPickerMarkup = (
      <div className='field'>
        {label && <label className='label'>{label}</label>}
        <div className='control'>
          <DayPickerInput {...dayPickerInputProps} />
        </div>
        {fieldState.state === 'invalid' && (<p className='help is-danger'>{fieldState.errorMsg}</p>)}
      </div>
    )
  }

  return dayPickerMarkup
}

export default DatePicker
