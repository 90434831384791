import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const AdminUserCard = props => {
  const { user } = props
  const history = useHistory()
  const [goEdit, triggerEdit] = useState(false)

  useEffect(() => {
    if (goEdit) history.push(`/admin-users/${user.id}`)
  })

  return (
    <div className='column is-one-third'>
      <div className='card' onClick={() => triggerEdit(true)}>
        <div className='card-image'>
          <small className={`px-2 py-1 has-text-weight-bold is-block ${user.isSuperAdmin ? 'has-background-grey-light' : 'has-background-dark'} has-text-white`}>
            {user.isSuperAdmin ? 'SuperAdmin' : 'Admin'}
          </small>
        </div>
        <div className='card-content'>
          <p className='title is-4'>{`${user.firstName} ${user.lastName}`}</p>
          <p className='subtitle is-6'>{user.email}</p>
        </div>
      </div>
    </div>
  )
}

export default AdminUserCard
