import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import MainLayout from '../../containers/MainLayout'
import { useFrankentangel } from '../../frankentangel'
import FormWrapper from '../../components/FormWrapper'
import FromInputField from '../../components/FormInputField'
import FormCommercialOccurences from '../../components/FormCommercialOccurences'
import SimpleSelect from '../../components/SimpleSelect'
import SubmitButton from '../../components/SubmitButton'
import FormErrorsList from '../../components/FormErrorsList'
import FormTextarea from '../../components/FormTextarea'
import FormJoinChecklist from '../../components/FormJoinChecklist'
import FormJoinSelect from '../../components/FormJoinSelect'
import FormStatus from '../../components/FormStatus'
import ButtonLink from '../../components/ButtonLink'
import { Tabs, Tab } from '../../components/Tabs'
import FormContext from '../../components/FormContext'
import CommercialReview from './CommercialReview'
import FormUploadField from '../../components/FormUploadField'
import toWeekDay from '../../utils/to-weekday'

const Commercial = props => {
  const { id } = useParams()
  const frankentangel = useFrankentangel()
  const [commercial, setCommercial] = useState({})

  const submitCommercialData = async values => {
    await frankentangel.updateDocument('commercials', commercial.id, values)
    fetchCommercial()
    return 'Commercial saved'
  }

  const fetchCommercial = async () => {
    const fetchedCommercial = await frankentangel.viewDocument('commercials', Number(id))
    fetchedCommercial.markets = (fetchedCommercial.markets || []).map(market => market.id).join(', ')
    fetchedCommercial.regions = (fetchedCommercial.regions || []).map(region => region.id).join(', ')
    fetchedCommercial.formats = (fetchedCommercial.formats || []).map(format => format.id).join(', ')
    fetchedCommercial.agency = fetchedCommercial.agency ? fetchedCommercial.agency.id : 0
    fetchedCommercial.accountRule = fetchedCommercial.accountRule !== 0
    setCommercial(fetchedCommercial)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    fetchCommercial()
  }, []) // eslint-disable-line

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns is-multiline'>
          <div className='column is-full'>
            <h1 className='title'>Edit Commercial</h1>
            <ButtonLink target='/commercials' icon={['fas', 'arrow-left']} linkText='Back to Commercials' />
          </div>
        </div>
        <div className='columns'>
          <div className='column is-full'>
            <FormWrapper
              fields={yup.object({
                name: yup.string().required('Required').max(255),
                description: yup.string().required('Required').max(15000),
                duration: yup.string().oneOf([':15', ':30', ':60'], 'Please choose one of these values: :15, :30, :60').required('Required'),
                week: yup.date('Please choose a valid date').typeError('Please enter a valid date').required('Required'),
                agency: yup.number().required('Required'),
                days: yup.array().required('Required'),
                language: yup.string().oneOf(['English', 'Spanish'], 'Please choose one of these values: English, Spanish').required('Required'),
                languageTarget: yup.string().oneOf(['English', 'Spanish', 'English and Spanish'], 'Please choose one of these values: English, Spanish').required('Required'),
                file: yup.string().required('Required'),
                isci: yup.string().max(50, 'Cannot exceed 50 chars'),
                orderNumber: yup.string().max(50, 'Cannot exceed 50 chars'),
                accountRule: yup.boolean().required('Required'),
                formats: yup.string(),
                regions: yup.string(),
                markets: yup.string(),
                states: yup.string(),
                zips: yup.string(),
                accountsEnabled: yup.string(),
                accountsDisabled: yup.string(),
                instructions: yup.string().required('Required')
              })}
              initialValuesFrom={commercial}
              submit={submitCommercialData}
            >
              <Tabs
                mode='is-boxed'
                headers={['Basic Information', 'Formats', 'Regions', 'Markets', 'States', 'Zip Codes', 'Accounts', 'Special Instructions', 'Review and Save']}
                headerIcons={[['fas', 'info-square'], ['fas', 'compact-disc'], ['fas', 'globe-americas'], ['fas', 'megaphone'], ['fas', 'route-interstate'], ['fas', 'mailbox'], ['fas', 'user-circle'], ['fas', 'exclamation-circle'], ['fas', 'clipboard-list']]}
              >
                <Tab>
                  <FromInputField name='name' placeholder='Commercial Name' label='Commercial Name' iconLeft={['fas', 'file']} autoFocus />
                  <FromInputField name='description' placeholder='Description' label='Commercial Description' />
                  <SimpleSelect
                    name='duration'
                    opts={[{ label: ':15', value: ':15' }, { label: ':30', value: ':30' }, { label: ':60', value: ':60' }]} label='Duration'
                  />
                  <FormContext.Consumer>
                    {
                      formState => (
                        <FromInputField
                          name='week'
                          placeholder='Run Week'
                          label='Run Week'
                          onBlur={() => {
                            console.log(toWeekDay(formState.values.week, 'Mon').toString())
                            if ((formState.values.week.match(/^[0-1]?[0-9]\/[0-3][0-9]\/[0-9][0-9]$/) && (toWeekDay(formState.values.week, 'Mon').toString() !== 'Invalid Date'))) {
                              formState.handleFieldBlur('week', toWeekDay(formState.values.week, 'Mon').toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }))
                            }
                          }}
                        />
                      )
                    }
                  </FormContext.Consumer>
                  <FormCommercialOccurences
                    name='days'
                  />
                  <SimpleSelect
                    name='language'
                    opts={[{ label: 'English', value: 'English' }, { label: 'Spanish', value: 'Spanish' }]} label='Language'
                  />
                  <SimpleSelect
                    name='languageTarget'
                    opts={[{ label: 'English', value: 'English' }, { label: 'Spanish', value: 'Spanish' }, { label: 'English and Spanish', value: 'English and Spanish' }]} label='Target Languages' preSelectedValue='English'
                  />
                  <FormContext.Consumer>
                    {
                      formValues =>
                        <FromInputField
                          name='isci'
                          placeholder='ISCI'
                          label='ISCI'
                          iconLeft={['fas', 'tag']}
                          addOnWithButton={{
                            buttonText: 'Random ISCI',
                            handleOnClick: () => formValues.handleFieldChange('isci', 'TAA-' + Array.from(window.crypto.getRandomValues(new Uint32Array(2))).map(a => (a / 0xFFFFFFFF).toString(36).slice(2, 6)).join('').toUpperCase())
                          }}
                        />
                    }
                  </FormContext.Consumer>
                  <FormContext.Consumer>
                    {
                      formState => (
                        <FormUploadField
                          name='file'
                          label='File'
                          getTicket={async (frankentangel, file) => {
                            const ticket = await frankentangel.runAction('commercials', 'getUploadTicket', { filename: file.name })
                            return ticket.ticketId
                          }}
                          help={formState.values.isci ? `The uploaded file will be renamed to ${formState.values.isci}.mp3` : 'Pending ISCI'}
                        />
                      )
                    }
                  </FormContext.Consumer>
                  <FormJoinSelect name='agency' placeholder='Select Ad Agency' label='Ad Agency' table='commsagency' rows={agency => agency.name} />
                  <FromInputField name='orderNumber' placeholder='Order Number' label='Order Number' iconLeft={['fas', 'tag']} />
                  <SimpleSelect
                    name='accountRule'
                    opts={[{ label: 'EXCLUDE all accounts', value: false }, { label: 'INCLUDE all accounts', value: true }]} label='Account Rule'
                  />
                </Tab>
                <Tab>
                  <FormContext.Consumer>
                    {
                      formValues => <FormJoinChecklist name='formats' table='commsformat' rows={format => format.name} invert={formValues.values.accountRule} columnLabel='Format' autoFocus />
                    }
                  </FormContext.Consumer>
                </Tab>
                <Tab>
                  <FormContext.Consumer>
                    {
                      formValues => <FormJoinChecklist name='regions' table='commsregion' rows={region => region.name} invert={formValues.values.accountRule} columnLabel='Region' autoFocus />
                    }
                  </FormContext.Consumer>
                </Tab>
                <Tab>
                  <FormContext.Consumer>
                    {
                      formValues => <FormJoinChecklist name='markets' table='commsmarket' rows={market => market.name} invert={formValues.values.accountRule} columnLabel='Market' autoFocus />
                    }
                  </FormContext.Consumer>
                </Tab>
                <Tab>
                  <FormContext.Consumer>
                    {
                      formValues => <FormJoinChecklist name='states' table='addresses' rows={state => state} invert={formValues.values.accountRule} columnLabel='State' action='listStates' autoFocus />
                    }
                  </FormContext.Consumer>
                </Tab>
                <Tab>
                  <FormContext.Consumer>
                    {
                      formValues => <FormJoinChecklist name='zips' table='addresses' rows={zip => zip} invert={formValues.values.accountRule} columnLabel='Zip Code' action='listZipCodes' autoFocus />
                    }
                  </FormContext.Consumer>
                </Tab>
                <Tab>
                  <FormContext.Consumer>
                    {
                      formValues =>
                        <FormJoinChecklist
                          overridePositive='accountsEnabled'
                          overrideNegative='accountsDisabled'
                          table='accounts'
                          rows={account => <><strong>{account.callsign}</strong> {account.name}</>}
                          isEnabled={account => {
                            const { values } = formValues
                            const defaultValue = Boolean(values.accountRule)

                            if (account.format && values.formats.split(',').map(Number).includes(account.format.id)) return !defaultValue
                            if (account.market && values.markets.split(',').map(Number).includes(account.market.id)) return !defaultValue
                            if (account.region && values.regions.split(',').map(Number).includes(account.region.id)) return !defaultValue
                            if (account.address && values.states.split(',').includes(account.address.state)) return !defaultValue
                            if (account.address && values.zips.split(',').includes(account.address.zip)) return !defaultValue

                            return defaultValue
                          }}
                          isVisible={account => {
                            const { values } = formValues

                            if (!account.contract) return false
                            if (account.contract.contractType === 'Cash') return false

                            if (values.languageTarget === 'English' && account.language === 'Spanish') return false
                            if (values.languageTarget === 'Spanish' && account.language === 'English') return false

                            return true
                          }}
                        // enableAll={formValues.values.acccountRule}
                          columnLabel='Account'
                          autoFocus
                        />
                    }
                  </FormContext.Consumer>
                </Tab>
                <Tab>
                  <FormTextarea name='instructions' placeholder='Instructions' label='Special instructions' rows='10' autoFocus />
                </Tab>
                <Tab>
                  <FormContext.Consumer>
                    {
                      formValues => <CommercialReview values={formValues.values} />
                    }
                  </FormContext.Consumer>
                  <div className='divider has-no-gap' />
                  <FormErrorsList />
                  <SubmitButton buttonText='Save' classes={['has-medium-top-margin']} />
                  <FormStatus />
                </Tab>
              </Tabs>
            </FormWrapper>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Commercial
