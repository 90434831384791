import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import * as yup from 'yup'

import MainLayout from '../../containers/MainLayout'
import { useFrankentangel } from '../../frankentangel'
import FormWrapper from '../../components/FormWrapper'
import FormInputField from '../../components/FormInputField'
import SimpleSelect from '../../components/SimpleSelect'
import SubmitButton from '../../components/SubmitButton'
import FormErrorsList from '../../components/FormErrorsList'
import FormStatus from '../../components/FormStatus'
import useModal from '../../components/useModal'
import usePasswordInput from '../../components/usePasswordInput'
import Button from '../../components/Button'
import ButtonLink from '../../components/ButtonLink'
import ObjectDelete from '../../components/ObjectDelete'

const AdminUser = props => {
  const { id } = useParams()
  const frankentangel = useFrankentangel()
  const [user, setUser] = useState({})
  const [passwordPopup, setPasswordPopup] = useState(false)
  const [passwordLoading, setPasswordLoading] = useState(false)

  const [passwordInput, getPassword, resetPassword] = usePasswordInput()
  const [modal, showModal] = useModal()

  const startPassword = () => {
    resetPassword()
    setPasswordPopup(true)
  }

  const cancelPassword = () => {
    setPasswordPopup(false)
    resetPassword()
  }

  const savePassword = async () => {
    const { password, ready } = getPassword()
    if (!ready) return console.error('Password not ready yet')

    setPasswordLoading(true)

    const result = await frankentangel.updateDocument('users', user.id, { password })

    if (result.ok) {
      setPasswordPopup(false)
      setPasswordLoading(false)
      showModal(
        'Password saved',
        `New password for user ${user.firstName} ${user.lastName} successfully saved`,
        [{
          text: 'OK',
          response: 'ok',
          isCancel: true
        }]
      )
    } else {
      const errorMsg = result.error ? (result.error.message || result.error) : 'Unknown Error'
      await showModal(
        'Error',
        `Unknown error while saving password for user ${user.firstName} ${user.lastName}: ${errorMsg}`,
        [{
          text: 'OK',
          response: 'ok',
          isCancel: true
        }]
      )
      setPasswordLoading(false)
    }
  }

  const fetchUser = async () => {
    const fetchedUser = await frankentangel.viewDocument('users', Number(id))
    setUser(fetchedUser)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    fetchUser()
  }, []) // eslint-disable-line

  const submitUserData = async values => {
    await frankentangel.updateDocument('users', user.id, values)
    fetchUser()
    return 'User settings saved'
  }

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns is-multiline'>
          <div className='column is-full'>
            <h1 className='title'>Admin User Profile</h1>
            <ButtonLink target='/admin-users' icon={['fas', 'arrow-left']} linkText='Back to Admin Users' />&nbsp;
            <ObjectDelete objectName='admin user' tableName='users' objectId={user.id} objectTitle={`${user.firstName} ${user.lastName}`} />
          </div>
        </div>
        <div className='columns'>
          <div className='column is-half'>
            <FormWrapper
              fields={yup.object({
                firstName: yup.string().max(128).required('Required'),
                lastName: yup.string().max(128).required('Required'),
                phone: yup.string().max(255).phone('Invalid phone number'),
                email: yup.string().email().required('Required'),
                title: yup.string(),
                isActive: yup.boolean().required('Required'),
                receivesEmails: yup.boolean()
              })}
              initialValuesFrom={user}
              submit={submitUserData}
            >
              <FormInputField name='firstName' placeholder='First Name' label='First Name' iconLeft={['fas', 'user']} />
              <FormInputField name='lastName' placeholder='Last Name' label='Last Name' iconLeft={['fas', 'user']} />
              <FormInputField
                name='phone'
                placeholder='Phone'
                label='Phone'
                iconLeft={['fas', 'phone']}
                phoneType
                type='tel'
              />
              <FormInputField name='email' placeholder='Email Address' label='Email Address' iconLeft={['fas', 'at']} />
              <FormInputField name='title' placeholder='Title' label='Title' iconLeft={['fas', 'id-card']} suggestions={{ table: 'users', action: 'listTitles' }} />
              <SimpleSelect name='isActive' opts={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} label='Active' />
              <SimpleSelect
                name='receivesEmails'
                label='Receives Emails'
                opts={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                preSelectedValue
              />
              <FormErrorsList />
              <Button onClick={startPassword} icon={['fas', 'lock-alt']}>Change Password</Button><br />
              <SubmitButton buttonText='Save' classes={['has-medium-top-margin']} />
              <FormStatus />
            </FormWrapper>
          </div>
        </div>
      </div>
      <div className={classNames('modal', {
        'is-active': passwordPopup
      })}
      >
        <div className='modal-background' onClick={cancelPassword} />
        <div className='modal-card'>
          <header className='modal-card-head'>
            <p className='modal-card-title'>Set password for user {user.firstName} {user.lastName}</p>
            <button className='delete' aria-label='close' onClick={cancelPassword} />
          </header>
          <section className='modal-card-body user-row__password-prompt'>
            {passwordInput}
            <div className={classNames('user-row__password-loading', {
              'user-row__password-loading--enabled': passwordLoading
            })}
            >
              <FontAwesomeIcon icon={['fas', 'sync-alt']} spin size='10x' />
            </div>
          </section>
          <footer className='modal-card-foot'>
            <Button classes={['is-dark', 'mod-disabled']} disabled={!getPassword().ready || passwordLoading} icon={['fas', 'save']} onClick={savePassword}>
              Save password
            </Button>
            &nbsp;
            <Button icon={['fas', 'times-circle']} onClick={cancelPassword}>
              Cancel
            </Button>
          </footer>
        </div>
      </div>
      {modal}
    </MainLayout>
  )
}

export default AdminUser
