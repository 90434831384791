import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt, faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'
import * as yup from 'yup'

import MainLayout from '../../containers/MainLayout'
import ReportTable from '../../components/ReportTable'
import ErrorPage from '../ErrorPage'
import ButtonLink from '../../components/ButtonLink'

import FormWrapper from '../../components/FormWrapper'
import FormJoinSelect from '../../components/FormJoinSelect'
import FormContext from '../../components/FormContext'
import { useList, useAction, useFrankentangel } from '../../frankentangel'

const CommercialDownloads = props => {
  const frankentangel = useFrankentangel()
  const [commsWeeks] = useAction('commercial-weeks', 'listPublished')
  const [selectedWeek, selectWeek] = useState(commsWeeks?.list[0]?.doc.actualDate.replaceAll('-', '/'))
  const [unicorn] = useState({})
  const [Paginator, results, error] = useList('dashboard-stations-with-download', 'commercialDownloadList', { week: selectedWeek || commsWeeks?.list[0]?.doc.actualDate || 'dummy' })
  const firstDayThisWeek = moment(selectedWeek?.replaceAll('-', '/') || commsWeeks?.list[0]?.doc.actualDate).format('dddd, MMM DD, YYYY')

  if (error) {
    return <ErrorPage error={error} />
  }

  const searchResults = results
    ? results.map(aff => aff.doc).map(aff => {
        return [
          `${aff.account?.callsign} - ${aff.account?.name}`,
          ['No', 'Yes, indirectly', 'Yes, directly'][aff.commercialsDownloaded],
          aff.downloadUser ? `${aff.downloadUser.firstName} ${aff.downloadUser.lastName} (${aff.downloadUser.email})` : '\xa0',
          aff.downloadTimestamp ? new Date(aff.downloadTimestamp).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric' }) : '\xa0'
        ]
      })
    : null

  const findCommDate = async idVal => {
    if (unicorn.lastIdVal === idVal) return
    unicorn.lastIdVal = idVal
    const result = await frankentangel.viewDocument('commercial-weeks', idVal)
    selectWeek(result?.actualDate)
  }

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns'>
          <div className='column is-full'>
            <div className='columns'>
              <div className='column is-6'>
                <h1 className='title'>Commercial Downloads</h1>
                <ButtonLink target='/' icon={faArrowLeft} linkText='Back' />
              </div>
              <div className='column is-6'>
                <h2 className='title has-text-right-tablet is-size-5-mobile'>{firstDayThisWeek}</h2>
                <FormWrapper fields={yup.object({ week: yup.string() })}>
                  <FormJoinSelect
                    name='week'
                    placeholder='Select Commercial Week'
                    table='commercial-weeks'
                    action='listPublished'
                    rows={
                      week => new Date(week?.actualDate.replaceAll('-', '/')).toLocaleString('en-US', { weekday: 'long', month: 'short', day: '2-digit', year: 'numeric' })
                    }
                  />
                  <FormContext.Consumer>
                    {
                      formValues => {
                        if (formValues.values.week !== '') findCommDate(formValues.values.week)
                      }
                    }
                  </FormContext.Consumer>
                </FormWrapper>
              </div>
            </div>
          </div>
        </div>
        <div className='columns'>
          <div className='column is-full'>
            <Paginator spinner={<div className='has-text-centered my-4'><FontAwesomeIcon icon={faSyncAlt} spin size='3x' /></div>}>
              <ReportTable
                headings={['Account', 'Downloaded', 'User', 'Downloaded On']}
                tableData={searchResults}
              />
            </Paginator>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CommercialDownloads
