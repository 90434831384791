import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import FormContext from './FormContext'

const SubmitButton = props => {
  const { classes } = props
  const formProps = useContext(FormContext)

  return (
    <button
      type='submit'
      className={classNames('button', 'is-dark', 'is-medium', { 'is-loading': formProps.isSubmitting }, classes)}
      disabled={formProps.isSubmitting}
    >
      <span className='icon'>
        <FontAwesomeIcon icon={props.buttonIcon || ['fad', 'paper-plane']} />
      </span>
      <span>{props.buttonText || 'Submit'}</span>
    </button>
  )
}

export default SubmitButton
