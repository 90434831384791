import React, { useEffect, useState } from 'react'

import { useFrankentangel } from '../frankentangel'

const CommercialReport = React.forwardRef((props, ref) => {
  const frankentangel = useFrankentangel()
  const { commercial, type } = props
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(false)

  // console.log(commercial)

  const getAccounts = async () => {
    setResults([])
    setLoading(true)

    let cursor = 1
    while (cursor !== null) {
      let query
      if (cursor === 1) query = await frankentangel.runAction('accounts', 'list')
      else query = await frankentangel.runAction('accounts', 'list', { _cursor: cursor })
      setResults(prevQuery => ([...prevQuery, ...query.list]))
      cursor = query._cursor
    }

    setLoading(false)
  }

  useEffect(() => {
    getAccounts()
  }, []) // eslint-disable-line

  let report = []

  const keepOrDont = (accounts, includeAll = false) => {
    return accounts.filter(entry => {
      const account = entry.doc

      // pasted from taa-api/backend/schema/commercial-weeks.js

      if (!account.contract) return false
      if (['Cash', 'Library Rights Only'].includes(account.contract.contractType)) return false

      // special rule for the reports
      if (account.comments.startsWith('TEST ACCOUNT')) return false

      if (commercial.languageTarget === 'English' && account.language === 'Spanish') return false
      if (commercial.languageTarget === 'Spanish' && account.language === 'English') return false

      const formats = (commercial.formats || []).map(format => format.id)
      const regions = (commercial.regions || []).map(region => region.id)
      const markets = (commercial.markets || []).map(market => market.id)
      const states = (commercial.states || '').split(',')
      const zips = (commercial.zips || '').split(',')

      const defaultValue = Boolean(commercial.accountRule)

      if (account.format && formats.includes(account.format.id)) return !defaultValue
      if (account.market && markets.includes(account.market.id)) return !defaultValue
      if (account.region && regions.includes(account.region.id)) return !defaultValue
      if (account.address && states.includes(account.address.state)) return !defaultValue
      if (account.zips && zips.includes(account.address.zip)) return !defaultValue

      return defaultValue
    })
  }
  // if (results) report = commercial.accountRule === 0 ? keepOrDont(results) : keepOrDont(results, true)
  if (results) report = keepOrDont(results)

  const markets = report
    .map(entry => entry.doc.market?.name)
    .filter(a => a) // not null
    .filter((value, index, array) => array.indexOf(value) === index)

  let toRender = []

  if (type === 'call-letters') {
    toRender = report.map((el, idx) => {
      return (
        <React.Fragment key={idx}>
          <div className='report-print-row'>{el.doc.callsign} - {el.doc.market?.name || 'N/A'}</div>
        </React.Fragment>
      )
    })
  } else {
    const byMarket = [...new Set(report.map(acc => acc.doc.market?.name || 'N/A'))].sort().reduce((acc, name) => ({ ...acc, [name]: [] }), {})
    report.forEach(acc => { byMarket[acc.doc.market?.name || 'N/A'].push(<div className='reporting__subkey' key={acc.id}>{acc.doc.callsign}</div>) })
    toRender = Object.keys(byMarket).map((key, idx) => {
      return (
        <React.Fragment key={`${key}-${idx}`}>
          <div className='report-print-row mb-3'>
            <p>{key || 'N/A'}</p>
            {byMarket[key]}
          </div>
        </React.Fragment>
      )
    })
  }

  return (
    <div ref={ref} className='columns is-multiline reporting'>
      <div className='reporting__print-header'>
        <img src='https://cdn.taamusic.com/taa-logo-grey-300.png' alt='TAA Music Logo' className='reporting__print-header__logo' />
        <p className='reporting__print-header__title'>Commercial Scheduling Report by {type === 'call-letters' ? 'Call Letters' : 'Market'}</p>
      </div>
      <div className='column is-two-fifths has-text-weight-bold'>Commercial Name:</div>
      <div className='column is-three-fifths'>{commercial.name}</div>
      <div className='column is-two-fifths has-text-weight-bold'>ISCI:</div>
      <div className='column is-three-fifths'>{commercial.isci}</div>
      <div className='column is-two-fifths has-text-weight-bold'>Commercial Description:</div>
      <div className='column is-three-fifths'>{commercial.description}</div>
      <div className='column is-two-fifths has-text-weight-bold'>Duration:</div>
      <div className='column is-three-fifths'>{commercial.duration}</div>
      <div className='column is-two-fifths has-text-weight-bold'>Ad Agency:</div>
      <div className='column is-three-fifths'>{commercial.agency.name}</div>
      <div className='column is-two-fifths has-text-weight-bold'>Air Week:</div>
      <div className='column is-three-fifths'>{commercial.week}</div>
      <div className='column is-two-fifths has-text-weight-bold'>Air Days:</div>
      <div className='column is-three-fifths'>{!Array.isArray(commercial.days) || commercial.days.length === 0 ? '' : <>{commercial.days.map(comm => <React.Fragment key={comm}>{comm.day + ': ' + comm.comment}<br /></React.Fragment>)}</>}</div>
      <div className='column is-two-fifths has-text-weight-bold'>Language:</div>
      <div className='column is-three-fifths'>{commercial.language}</div>
      <div className='column is-two-fifths has-text-weight-bold'>Special Instructions:</div>
      <div className='column is-three-fifths'>{commercial.instructions}</div>
      <div className='column is-full has-text-weight-bold is-size-5 my-5'>{report.length} Accounts Included{markets.length > 0 ? ` Across ${markets.length} Markets` : ''}</div>
      <div className='column is-full reporting__data'>
        {!loading ? toRender : 'Loading...'}
      </div>
    </div>
  )
}
)

export default CommercialReport
