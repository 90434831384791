import React from 'react'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import MainLayout from '../../containers/MainLayout'
import ButtonLink from '../../components/ButtonLink'
import FormWrapper from '../../components/FormWrapper'
import FromInputField from '../../components/FormInputField'
import FormJoinSelect from '../../components/FormJoinSelect'
import SubmitButton from '../../components/SubmitButton'
import FormErrorsList from '../../components/FormErrorsList'
import FormStatus from '../../components/FormStatus'
import { useFrankentangel } from '../../frankentangel'

const CreateLibrary = props => {
  const frankentangel = useFrankentangel()
  const history = useHistory()

  const submitLibrary = async values => {
    const result = await frankentangel.createDocument('library', values)
    history.push(`/library-manager/libraries/${result.id}`)
  }

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns'>
          <div className='column is-full'>
            <div className='columns'>
              <div className='column is-12'>
                <h1 className='title'>Create Library</h1>
                <ButtonLink target='/library-manager' classes={['is-dark']} icon={['fas', 'arrow-left']} linkText='Back to Library Manager' />
              </div>
            </div>
          </div>
        </div>
        <div className='columns'>
          <div className='column is-half'>
            <FormWrapper
              fields={yup.object({
                library_name: yup.string().required('Required'),
                albums: yup.string()
              })}
              submit={submitLibrary}
            >
              <FromInputField name='library_name' placeholder='Libary Name' label='Library Name' iconLeft={['fas', 'file']} />
              <FormJoinSelect name='albums' placeholder='Albums Connected' label='Albums Connected' table='album' rows={album => `${album.album_name} (${album.library ? `Currently in ${album.library.library_name}` : 'Currently Unlinked'})`} multi />
              <FormErrorsList />
              <SubmitButton buttonText='Save' classes={['has-medium-top-margin']} />
              <FormStatus />
            </FormWrapper>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CreateLibrary
