import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSyncAlt } from '@fortawesome/pro-solid-svg-icons'

import MainLayout from '../../containers/MainLayout'
import ReportTable from '../../components/ReportTable'
import InputField from '../../components/InputField'
import ErrorPage from '../../pages/ErrorPage'
import ButtonLink from '../../components/ButtonLink'

import { useSearchableList } from '../../frankentangel'

const LibraryManager = props => {
  const [searchText, setSearchText] = useState('')

  const [Paginator, result, error, loading] = useSearchableList('library', searchText, 'listWithAlbums', {}, 'withAlbums')

  if (error) {
    return <ErrorPage error={error} />
  }

  const searchResults = result
    ? result.map(lib => lib.doc).map(lib => {
        return [
          lib.library_name,
          lib.albums?.length || 0,
          <ButtonLink target={`/library-manager/libraries/${lib.id}`} classes={['is-dark']} icon={faEdit} linkText='Edit' key={lib.library_name + lib.albums?.length} />
        ]
      })
    : null

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns'>
          <div className='column is-full'>
            <div className='columns'>
              <div className='column is-12'>
                <h1 className='title'>Library Manager</h1>
                <ButtonLink target='/library-manager/libraries/create' classes={['is-dark']} icon={['fas', 'file-plus']} linkText='Add New Library' />
              </div>
            </div>
          </div>
        </div>
        <div className='columns is-multiline'>
          <div className='column is-half'>
            <InputField loading={loading} label='Search Libraries' placeholder='Type here' value={searchText} onChange={ev => setSearchText(ev.target.value)} />
          </div>
        </div>
        <div className='columns'>
          <div className='column is-full'>
            <Paginator spinner={<div className='has-text-centered my-4'><FontAwesomeIcon icon={faSyncAlt} spin size='3x' /></div>}>
              <ReportTable
                headings={['Library', 'Album Count', '']}
                tableData={searchResults}
              />
            </Paginator>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default LibraryManager
