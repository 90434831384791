import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import MainLayout from '../../containers/MainLayout'
import { useFrankentangel } from '../../frankentangel'
import ButtonLink from '../../components/ButtonLink'

const AffidavitsAccount = props => {
  const { id } = useParams()
  const frankentangel = useFrankentangel()
  const [account, setAccount] = useState({})

  const fetchAccount = async () => {
    const fetchedAccount = await frankentangel.viewDocument('accounts', Number(id))
    setAccount(fetchedAccount)
  }

  useEffect(() => {
    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100)
    fetchAccount()
  }, []) // eslint-disable-line

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns is-multiline'>
          <div className='column is-full'>
            <h1 className='title'>Affidavits Weeks for {account.name}</h1>
            <ButtonLink target='/affidavits' icon={['fas', 'arrow-left']} linkText='Back to Affidavits' />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default AffidavitsAccount
