import { EventEmitter } from 'events'
import React, { useState, useEffect, useRef } from 'react'
import LRU from 'lru-cache'
import zaplink from 'zaplink'
import unwrap from 'async-unwrap'

import config from '../config'

// const client = new zaplink.Client('wss://api.taamusic.com/frankentangel', { concurrency: 2 })
// const client = new zaplink.Client('ws://localhost:3011/frankentangel', { concurrency: 2 })
const client = new zaplink.Client(config.entangelPath, { concurrency: 3, timeout: 2500, retryCount: 15 })

if (window.location.hostname === 'localhost' || (window.localStorage && window.localStorage.getItem('entangel-debug'))) window.zaplink = client
//                                                                    ^ btw, f@#k ios safari for this one

const emitter = new EventEmitter()
emitter.setMaxListeners(Infinity)

setInterval(() => client.send('session-keepalive').catch((err) => console.log('session-keepalive failed', err.message || err)), 1000)
client.addHandler('session-update', ({ session, field }) => {
  emitter.emit('session', session, field)
  emitter.emit(`session:${field}`, session)
})

setInterval(async () => {
  try {
    const { token } = await client.send('get-gate-token')
    const tld = window.location.hostname.split('.').slice(-2).join('.')
    document.cookie = `entangel-gate-token=${token};domain=${tld};path=/;samesite=strict`
  } catch (err) {
    console.log('gate token update failed', err.message || err)
  }
}, 1000)

const createActionId = () => `action-${Array.from(window.crypto.getRandomValues(new Uint8Array(4))).map(c => c.toString(16).padStart(2, '0')).join('')}`

const createDocument = async (table, params) => client.send('create-document', { table, params, actionId: createActionId() })
const updateDocument = async (table, id, params) => client.send('update-document', { table, id, params, actionId: createActionId() })
const deleteDocument = async (table, id) => client.send('delete-document', { table, id, actionId: createActionId() })
const runAction = async (table, action, params = {}, includeFull = false) => {
  const fullResult = await client.send('run-action', { table, action, params, actionId: createActionId() })
  const { result } = fullResult
  return includeFull ? fullResult : result
}
const search = async (text, tables = [], cursor = null, viewConfig = {}, includeFull = false, searchId = '_default') => {
  const fullResult = await client.send('search', { text, tables, cursor, viewConfig, searchId }, { timeout: 2500, retryCount: 0 })
  const { result } = fullResult
  return includeFull ? fullResult : result
}
const viewDocument = async (table, id, viewName = '_default', includeFull = false) => {
  const fullResult = await client.send('view', { table, id: Number(id), viewName })
  const { document } = fullResult
  return includeFull ? fullResult : document
}
const viewSession = async () => client.send('session-view')
const viewMulti = async (table, keys, viewName = '_default', includeFull = false) => {
  const fullResult = await client.send('multi', { table, keys, viewName })
  const { result } = fullResult
  return includeFull ? fullResult : result
}

const frankentangel = {
  createDocument,
  updateDocument,
  deleteDocument,
  runAction,
  search,
  viewDocument,
  viewSession,
  viewMulti
}
if (window.location.hostname === 'localhost' || (window.localStorage && window.localStorage.getItem('entangel-debug'))) window.frankentangel = frankentangel

export const useFrankentangel = () => frankentangel

const subscribedDocs = new Map()

setInterval(() => client.send('subscribed-docs', Array.from(subscribedDocs.keys())).catch(err => console.log('subscribed-docs failed', err.message || err)), 1000)
client.addHandler('document-ping', ({ table, id }) => emitter.emit('document-ping', `${table}:${id}`))

const messageOf = error => error ? error.message || error : 'unknown error'

const subscribeToDoc = documentId => subscribedDocs.set(documentId, (subscribedDocs.get(documentId) || 0) + 1)
const unsubscribeFromDoc = documentId => {
  subscribedDocs.set(documentId, (subscribedDocs.get(documentId) || 0) - 1)
  if (subscribedDocs.get(documentId) <= 0) subscribedDocs.delete(documentId)
}

const deduplicateFilter = (value, index, array) => array.indexOf(value) === index

// session handler
const globalSession = {
  emitter: new EventEmitter(),
  state: { loading: true, error: null, session: null },
  setState: (state) => {
    globalSession.state = state
    globalSession.emitter.emit('session', state)
  },
  loadSession: async () => {
    globalSession.setState({ loading: true, error: null, session: null })

    const [error, doc] = await viewSession()[unwrap]

    if (error) return globalSession.setState({ loading: false, error: error ? error.message || error : 'unknown error', session: null })
    else return globalSession.setState({ loading: false, error: null, session: doc.session })
  }
}
globalSession.loadSession()
emitter.on('session', (session) => globalSession.setState({ loading: false, error: null, session }))

export const useSession = () => {
  const [sessionState, setSessionState] = useState(globalSession.state)

  useEffect(() => {
    setSessionState(globalSession.state)

    const updateHandler = (session) => {
      setSessionState(session)
    }
    globalSession.emitter.on('session', updateHandler)

    return () => {
      globalSession.emitter.removeListener('session', updateHandler)
    }
  }, [])

  return [sessionState.session, sessionState.error, sessionState.loading]
}

const documentCache = new LRU({ max: 2000 })

const cacheDocumentList = (list) => {
  if (!list || !list.length) return

  list.forEach(doc => {
    const { table, id, view } = doc
    const docName = `${table}:${id}:${view}`

    if (!table || !id || !view) return

    documentCache.set(docName, doc)
  })
}

const deduplicateDocumentList = (list) => {
  const docNames = list.map(doc => {
    const { table, id, view } = doc
    const docName = `${table}:${id}:${view}`

    return docName
  })

  return list.filter((doc, index) => {
    const { table, id, view } = doc
    if (!table || !id || !view) return true

    const currentName = docNames[index]
    const firstOccurrence = docNames.indexOf(currentName)

    return firstOccurrence === index
  })
}

export const useDocument = (table, id, viewName = '_default') => {
  const docName = `${table}:${id}:${viewName}`

  const [docState, setDocState] = useState({ loading: true, error: null, document: null })
  const [sessionTouched, setSessionTouched] = useState('')
  const [lastDoc, setLastDoc] = useState('')
  const [docsTouched, setDocsTouched] = useState('')

  const [magic] = useState({
    firstRender: true
  })

  useEffect(() => {
    const loadDoc = async (firstLoad = false) => {
      if (docName !== lastDoc) {
        setLastDoc(docName)
        setDocState({ loading: true, error: null, document: null })
      } else {
        if (firstLoad) return // no need to reload a doc
      }

      const [error, doc] = await viewDocument(table, id, viewName, true)[unwrap]
      if (error) return setDocState({ loading: false, error: error ? error.message || error : 'unknown error', document: null })

      documentCache.set(docName, doc)
      setDoc(doc)
    }
    loadDoc(true)

    const setDoc = (doc) => {
      // set up state to match the document
      const { sessionTouched, documentsTouched } = doc
      const document = doc.document || doc.doc
      setDocState({ loading: false, error: null, document })
      setSessionTouched(sessionTouched.sort().join(':'))

      // update document subscriptions
      const docsActuallyTouched = docsTouched.split(';')
      const newDocs = documentsTouched.filter(documentId => !docsActuallyTouched.includes(documentId))
      const oldDocs = docsActuallyTouched.filter(documentId => !documentsTouched.includes(documentId))

      newDocs.forEach(documentId => {
        subscribedDocs.set(documentId, (subscribedDocs.get(documentId) || 0) + 1)
      })

      oldDocs.forEach(documentId => {
        subscribedDocs.set(documentId, (subscribedDocs.get(documentId) || 0) - 1)
        if (subscribedDocs.get(documentId) <= 0) subscribedDocs.delete(documentId)
      })

      setDocsTouched(documentsTouched.join(';'))
    }

    if (magic.firstRender) {
      magic.firstRender = false

      const doc = documentCache.get(docName)
      if (doc) setDoc(doc)
    }

    // subscribe to session updates
    sessionTouched.split(':').map(field => emitter.on(`session:${field}`, loadDoc))

    // subscribe to pings
    const pingHandler = (documentId) => {
      if (docsTouched.split(';').includes(documentId)) loadDoc()
    }
    emitter.on('document-ping', pingHandler)

    return () => {
      // remove listeners from session updates and pings
      sessionTouched.split(':').map(field => emitter.removeListener(`session:${field}`, loadDoc))
      emitter.removeListener('document-ping', pingHandler)

      // clean up document updates
      docsTouched.split(';').forEach(documentId => {
        subscribedDocs.set(documentId, (subscribedDocs.get(documentId) || 0) - 1)
        if (subscribedDocs.get(documentId) <= 0) subscribedDocs.delete(documentId)
      })
    }
  }, [table, id, viewName, sessionTouched, docName, lastDoc, docsTouched, magic])

  return [docState.document, docState.error, docState.loading]
}

export const useAction = (table, action, params = {}) => {
  const [actionState, setActionState] = useState({ loading: true, error: null, result: null })
  const [sessionTouched, setSessionTouched] = useState('')

  const paramsJson = JSON.stringify(params)

  useEffect(() => {
    // stores a list of all documents involved with the current action
    let docsTouched = []
    let aborted = false

    const loadDoc = async () => {
      const params = JSON.parse(paramsJson)
      const [error, doc] = await runAction(table, action, params, true)[unwrap]

      if (aborted) return
      if (error) return setActionState({ loading: false, error: error ? error.message || error : 'unknown error', result: null })

      // set up state to match the result
      const { result, sessionTouched, documentsTouched } = doc
      setActionState({ loading: false, error: null, result })
      setSessionTouched(sessionTouched.sort().join(':'))

      // update document subscriptions
      const newDocs = documentsTouched.filter(documentId => !docsTouched.includes(documentId))
      const oldDocs = docsTouched.filter(documentId => !documentsTouched.includes(documentId))

      newDocs.forEach(documentId => {
        subscribedDocs.set(documentId, (subscribedDocs.get(documentId) || 0) + 1)
      })

      oldDocs.forEach(documentId => {
        subscribedDocs.set(documentId, (subscribedDocs.get(documentId) || 0) - 1)
        if (subscribedDocs.get(documentId) <= 0) subscribedDocs.delete(documentId)
      })

      docsTouched = documentsTouched
    }
    loadDoc()

    // subscribe to session updates
    sessionTouched.split(':').map(field => emitter.on(`session:${field}`, loadDoc))

    // subscribe to pings
    const pingHandler = (documentId) => {
      if (docsTouched.includes(documentId)) loadDoc()
    }
    emitter.on('document-ping', pingHandler)

    return () => {
      aborted = true

      // remove listeners from session updates and pings
      sessionTouched.split(':').map(field => emitter.removeListener(`session:${field}`, loadDoc))
      emitter.removeListener('document-ping', pingHandler)

      // clean up document updates
      docsTouched.forEach(documentId => {
        subscribedDocs.set(documentId, (subscribedDocs.get(documentId) || 0) - 1)
        if (subscribedDocs.get(documentId) <= 0) subscribedDocs.delete(documentId)
      })
    }
  }, [table, action, paramsJson, sessionTouched])

  return [actionState.result, actionState.error, actionState.loading]
}

const SearchPaginator = (magic) => {
  const Paginator = (props) => {
    const { children, spinner, ...remainingProps } = props
    const { listState, setListState, text, tablesJson, viewConfigJson } = magic
    const Spinner = spinner || <div>Loading...</div>

    const ref = useRef(null)
    useEffect(() => {
      // if it's loading or finished, don't even start the loop
      let looping = !listState.loading && listState.cursor != null

      const getParentList = node => node.parentNode != null ? [node, ...getParentList(node.parentNode)] : []
      let parentListCache = null
      const getCachedParentList = () => {
        if (!ref.current) return null

        if (!parentListCache) parentListCache = getParentList(ref.current)
        return parentListCache
      }

      const loop = () => {
        if (!looping) return false
        // if (ref.current) looping = false // for debug purposes

        window.requestAnimationFrame(loop)
        if (!ref.current) return

        const scrollContainers = getCachedParentList().filter(node => window.getComputedStyle(node).overflowY === 'scroll')

        const selfRect = ref.current.getBoundingClientRect()
        const inhibitors = scrollContainers.filter(container => {
          if (container.nodeName === 'HTML') return selfRect.bottom > window.scrollY + window.innerHeight * 2

          const containerRect = container.getBoundingClientRect()
          if (containerRect.height === 0) return true
          return selfRect.bottom > containerRect.bottom + containerRect.height
        })

        const loadNext = async () => {
          setListState(state => ({ ...state, loading: true }))

          const tables = JSON.parse(tablesJson)
          const viewConfig = JSON.parse(viewConfigJson)
          // const [error, doc] = await runAction(table, action, { ...params, _cursor: listState.cursor }, true)[unwrap]
          const [error, doc] = await search(text, tables, listState.cursor, viewConfig, true)[unwrap]

          if (error === 'search debounced') return

          if (error) {
            return setListState(state => ({
              ...state,
              loading: false,
              error: messageOf(error)
            }))
          }

          cacheDocumentList(doc.result.list)

          setListState(state => ({
            ...state,
            loading: false,
            result: deduplicateDocumentList([...state.result, ...doc.result.list]),
            documentsTouched: [...state.documentsTouched, ...doc.documentsTouched].filter(deduplicateFilter),
            sessionTouched: [...state.sessionTouched, ...doc.sessionTouched].filter(deduplicateFilter),
            cursor: doc.result._cursor
          }))
        }

        if (!inhibitors.length) loadNext()
      }
      loop()

      return () => {
        looping = false
      }
    }, [tablesJson, viewConfigJson, text, listState.loading, listState.cursor, setListState])

    return (
      <div ref={ref} {...remainingProps}>
        {children}
        {listState.loading ? Spinner : undefined}
      </div>
    )
  }

  return Paginator
}

export const useSearch = (text = '', tables = [], viewConfig = {}) => {
  const tablesJson = JSON.stringify(tables)
  const argsJson = JSON.stringify([text, tables, viewConfig])
  const viewConfigJson = JSON.stringify(viewConfig)

  const [listState, setListState] = useState({
    loading: true,
    error: null,
    result: [],
    cursor: null,
    sessionTouched: [],
    documentsTouched: [],
    lastArgs: '[]',
    loadNextPage: false,
    searchId: Math.random().toString(16).slice(2, 10)
  })

  const [magic] = useState({})
  magic.listState = listState
  magic.setListState = setListState
  magic.text = text
  magic.tablesJson = tablesJson
  magic.viewConfigJson = viewConfigJson

  if (!magic.Paginator) magic.Paginator = SearchPaginator(magic)
  const Paginator = magic.Paginator

  useEffect(() => {
    let aborted = false

    const initialLoad = async () => {
      const tables = JSON.parse(tablesJson)
      const viewConfig = JSON.parse(viewConfigJson)
      const [error, doc] = await search(text, tables, null, viewConfig, true)[unwrap]

      if (aborted) return
      if (error) {
        return setListState(state => ({
          ...state,
          loading: false,
          result: [],
          error: messageOf(error),
          lastArgs: argsJson
        }))
      }

      cacheDocumentList(doc.result.list)

      setListState(state => ({
        ...state,
        loading: false,
        result: doc.result.list || [],
        cursor: doc.result._cursor,
        error: doc.result.error || null,
        documentsTouched: doc.documentsTouched,
        sessionTouched: doc.sessionTouched,
        lastArgs: argsJson
      }))
    }

    if (argsJson !== listState.lastArgs) initialLoad()

    const updateDocuments = async (docList) => {
      const newDocs = await Promise.all(docList.map(async doc => {
        const { table, id, view } = doc

        const newDoc = await frankentangel.viewDocument(table, id, view, true)

        return {
          table,
          id,
          view,
          doc: newDoc.document,
          sessionTouched: newDoc.sessionTouched,
          documentsTouched: newDoc.documentsTouched
        }
      }))

      const updatedDocs = new Map()
      newDocs.forEach(doc => updatedDocs.set(`${doc.table}:${doc.id}`, doc))

      if (aborted) return

      setListState(state => {
        const result = state.result.map(doc => {
          const docId = `${doc.table}:${doc.id}`
          return updatedDocs.has(docId) ? updatedDocs.get(docId) : doc
        }).filter(doc => {
          // remove unauthorized docs
          if (!doc.doc || (doc.doc.id === doc.id && doc.doc.error === 'unauthorized')) return false

          return true
        })

        // if all docs suddenly disappeared we might have run into an error, so trigger a reload and fetch the message
        const lastArgs = (!result.length && state.result.length) ? '[]' : state.lastArgs

        return { ...state, result, lastArgs }
      })
    }

    // subscribe to pings
    const pingHandler = (documentId) => {
      if (!listState.documentsTouched.includes(documentId)) return
      if (!listState.result.length) return initialLoad()

      updateDocuments(listState.result.filter(doc => doc.documentsTouched.includes(documentId)))
    }
    emitter.on('document-ping', pingHandler)

    // subscribe to sessions
    const sessionHandler = (session, field) => {
      if (!listState.sessionTouched.includes(field)) return
      if (!listState.result.length) return initialLoad()

      updateDocuments(listState.result.filter(doc => doc.sessionTouched.includes(field)))
    }
    emitter.on('session', sessionHandler)

    // subscribe to docs
    listState.documentsTouched.forEach(subscribeToDoc)

    return () => {
      aborted = true

      emitter.removeListener('document-ping', pingHandler)
      emitter.removeListener('session', sessionHandler)

      listState.documentsTouched.forEach(unsubscribeFromDoc)
    }
  }, [listState, argsJson, tablesJson, viewConfigJson, text])

  return [Paginator, listState.result, listState.error, listState.loading]
}

const ListPaginator = (magic) => {
  const Paginator = (props) => {
    const { children, spinner, ...remainingProps } = props
    const { table, action, listState, setListState, paramsJson } = magic
    const Spinner = spinner || <div>Loading...</div>

    const ref = useRef(null)
    useEffect(() => {
      // if it's loading or finished, don't even start the loop
      let looping = !listState.loading && listState.cursor != null

      const getParentList = node => node.parentNode != null ? [node, ...getParentList(node.parentNode)] : []
      let parentListCache = null
      const getCachedParentList = () => {
        if (!ref.current) return null

        if (!parentListCache) parentListCache = getParentList(ref.current)
        return parentListCache
      }

      const loop = () => {
        if (!looping) return false
        // if (ref.current) looping = false // for debug purposes

        window.requestAnimationFrame(loop)
        if (!ref.current) return

        const scrollContainers = getCachedParentList().filter(node => window.getComputedStyle(node).overflowY === 'scroll')

        const selfRect = ref.current.getBoundingClientRect()
        const inhibitors = scrollContainers.filter(container => {
          if (container.nodeName === 'HTML') return selfRect.bottom > window.scrollY + window.innerHeight * 2

          const containerRect = container.getBoundingClientRect()
          if (containerRect.height === 0) return true
          return selfRect.bottom > containerRect.bottom + containerRect.height
        })

        const loadNext = async () => {
          setListState(state => ({ ...state, loading: true }))

          const params = JSON.parse(paramsJson)
          const [error, doc] = await runAction(table, action, { ...params, _cursor: listState.cursor }, true)[unwrap]

          if (error) {
            return setListState(state => ({
              ...state,
              loading: false,
              error: messageOf(error)
            }))
          }

          cacheDocumentList(doc.result.list)

          setListState(state => ({
            ...state,
            loading: false,
            result: deduplicateDocumentList([...state.result, ...doc.result.list]),
            documentsTouched: [...state.documentsTouched, ...doc.documentsTouched].filter(deduplicateFilter),
            sessionTouched: [...state.sessionTouched, ...doc.sessionTouched].filter(deduplicateFilter),
            cursor: doc.result._cursor
          }))
        }

        if (!inhibitors.length) loadNext()
      }
      loop()

      return () => {
        looping = false
      }
    }, [table, action, paramsJson, listState.loading, listState.cursor, setListState])

    return (
      <div ref={ref} {...remainingProps}>
        {children}
        {listState.loading ? Spinner : undefined}
      </div>
    )
  }

  return Paginator
}

export const useList = (table, action, params = {}) => {
  const paramsJson = JSON.stringify(params)
  const argsJson = JSON.stringify([table, action, paramsJson])

  const [listState, setListState] = useState({
    loading: true,
    error: null,
    result: [],
    cursor: null,
    sessionTouched: [],
    documentsTouched: [],
    lastArgs: '[]',
    loadNextPage: false
  })

  const [magic] = useState({})
  magic.table = table
  magic.action = action
  magic.listState = listState
  magic.setListState = setListState
  magic.paramsJson = paramsJson

  if (!magic.Paginator) magic.Paginator = ListPaginator(magic)
  const Paginator = magic.Paginator

  useEffect(() => {
    let aborted = false

    const initialLoad = async () => {
      const params = JSON.parse(paramsJson)
      const [error, doc] = await runAction(table, action, params, true)[unwrap]

      if (aborted) return
      if (error) {
        return setListState(state => ({
          ...state,
          loading: false,
          result: [],
          error: messageOf(error),
          lastArgs: argsJson
        }))
      }

      cacheDocumentList(doc.result.list)

      setListState(state => ({
        ...state,
        loading: false,
        result: doc.result.list || [],
        cursor: doc.result._cursor,
        error: doc.result.error || null,
        documentsTouched: doc.documentsTouched,
        sessionTouched: doc.sessionTouched,
        lastArgs: argsJson
      }))
    }

    if (argsJson !== listState.lastArgs) initialLoad()

    const updateDocuments = async (docList) => {
      const newDocs = await Promise.all(docList.map(async doc => {
        const { table, id, view } = doc

        const newDoc = await frankentangel.viewDocument(table, id, view, true)

        return {
          table,
          id,
          view,
          doc: newDoc.document,
          sessionTouched: newDoc.sessionTouched,
          documentsTouched: newDoc.documentsTouched
        }
      }))

      const updatedDocs = new Map()
      newDocs.forEach(doc => updatedDocs.set(`${doc.table}:${doc.id}`, doc))

      if (aborted) return

      setListState(state => {
        const result = state.result.map(doc => {
          const docId = `${doc.table}:${doc.id}`
          return updatedDocs.has(docId) ? updatedDocs.get(docId) : doc
        }).filter(doc => {
          // remove unauthorized docs
          if (!doc.doc || (doc.doc.id === doc.id && doc.doc.error === 'unauthorized')) return false

          return true
        })

        // if all docs suddenly disappeared we might have run into an error, so trigger a reload and fetch the message
        const lastArgs = (!result.length && state.result.length) ? '[]' : state.lastArgs

        return { ...state, result, lastArgs }
      })
    }

    // subscribe to pings
    const pingHandler = (documentId) => {
      if (!listState.documentsTouched.includes(documentId)) return
      if (!listState.result.length) return initialLoad()

      updateDocuments(listState.result.filter(doc => doc.documentsTouched.includes(documentId)))
    }
    emitter.on('document-ping', pingHandler)

    // subscribe to sessions
    const sessionHandler = (session, field) => {
      if (!listState.sessionTouched.includes(field)) return
      if (!listState.result.length) return initialLoad()

      updateDocuments(listState.result.filter(doc => doc.sessionTouched.includes(field)))
    }
    emitter.on('session', sessionHandler)

    // subscribe to docs
    listState.documentsTouched.forEach(subscribeToDoc)

    return () => {
      aborted = true

      emitter.removeListener('document-ping', pingHandler)
      emitter.removeListener('session', sessionHandler)

      listState.documentsTouched.forEach(unsubscribeFromDoc)
    }
  }, [listState, argsJson, table, action, paramsJson])

  return [Paginator, listState.result, listState.error, listState.loading]
}

export const useSearchableList = (table, text, action = 'list', params = {}, viewName = '_default') => {
  const searchResults = useSearch(text, [table], { [table]: viewName })
  const listResults = useList(table, action, params)

  return text ? searchResults : listResults
}

export const useMulti = (table, keys = [], viewName = '_default') => {
  const keysJson = JSON.stringify(keys)
  const argsJson = JSON.stringify([table, keysJson])

  const [listState, setListState] = useState({
    loading: true,
    error: null,
    result: [],
    // cursor: null,
    sessionTouched: [],
    documentsTouched: [],
    lastArgs: '[]',
    loadNextPage: false
  })

  const [magic] = useState({})
  magic.table = table
  magic.listState = listState
  magic.setListState = setListState
  magic.paramsJson = keysJson

  useEffect(() => {
    let aborted = false

    const initialLoad = async () => {
      const keys = JSON.parse(keysJson)
      const [error, doc] = await viewMulti(table, keys, viewName, true)[unwrap]

      if (aborted) return
      if (error) {
        return setListState(state => ({
          ...state,
          loading: false,
          result: [],
          error: messageOf(error),
          lastArgs: argsJson
        }))
      }

      cacheDocumentList(doc.result)

      setListState(state => ({
        ...state,
        loading: false,
        result: doc.result || [],
        // cursor: doc.result._cursor,
        error: doc.result.error || null,
        documentsTouched: doc.documentsTouched,
        sessionTouched: doc.sessionTouched,
        lastArgs: argsJson
      }))
    }

    if (argsJson !== listState.lastArgs) initialLoad()

    const updateDocuments = async (docList) => {
      const newDocs = await Promise.all(docList.map(async doc => {
        const { table, id, view } = doc

        const newDoc = await frankentangel.viewDocument(table, id, view, true)

        return {
          table,
          id,
          view,
          doc: newDoc.document,
          sessionTouched: newDoc.sessionTouched,
          documentsTouched: newDoc.documentsTouched
        }
      }))

      const updatedDocs = new Map()
      newDocs.forEach(doc => updatedDocs.set(`${doc.table}:${doc.id}`, doc))

      if (aborted) return

      setListState(state => {
        const result = state.result.map(doc => {
          const docId = `${doc.table}:${doc.id}`
          return updatedDocs.has(docId) ? updatedDocs.get(docId) : doc
        }).filter(doc => {
          // remove unauthorized docs
          if (!doc.doc || (doc.doc.id === doc.id && doc.doc.error === 'unauthorized')) return false

          return true
        })

        // if all docs suddenly disappeared we might have run into an error, so trigger a reload and fetch the message
        const lastArgs = (!result.length && state.result.length) ? '[]' : state.lastArgs

        return { ...state, result, lastArgs }
      })
    }

    // subscribe to pings
    const pingHandler = (documentId) => {
      if (!listState.documentsTouched.includes(documentId)) return
      if (!listState.result.length) return initialLoad()

      updateDocuments(listState.result.filter(doc => doc.documentsTouched.includes(documentId)))
    }
    emitter.on('document-ping', pingHandler)

    // subscribe to sessions
    const sessionHandler = (session, field) => {
      if (!listState.sessionTouched.includes(field)) return
      if (!listState.result.length) return initialLoad()

      updateDocuments(listState.result.filter(doc => doc.sessionTouched.includes(field)))
    }
    emitter.on('session', sessionHandler)

    // subscribe to docs
    listState.documentsTouched.forEach(subscribeToDoc)

    return () => {
      aborted = true

      emitter.removeListener('document-ping', pingHandler)
      emitter.removeListener('session', sessionHandler)

      listState.documentsTouched.forEach(unsubscribeFromDoc)
    }
  }, [listState, argsJson, table, viewName, keysJson])

  return [listState.result, listState.error, listState.loading]
}

const functionalities = {
  useFrankentangel,
  useDocument,
  useAction,
  useSession,
  useSearch,
  useList,
  useSearchableList,
  useMulti
}

export default functionalities
