import React from 'react'

const loaderAnimation = props => (
  <div className='loading__wrapper'>
    <div className='loading__inner-wrapper'>
      <div className='loading__inner-breather' />
      <div className='loading__outer-spinner' />
    </div>
  </div>
)

export default loaderAnimation
