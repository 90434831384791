import React from 'react'
import classNames from 'classnames'

import useForm from './useForm'

const FormTextarea = props => {
  const { id, name, onBlur, onChange, placeholder, disabled, required, label, classes, fixedSize, rows, autoFocus } = props
  const [fieldValue, fieldState, handleFieldChange, handleFieldBlur] = useForm(name)

  const handleOnBlur = ev => {
    handleFieldBlur(name, ev.target.value)
    if (onBlur) onBlur(ev)
  }
  const handleOnChange = ev => {
    handleFieldChange(name, ev.target.value)
    if (onChange) onChange(ev)
  }

  const textareaProperties = {
    disabled,
    required,
    className: classNames('textarea', { 'is-danger': fieldState.state === 'invalid' }, { 'has-fixed-size': fixedSize }, classes),
    placeholder,
    id,
    name,
    rows,
    autoFocus,
    value: fieldValue,
    onChange: ev => handleOnChange(ev),
    onBlur: ev => handleOnBlur(ev)
  }

  let textareaMarkup = (
    <>
      <textarea {...textareaProperties} />
      {fieldState.state === 'invalid' && (<p className='help is-danger'>{fieldState.errorMsg}</p>)}
    </>
  )

  if (label) {
    textareaMarkup = (
      <div className='field'>
        <label className='label'>{label}</label>
        <div className='control'>
          <textarea {...textareaProperties} />
        </div>
        {fieldState.state === 'invalid' && (<p className='help is-danger'>{fieldState.errorMsg}</p>)}
      </div>
    )
  }

  return textareaMarkup
}

export default FormTextarea
