import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPaperPlane } from '@fortawesome/pro-duotone-svg-icons'
import { faCheck, faTimes, faAngleLeft, faAngleRight, faPlus, faMinus, faSignIn, faSignOut, faRedo, faUserEdit, faUserTimes, faToggleOn, faToggleOff, faLockAlt, faSave, faTimesCircle, faSyncAlt, faSearch, faAt, faUser, faArrowLeft, faUserPlus, faFileSignature, faFileTimes, faFilePlus, faFile, faIdCardAlt, faTag, faDollarSign, faArchway, faCity, faRouteInterstate, faMailbox, faFlag, faPhone, faFax, faInfoSquare, faAddressBook, faLink, faFileInvoice, faIdCard, faAlbum, faAngleDown, faUserCircle, faGlobeAmericas, faMegaphone, faExclamationCircle, faClipboardList, faCompactDisc, faTrashAlt, faClone, faFileChartLine, faFileChartPie, faBroadcastTower, faPrint, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { useSession } from './frankentangel'
import phoneValidation from './utils/phone-validation'

// Pages
import Home from './pages/Home'
import AdminUsers from './pages/AdminUsers/AdminUsers'
import AdminUser from './pages/AdminUsers/AdminUser'
import CreateAdminUser from './pages/AdminUsers/CreateAdminUser'
import Contracts from './pages/Contracts/Contracts'
import Contract from './pages/Contracts/Contract'
import CreateContract from './pages/Contracts/CreateContract'
import Accounts from './pages/Accounts/Accounts'
import Account from './pages/Accounts/Account'
import CreateAccount from './pages/Accounts/CreateAccount'
import Users from './pages/Users/Users'
import User from './pages/Users/User'
import CreateUser from './pages/Users/CreateUser'
import Commercials from './pages/Commercials/Commercials'
import Commercial from './pages/Commercials/Commercial'
import CreateCommercial from './pages/Commercials/CreateCommercial'
import CommercialReport from './pages/Commercials/CommercialReport'
import Affidavits from './pages/Affidavits/Affidavits'
import AffidavitsAccount from './pages/Affidavits/AffidavitsAccount'
import AffidavitsAccountWeek from './pages/Affidavits/AffidavitsAccountWeek'
import Error404 from './pages/Error404'
import Login from './pages/Login'
import LoadingScreen from './pages/LoadingScreen'
import ErrorPage from './pages/ErrorPage'
import Search from './pages/Search'
import RedirectPage from './pages/RedirectPage'
import ReindexPage from './pages/Debug/Reindex'
import CommercialDownloads from './pages/Reports/CommercialDownloads'
import Searches from './pages/Reports/Searches'
import SubOutLastWeek from './pages/Reports/SubOutLastWeek'
import OverdueAffidavits from './pages/Reports/OverdueAffidavits'
import TotalTracksDownloaded from './pages/Reports/TotalTracksDownloaded'
import EmailBounced from './pages/Reports/EmailBounced'
import OrphanedUsers from './pages/Reports/OrphanedUsers'
import OrphanedAccounts from './pages/Reports/OrphanedAccounts'
import OrphanedContracts from './pages/Reports/OrphanedContracts'
import LibraryManager from './pages/Music/LibraryManager'
import Library from './pages/Music/Library'
import CreateLibrary from './pages/Music/CreateLibrary'
import AlbumManager from './pages/Music/AlbumManager'
import TrackManager from './pages/Music/TrackManager'
import CreateTrack from './pages/Music/CreateTrack'
import Track from './pages/Music/Track'
import EditMix from './pages/Music/EditMix'
import Album from './pages/Music/Album'
import CreateAlbum from './pages/Music/CreateAlbum'

library.add(faCheck, faTimes, faAngleLeft, faAngleRight, faPaperPlane, faPlus, faMinus, faSignIn, faSignOut, faRedo, faUserEdit, faUserTimes, faToggleOn, faToggleOff, faLockAlt, faSave, faTimesCircle, faSyncAlt, faSearch, faAt, faUser, faArrowLeft, faUserPlus, faFileSignature, faFileTimes, faFilePlus, faFile, faIdCardAlt, faTag, faDollarSign, faArchway, faCity, faRouteInterstate, faMailbox, faFlag, faPhone, faFax, faInfoSquare, faAddressBook, faLink, faFileInvoice, faIdCard, faAlbum, faAngleDown, faUserCircle, faCompactDisc, faGlobeAmericas, faMegaphone, faExclamationCircle, faClipboardList, faTrashAlt, faClone, faFileChartLine, faFileChartPie, faBroadcastTower, faPrint, faTrash)

phoneValidation()

const App = () => {
  const [currentSession, error, loading] = useSession()
  // TODO Loading ==> loading screen
  // TODO if Error, handle it

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorPage error={error} />
  }

  if (!currentSession.user || !currentSession.adminLevel) {
    return <Login />
  }

  if (!currentSession.user || !currentSession.adminLevel) {
    return <Login />
  }

  return (
    <Switch>
      <Route path='/' exact render={props => <Home {...props} />} />
      <Route path='/admin-users' exact render={props => <AdminUsers {...props} />} />
      <Route path='/admin-users/create' exact render={props => <CreateAdminUser {...props} />} />
      <Route path='/admin-users/:id' exact render={props => <AdminUser {...props} />} />
      <Route path='/commercials' exact render={props => <Commercials {...props} />} />
      <Route path='/commercials/create' exact render={props => <CreateCommercial {...props} />} />
      <Route path='/commercials/:id/report/:type' exact render={props => <CommercialReport {...props} />} />
      <Route path='/commercials/:id' exact render={props => <Commercial {...props} />} />
      <Route path='/contracts' exact render={props => <Contracts {...props} />} />
      <Route path='/contracts/create' exact render={props => <CreateContract {...props} />} />
      <Route path='/contracts/:id' exact render={props => <Contract {...props} />} />
      <Route path='/accounts' exact render={props => <Accounts {...props} />} />
      <Route path='/accounts/create' exact render={props => <CreateAccount {...props} />} />
      <Route path='/accounts/:id' exact render={props => <Account {...props} />} />
      <Route path='/affidavits' exact render={props => <Affidavits {...props} />} />
      <Route path='/affidavits/account/:id/weeks' exact render={props => <AffidavitsAccount {...props} />} />
      <Route path='/affidavits/account/:id/weeks/:week' exact render={props => <AffidavitsAccountWeek {...props} />} />
      <Route path='/users' exact render={props => <Users {...props} />} />
      <Route path='/users/create' exact render={props => <CreateUser {...props} />} />
      <Route path='/users/:id' exact render={props => <User {...props} />} />
      <Route path='/search' exact render={props => <Search {...props} />} />
      <Route path='/reports/commercial-downloads' exact render={props => <CommercialDownloads {...props} />} />
      <Route path='/reports/searches' exact render={props => <Searches {...props} />} />
      <Route path='/reports/sub-out-last-week' exact render={props => <SubOutLastWeek {...props} />} />
      <Route path='/reports/overdue-affidavits' exact render={props => <OverdueAffidavits {...props} />} />
      <Route path='/reports/total-tracks-downloaded' exact render={props => <TotalTracksDownloaded {...props} />} />
      <Route path='/reports/bounced-emails' exact render={props => <EmailBounced {...props} />} />
      <Route path='/reports/unlinked-users' exact render={props => <OrphanedUsers {...props} />} />
      <Route path='/reports/unlinked-accounts' exact render={props => <OrphanedAccounts {...props} />} />
      <Route path='/reports/unlinked-contracts' exact render={props => <OrphanedContracts {...props} />} />
      <Route path='/library-manager' exact render={props => <LibraryManager {...props} />} />
      <Route path='/library-manager/libraries/create' exact render={props => <CreateLibrary {...props} />} />
      <Route path='/library-manager/libraries/:id' exact render={props => <Library {...props} />} />
      <Route path='/album-manager' exact render={props => <AlbumManager {...props} />} />
      <Route path='/album-manager/albums/create' exact render={props => <CreateAlbum {...props} />} />
      <Route path='/album-manager/albums/:id' exact render={props => <Album {...props} />} />
      <Route path='/track-manager' exact render={props => <TrackManager {...props} />} />
      <Route path='/track-manager/tracks/create' exact render={props => <CreateTrack {...props} />} />
      <Route path='/track-manager/tracks/:id/mix' exact render={props => <EditMix {...props} />} />
      <Route path='/track-manager/tracks/:id' exact render={props => <Track {...props} />} />

      {/* redirects */}
      <Route path='/redirect/kibana' exact render={props => <RedirectPage url='https://kibana.taamusic.com' />} />
      <Route path='/redirect/database' exact render={props => <RedirectPage url='https://db.taamusic.com' />} />
      <Route path='/redirect/strapi' exact render={props => <RedirectPage url='https://content.taamusic.com' />} />

      {/* debug */}
      <Route path='/debug/reindex' exact render={props => <ReindexPage {...props} />} />

      <Route render={props => <Error404 {...props} />} />
    </Switch>
  )
}

export default App
