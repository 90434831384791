import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import MainLayout from '../../containers/MainLayout'
import Button from '../../components/Button'
import ButtonLink from '../../components/ButtonLink'
import Report from '../../components/CommercialReporting'
import { useFrankentangel } from '../../frankentangel'

const CommercialReport = props => {
  const { id, type } = useParams()
  const [commercial, setCommercial] = useState({})
  const componentToPrint = useRef()
  const frankentangel = useFrankentangel()
  const handlePrint = useReactToPrint({
    content: () => componentToPrint.current,
    bodyClass: 'p-6'
  })

  const fetchCommercial = async () => {
    const fetchedCommercial = await frankentangel.viewDocument('commercials', Number(id))
    setCommercial(prevCommercial => fetchedCommercial)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    fetchCommercial()
  }, []) // eslint-disable-line

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns is-multiline'>
          <div className='column is-full'>
            <h1 className='title'>Commercial Scheduling Report by {type === 'call-letters' ? 'Call Letters' : 'Market'}</h1>
            <ButtonLink target='/commercials' icon={['fas', 'arrow-left']} linkText='Back to Commercials' />&nbsp;
            <Button onClick={handlePrint} icon={['fas', 'print']} classes={['is-dark']}>Print Report</Button>
          </div>
        </div>
        {
            commercial.agency !== undefined &&
              <Report ref={componentToPrint} type={type} commercial={commercial} />
          }
      </div>
    </MainLayout>
  )
}

export default CommercialReport
