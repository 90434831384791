import React from 'react'
import { useList } from '../../frankentangel'

import CommercialCard from '../../components/CommercialCard'
import CommercialWeek from './CommercialWeek'

const CommsManager = props => {
  const [Paginator, results] = useList('commercial-weeks', 'listWithCommercials')
  if (results) {
    const commsByWeekList = results.map((week, idx) => {
      const numItems = week.doc.commercials.length
      const sumTime = Math.round((week.doc.commercials.reduce((acc, curr) => acc + Number(curr.doc.duration.replace(':', '')), 0)) / 60)
      return (
        <React.Fragment key={week.doc.monday}>
          <CommercialWeek numItems={numItems} sumTime={sumTime} week={week} />
          {week.doc.commercials.map((comm, idx) => <CommercialCard commercial={comm.doc} key={`${comm.doc.name}-${idx}`} />)}
          <CommercialCard isAdd week={week.doc.actualDate} />
        </React.Fragment>
      )
    })
    return (
      <Paginator className='columns is-multiline'>
        {commsByWeekList}
      </Paginator>
    )
  }

  return null
}

export default CommsManager
