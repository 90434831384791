import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEdit, faSyncAlt, faTimes } from '@fortawesome/pro-solid-svg-icons'

import MainLayout from '../../containers/MainLayout'
import ReportTable from '../../components/ReportTable'
import InputField from '../../components/InputField'
import ErrorPage from '../../pages/ErrorPage'
import ButtonLink from '../../components/ButtonLink'
import Button from '../../components/Button'

import { useSearchableList, useFrankentangel } from '../../frankentangel'

const AlbumManager = props => {
  const [searchText, setSearchText] = useState('')
  const frankentangel = useFrankentangel()

  const [Paginator, result, error, loading] = useSearchableList('album', searchText, 'listWithSongs', {}, 'withSongs')

  if (error) {
    console.log(error)
    return <ErrorPage error={error} />
  }

  const handleLibraryRightsAll = async (id, currentLr4All) => await frankentangel.updateDocument('album', id, { lr4all: !currentLr4All })

  const handleActive = async (id, currentActive) => await frankentangel.updateDocument('album', id, { active: !currentActive })

  const searchResults = result
    ? result.map(album => album.doc).map(album => {
        return [
          album.album_name,
          album.songs?.length || 0,
          album.library?.library_name,
          <Button preventDefault key={`button-libraryrights-${album.id}`} onClick={() => handleLibraryRightsAll(album.id, album.lr4all)} icon={album.lr4all ? faCheck : faTimes} classes={{ 'is-danger': !album.lr4all, 'is-success': album.lr4all }}>{album.lr4all ? 'Yes' : 'No'}</Button>,
          <Button preventDefault key={`button-active-${album.id}`} onClick={() => handleActive(album.id, album.active)} icon={album.active ? faCheck : faTimes} classes={{ 'is-danger': !album.active, 'is-success': album.active }}>{album.active ? 'Yes' : 'No'}</Button>,
          <ButtonLink target={`/album-manager/albums/${album.id}`} classes={['is-dark']} icon={faEdit} linkText='Edit' key={album.id} />
        ]
      })
    : null

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns'>
          <div className='column is-full'>
            <div className='columns'>
              <div className='column is-12'>
                <h1 className='title'>Album Manager</h1>
                <ButtonLink target='/album-manager/albums/create' classes={['is-dark']} icon={['fas', 'file-plus']} linkText='Add New Album' />
              </div>
            </div>
          </div>
        </div>
        <div className='columns is-multiline'>
          <div className='column is-half'>
            <InputField loading={loading} label='Search Albums' placeholder='Type here' value={searchText} onChange={ev => setSearchText(ev.target.value)} />
          </div>
        </div>
        <div className='columns'>
          <div className='column is-full'>
            <Paginator spinner={<div className='has-text-centered my-4'><FontAwesomeIcon icon={faSyncAlt} spin size='3x' /></div>}>
              <ReportTable
                headings={['Album', 'Song Count', 'Library', 'LR for All', 'Active', '']}
                tableData={searchResults}
              />
            </Paginator>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default AlbumManager
