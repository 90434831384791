import React, { useState } from 'react'
import classNames from 'classnames'

import useForm from './useForm'

import { useMulti, useSearchableList } from '../frankentangel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faExternalLink, faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { Link } from 'react-router-dom'

const SelectedOption = ({ id, handleOnDelete, displayedSelections, url }) => {
  // const [doc, error, loading] = useDocument(table, Number(id))

  // const content = error ? (
  //   error ? (error.message || error) : 'Unknown Error'
  // ) : (loading ? (
  //   'Loading...'
  // ) : (
  //   displayRows(doc, { doc, id: Number(id) })
  // ))

  const content = (displayedSelections.filter(option => option.value === id)[0] || { label: 'Loading...' }).label

  const handleDelete = (ev) => {
    ev.preventDefault()
    handleOnDelete(id)
  }

  return (
    <div className='field has-addons mb-0 form-join-select__option'>
      <div className='control is-expanded'>
        <input className={classNames('input', { 'form-join-select--has-shortcut': url })} readOnly value={content} tabIndex='-1' />
        {
          url
            ? (
              <Link to={url.replaceAll('%id', id)} className='form-join-select__shortcut button is-small'>
                <span>
                  Open
                </span>
                <span className='icon'>
                  <FontAwesomeIcon icon={faExternalLink} />
                </span>
              </Link>
              )
            : undefined
        }
      </div>
      <div className='control'>
        <button className='button' onClick={handleDelete}>
          <span className='icon is-right'>
            <FontAwesomeIcon icon={faTimesCircle} />
          </span>
        </button>
      </div>
    </div>
    // <div className='card'>
    //   <div className='card-header'>
    //     <div className='card-header-title'>
    //       {content}
    //     </div>
    //     <div className='card-header-icon'>
    //       <button className='delete' aria-label='delete' onClick={handleDelete} tabIndex='-1' />
    //     </div>
    //   </div>
    // </div>
  )
}

const FormJoinSelect = props => {
  const { name, onBlur, onChange, placeholder, disabled, label, multi, table, rows, url, action = 'list', params = {} } = props
  const [fieldValue, fieldState, handleFieldChange, handleFieldBlur] = useForm(name)

  const [searchText, setSearchText] = useState('')
  const [Paginator, results, error, loading] = useSearchableList(table, searchText, action, params) // eslint-disable-line no-unused-vars
  const [selectedResults] = useMulti(table, String(fieldValue).split(',').map(s => Number(s)).filter(s => Number.isInteger(s)))

  const deduplicate = (v, i, a) => a.indexOf(v) === i
  const filterArray = values => values.map(s => String(s).trim()).filter(n => n && n !== '0').filter(deduplicate)

  const arrayValue = filterArray(String(fieldValue).split(','))
  const transformValue = value => multi ? filterArray(value).join(', ') : (Number(value[0]) || 0)

  const handleOnBlur = value => {
    // const newValue = transformValue([value, ...arrayValue])
    const newValue = transformValue([...arrayValue])

    handleFieldBlur(name, newValue)
    if (onBlur) onBlur(newValue)
  }
  const handleOnChange = value => {
    const newValue = multi ? transformValue([...arrayValue, value]) : transformValue([value])
    console.log(name, 'changed to', newValue)

    handleFieldChange(name, newValue)
    if (onChange) onChange(newValue)
  }
  const handleOnDelete = (value) => {
    const newValue = transformValue(arrayValue.filter(i => i !== value))

    handleFieldChange(name, newValue)
    if (onChange) onChange(newValue)
  }

  const displayRows = rows || ((doc, meta) => `Document ${meta.id}`)
  const displayedOptions = (results || []).map(result => ({
    value: String(result.id),
    label: displayRows(result.doc, result)
  })).concat([{ value: null, label: '' }])

  const displayedSelections = (selectedResults || []).map(result => ({
    value: String(result.id),
    label: displayRows(result.doc, result)
  })).concat(displayedOptions)

  const [dropdownActive, setDropdownActive] = useState(false)
  const handleFocus = () => {
    setDropdownActive(true)
  }
  const handleBlur = () => {
    setDropdownActive(false)
    setSearchText('')
    handleOnBlur()
  }
  const handleClick = (event) => {
    if (multi || !arrayValue[0]) return

    // event.preventDefault()
    handleOnDelete(arrayValue[0])
  }

  const selectedTitle = (displayedSelections.filter(option => option.value === arrayValue[0])[0] || { label: fieldValue ? 'Loading...' : '' }).label

  return (
    <div className='field'>
      {label ? <label className='label'>{label}</label> : undefined}
      {
        multi
          ? (
            <div className='control'>
              {arrayValue.map(id => <SelectedOption id={id} key={id} {...{ handleOnDelete, displayedSelections, url }} />)}
            </div>
            )
          : undefined
      }
      <div className={classNames('dropdown is-width-100', { 'is-active': dropdownActive })}>
        <div className='dropdown-trigger is-width-100'>
          <div className='field has-addons'>
            <div className={classNames('control is-expanded has-icons-right', { 'is-loading': loading })}>
              <input
                className={classNames('input is-fullwidth', { 'is-danger': fieldState.state === 'invalid' })}
                onFocus={handleFocus}
                onBlur={handleBlur}
                // onClick={handleClick}
                value={dropdownActive ? searchText : (multi ? '' : selectedTitle)}
                onChange={event => setSearchText(event.target.value)}
                disabled={loading}
                {...{ placeholder, disabled }}
              />
              <span className='icon is-small is-right'>
                <FontAwesomeIcon icon={faCaretDown} />
              </span>
              {
                !multi && arrayValue[0] && url
                  ? (
                    <Link to={url.replaceAll('%id', fieldValue)} className='form-join-select__shortcut button is-small'>
                      <span>
                        Open
                      </span>
                      <span className='icon form-join-select__shortcut-icon'>
                        <FontAwesomeIcon icon={faExternalLink} />
                      </span>
                    </Link>
                    )
                  : undefined
              }
            </div>
            {
              !multi && arrayValue[0]
                ? (
                  <div className='control'>
                    <button className='button' onClick={handleClick}>
                      <span className='icon is-right'>
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </span>
                    </button>
                  </div>
                  )
                : undefined
            }
          </div>
        </div>
        <div className='dropdown-menu is-width-100'>
          <div className='dropdown-content is-width-100 is-scrollable-clamped'>
            <Paginator>
              {
                displayedOptions.map((option) => {
                  const isSelected = arrayValue.includes(option.value)
                  const handleClick = isSelected
                    ? (
                        multi ? () => handleOnDelete(option.value) : undefined
                      )
                    : () => handleOnChange(option.value)

                  return (
                    <a // eslint-disable-line
                      key={option.value}
                      className={classNames('dropdown-item', {
                        'is-active': isSelected
                      })}
                      onMouseDown={handleClick}
                      onTouchStart={handleClick}
                      href='#'
                    >
                      {option.label}
                    </a>
                  )
                })
              }
            </Paginator>
          </div>
        </div>
      </div>
      {error && (<p className='help is-danger'>{error.message || String(error)}</p>)}
      {fieldState.state === 'invalid' && (<p className='help is-danger'>{fieldState.errorMsg}</p>)}
    </div>
  )
}

export default FormJoinSelect
