import React from 'react'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import MainLayout from '../../containers/MainLayout'
import ButtonLink from '../../components/ButtonLink'
import FormWrapper from '../../components/FormWrapper'
import FromInputField from '../../components/FormInputField'
import FormJoinSelect from '../../components/FormJoinSelect'
import SubmitButton from '../../components/SubmitButton'
import FormErrorsList from '../../components/FormErrorsList'
import FormStatus from '../../components/FormStatus'
import FormUploadField from '../../components/FormUploadField'
import SimpleSelect from '../../components/SimpleSelect'
import FormContext from '../../components/FormContext'
import { useFrankentangel } from '../../frankentangel'

const CreateAlbum = props => {
  const frankentangel = useFrankentangel()
  const history = useHistory()

  const submitAlbum = async values => {
    const result = await frankentangel.createDocument('album', values)
    history.push(`/album-manager/albums/${result.id}`)
  }

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns'>
          <div className='column is-full'>
            <div className='columns'>
              <div className='column is-12'>
                <h1 className='title'>Create Album</h1>
                <ButtonLink target='/album-manager' classes={['is-dark']} icon={['fas', 'arrow-left']} linkText='Back to Album Manager' />
              </div>
            </div>
          </div>
        </div>
        <div className='columns'>
          <div className='column is-half'>
            <FormWrapper
              fields={yup.object({
                libid: yup.number(),
                album_name: yup.string().required('Required'),
                songs: yup.string(),
                art_file: yup.string(),
                active: yup.bool(),
                lr4all: yup.bool()
              })}
              submit={submitAlbum}
            >
              <FormJoinSelect name='libid' placeholder='Library' label='Library' table='library' rows={lib => lib.library_name} />
              <FromInputField name='album_name' placeholder='Album Name' label='Album Name' iconLeft={['fas', 'file']} />
              <FormJoinSelect name='songs' placeholder='Songs Connected' label='Songs Connected' table='song' rows={song => `${song.song_title} (${song.album ? `Currently in ${song.album.album_name}` : 'Currently Unlinked'})`} multi />
              <FormUploadField
                name='art_file'
                label='Album Artwork'
                getTicket={async (frankentangel, file) => {
                  const ticket = await frankentangel.runAction('album', 'getUploadTicket', { filename: file.name })
                  return ticket.ticketId
                }}
                help='JPG/PNG, 500 x 500 pixels'
              />
              <FormContext.Consumer>
                {
                  formValues => {
                    let artFile
                    try {
                      artFile = JSON.parse(formValues.values.art_file)
                    } catch (e) {
                      return
                    }
                    return <img src={artFile.url} alt={formValues.values.album_name} className='album__artwork' />
                  }
                }
              </FormContext.Consumer>
              <SimpleSelect name='active' opts={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} label='Active' />
              <SimpleSelect name='lr4all' opts={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} label='Library Rights for All' />
              <FormErrorsList />
              <SubmitButton buttonText='Save' classes={['has-medium-top-margin']} />
              <FormStatus />
            </FormWrapper>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CreateAlbum
