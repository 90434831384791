import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt, faArrowLeft } from '@fortawesome/pro-solid-svg-icons'

import MainLayout from '../../containers/MainLayout'
import ReportTable from '../../components/ReportTable'
import ErrorPage from '../ErrorPage'
import ButtonLink from '../../components/ButtonLink'

import { useList } from '../../frankentangel'

const Searches = props => {
  const [Paginator, results, error] = useList('search-logs', 'list')

  if (error) {
    return <ErrorPage error={error} />
  }

  const searchResults = results
    ? results.map(search => search.doc).map(search => {
        return [
          search.list,
          search.query,
          new Date(search.timestamp).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric' }),
          search.user ? `${search.user.firstName} ${search.user.lastName} (${search.user.email})` : '\xa0'
        ]
      })
    : null

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns'>
          <div className='column is-full'>
            <div className='columns'>
              <div className='column is-6'>
                <h1 className='title'>Searches in the past 7 days</h1>
                <ButtonLink target='/' icon={faArrowLeft} linkText='Back' />
              </div>
              <div className='column is-6'>
                <h2 className='title has-text-right-tablet is-size-5-mobile'>Monday, October 19, 2020</h2>
              </div>
            </div>
          </div>
        </div>
        <div className='columns'>
          <div className='column is-full'>
            <Paginator spinner={<div className='has-text-centered my-4'><FontAwesomeIcon icon={faSyncAlt} spin size='3x' /></div>}>
              <ReportTable
                headings={['Page', 'Phrase', 'Timestamp', 'User (if applicable)']}
                tableData={searchResults}
              />
            </Paginator>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
export default Searches
