import React from 'react'
import MainLayout from '../containers/MainLayout'
import moment from 'moment'

// import StatCard from '../components/StatCard'

import SearchesLastSeven from '../components/DashBoxes/SearchesLastSeven'
import SubVsOutLastWeek from '../components/DashBoxes/SubVsOutLastWeek'
import OverdueTwoWeeks from '../components/DashBoxes/OverdueTwoWeeks'
import CommercialDownloadsSum from '../components/DashBoxes/CommercialDownloadsSum'
import TotalTracksDownloadedSum from '../components/DashBoxes/TotalTracksDownloadedSum'
import EmailBouncedBox from '../components/DashBoxes/EmailBouncedBox'
import OrphanedUsersBox from '../components/DashBoxes/OrphanedUsersBox'
import OrphanedContractsBox from '../components/DashBoxes/OrphanedContractsBox'
import OrphanedAccountsBox from '../components/DashBoxes/OrphanedAccountsBox'

const Home = props => {
  const currentFirstDayOfWeek = moment().startOf('isoWeek').format('dddd, MMMM DD, YYYY')
  // const previousFirstDayOfWeek = moment().subtract(7, 'days').startOf('isoWeek').format('dddd, MMM DD')

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns'>
          <div className='column is-full'>
            <div className='columns'>
              <div className='column is-3'>
                <h2 className='title'>
                  TOP 5
                </h2>
              </div>
              <div className='column is-9'>
                <h2 className='title has-text-right-tablet is-size-5-mobile'>{currentFirstDayOfWeek}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className='columns is-multiline'>
          <SubVsOutLastWeek />
          <OverdueTwoWeeks />
          <CommercialDownloadsSum />
          <SearchesLastSeven />
          <TotalTracksDownloadedSum />
        </div>
        <div className='columns'>
          <div className='column is-full'>
            <h2 className='title mt-5 has-text-centered-mobile'>
              Notifications
            </h2>
          </div>
        </div>
        <div className='columns is-multiline'>
          <EmailBouncedBox />
          <OrphanedUsersBox />
          <OrphanedAccountsBox />
          <OrphanedContractsBox />
        </div>
      </div>
    </MainLayout>
  )
}

export default Home
