import React from 'react'
import { useDocument } from '../../frankentangel'

const DocName = (props) => {
  const [doc] = useDocument(props.table, Number(props.id))
  return doc ? doc.name : 'Loading...'
}

const CommercialReview = props => {
  const { values } = props

  const listOf = (table, idList) => (
    <span>
      {
        idList.split(',').map(id => id.trim()).filter(id => id).map((id, index, array) => {
          return (
            <span key={id} className='mr-1' style={{ display: 'inline' }}>
              <DocName table={table} id={id} />
              {(index < array.length - 1) ? ', ' : ''}
            </span>
          )
        })

      }
    </span>
  )

  return (
    <>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>Commercial name:</label>
        </div>
        <div className='field-body'>{values.name}</div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>Commercial description: </label>
        </div>
        <div className='field-body'>{values.description}</div>
      </div>
      {/* File and Server folder */}
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>Duration: </label>
        </div>
        <div className='field-body'>{values.duration}</div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>Run week: </label>
        </div>
        <div className='field-body'>{values.week}</div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>Run days: </label>
        </div>
        <div className='field-body'>{values.days.map ? values.days.map((day, idx) => <React.Fragment key={day + idx}>{day.day + ': ' + day.comment}<br /></React.Fragment>) : ''}</div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>Language: </label>
        </div>
        <div className='field-body'>{values.language}</div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>File: </label>
        </div>
        <div className='field-body'>{values.file ? JSON.parse(values.file).originalName : ''}</div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>Ad Agency: </label>
        </div>
        <div className='field-body'>{listOf('commsagency', `${values.agency}`)}</div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>ISCI: </label>
        </div>
        <div className='field-body'>{values.isci}</div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>Order Number: </label>
        </div>
        <div className='field-body'>{values.orderNumber}</div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>Account Rule: </label>
        </div>
        <div className='field-body'>{values.accountRule ? 'INCLUDE all accounts' : 'EXCLUDE all accounts'}</div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>Formats: </label>
        </div>
        <div className='field-body'>{listOf('commsformat', values.formats)}</div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>Regions: </label>
        </div>
        <div className='field-body'>{listOf('commsregion', values.regions)}</div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>Markets: </label>
        </div>
        <div className='field-body'>{listOf('commsmarket', values.markets)}</div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>States: </label>
        </div>
        <div className='field-body'>{values.states}</div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>ZIP Codes: </label>
        </div>
        <div className='field-body'>{values.zips}</div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>Accounts Explicitly Enabled: </label>
        </div>
        <div className='field-body'>{listOf('accounts', values.accountsEnabled)}</div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>Accounts Explicitly Disabled: </label>
        </div>
        <div className='field-body'>{listOf('accounts', values.accountsDisabled)}</div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>Special Instructions: </label>
        </div>
        <div className='field-body'>{values.instructions}</div>
      </div>
    </>
  )
}

export default CommercialReview
