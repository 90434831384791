import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt, faArrowLeft } from '@fortawesome/pro-solid-svg-icons'

import MainLayout from '../../containers/MainLayout'
import ReportTable from '../../components/ReportTable'
import ErrorPage from '../ErrorPage'
import ButtonLink from '../../components/ButtonLink'

import { useList } from '../../frankentangel'

const OrphanedUsers = props => {
  const [Paginator, results, error] = useList('users', 'listOrphaned')

  if (error) {
    return <ErrorPage error={error} />
  }

  const searchResults = results
    ? results.map(user => user.doc).map(user => {
        return [
          `${user.firstName} ${user.lastName}`,
          user.email,
          user.title,
          user.station[0] ? `${user.station[0].callsign} - ${user.station[0].name}` : 'MISSING'
        ]
      })
    : null

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns'>
          <div className='column is-full'>
            <div className='columns'>
              <div className='column is-12'>
                <h1 className='title'>Unlinked Users</h1>
                <ButtonLink target='/' icon={faArrowLeft} linkText='Back' />
              </div>
            </div>
          </div>
        </div>
        <div className='columns'>
          <div className='column is-full'>
            <Paginator spinner={<div className='has-text-centered my-4'><FontAwesomeIcon icon={faSyncAlt} spin size='3x' /></div>}>
              <ReportTable
                headings={['Name', 'Email', 'Title', 'Radio Station']}
                tableData={searchResults}
              />
            </Paginator>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default OrphanedUsers
