import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import useForm from './useForm'

const FormNumberInput = props => {
  const { id, name, onBlur, onChange, placeholder, label, classes, step, strictStep, min, max } = props
  const [fieldValue, fieldState, handleFieldChange, handleFieldBlur] = useForm(name)

  useEffect(() => {
    if (fieldValue === '') handleFieldChange(name, 0)
  }, [fieldValue]) // eslint-disable-line

  const handleOnBlur = ev => {
    handleFieldBlur(name, parseFloat(ev.target.value))
    if (onBlur) onBlur(ev)
  }

  const handleOnChange = ev => {
    if (isNaN(ev.target.value) || ev.target.value === '') return
    const changeValue = strictStep ? Math.ceil(ev.target.value / step) * step : ev.target.value
    handleFieldChange(name, parseFloat(changeValue))
    if (onChange) onChange(ev)
  }

  const handleSubtractValue = () => {
    if (isNaN(fieldValue)) return
    let newValue = parseFloat(fieldValue)
    newValue -= parseFloat(step)
    const changeValue = strictStep ? Math.ceil(newValue / step) * step : newValue
    handleFieldBlur(name, changeValue)
  }

  const handleAddValue = () => {
    if (isNaN(fieldValue)) return
    let newValue = parseFloat(fieldValue)
    newValue += parseFloat(step)
    const changeValue = strictStep ? Math.ceil(newValue / step) * step : newValue
    handleFieldBlur(name, changeValue)
  }

  const numberInputProperties = {
    type: 'text',
    className: classNames('input', 'has-text-centered', 'form-number-input', { 'is-danger': fieldState === 'invalid' }, classes),
    placeholder,
    id,
    name,
    value: fieldValue,
    onChange: ev => handleOnChange(ev),
    onBlur: ev => handleOnBlur(ev),
    onFocus: ev => ev.target.select()
  }

  const numberInputMarkup = (
    <>
      {label && <label className='label'>{label}</label>}
      <div className='field has-addons'>
        <div className='control'>
          <button className='button is-dark' type='button' disabled={fieldValue <= (parseFloat(min) || 0)} onClick={handleSubtractValue}>
            <span className='icon'><FontAwesomeIcon icon={['fas', 'minus']} /></span>
          </button>
        </div>
        <div className='control'>
          <input {...numberInputProperties} />
        </div>
        <div className='control'>
          <button className='button is-dark' type='button' disabled={max ? fieldValue >= parseFloat(max) : false} onClick={handleAddValue}>
            <span className='icon'><FontAwesomeIcon icon={['fas', 'plus']} /></span>
          </button>
        </div>
      </div>
      {fieldState.state === 'invalid' && (<p className='help is-danger'>{fieldState.errorMsg}</p>)}
    </>
  )

  return numberInputMarkup
}

export default FormNumberInput
