import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from './Button'
import moment from 'moment'
import classNames from 'classnames'

const SearchResult = props => {
  const { result, type } = props
  const history = useHistory()
  let typeText, content

  const goEdit = () => history.push(`/${type}/${result.id}`)

  switch (true) {
    case type === 'accounts':
      content = (
        <>
          <div className='title is-5'>{result.name}</div>
          <p className='subtitle is-6'>{result.callsign}</p>
          <Button onClick={goEdit} icon={['fas', 'file-signature']} classes={['is-fullwidth', 'has-medium-top-margin']}>Details</Button>
        </>
      )
      typeText = 'Account'
      break
    case type === 'users':
      content = (
        <>
          <div className='title is-5'>{result.firstName} {result.lastName} <small>{result.email}</small></div>
          <p className='subtitle is-6'>{result.isSuperAdmin ? 'SuperAdmin' : (result.isAdmin ? 'Admin' : (result.type || 'User'))}</p>
          <Button onClick={goEdit} icon={['fas', 'file-signature']} classes={['is-fullwidth', 'has-medium-top-margin']}>Details</Button>
        </>
      )
      typeText = 'User'
      console.log(result)
      break
    case type === 'contracts':
      content = (
        <>
          <div className='title is-5'>{result.name}</div>
          <p className='subtitle is-6'>{`${moment(result.startDate).format('MMM Do YYYY')} - ${moment(result.expirationDate).format('MMM Do YYYY')}`}</p>
          <Button onClick={goEdit} icon={['fas', 'file-signature']} classes={['is-fullwidth', 'has-medium-top-margin']}>Details</Button>
        </>
      )
      typeText = 'Contract'
      break
    default:
      break
  }

  const resultMarkup = (
    <div className='column is-one-third'>
      <div className='card is-flex has-columndirection'>
        <div className='card-image'>
          <small className={classNames('px-2', 'px-2', 'py-1', 'has-text-weight-bold', 'is-block', 'has-text-white', { 'has-background-grey-light': typeText === 'Account' }, { 'has-background-grey': typeText === 'Contract' }, { 'has-background-black': typeText === 'User' })}>
            {typeText}
          </small>
        </div>
        <div className='card-content'>
          {content}
        </div>
      </div>
    </div>
  )

  return resultMarkup
}

export default SearchResult
