import React from 'react'

import StatCard from '../StatCard'
import { useAction } from '../../frankentangel'

const OrphanedContractsBox = props => {
  const [orphanedContracts,, orphanedContractsLoading] = useAction('contracts', 'numOrphaned')

  return (
    <StatCard
      title='Contracts'
      firstValue={orphanedContracts?.total}
      content='Unlinked Contracts'
      linksTo='/reports/unlinked-contracts'
      loading={orphanedContractsLoading}
      mode='wide'
    />
  )
}

export default OrphanedContractsBox
