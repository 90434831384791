import React from 'react'
import Button from '../components/Button'

const ErrorPage = props => {
  const errorMsg = props.error ? (props.error.message || props.error) : 'Unknown Error'
  return (
    <section className='hero is-fullheight get-my-position-back'>
      <div className='hero-body'>
        <div className='container has-text-center'>
          <div className='columns is-centered'>
            <div className='column is-one-third'>
              <article className='message is-danger'>
                <div className='message-header'>
                  <p>Error</p>
                </div>
                <div className='message-body'>
                  <p>{errorMsg}</p>
                  <Button onClick={() => window.location.reload()} classes={['is-warning', 'has-medium-top-margin']} icon={['fas', 'redo']}>Reload</Button>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ErrorPage
