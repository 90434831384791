import React, { useEffect } from 'react'

import useForm from './useForm'
import Button from './Button'

const FormCommercialOccurences = props => {
  const { name } = props
  const [fieldValue,, handleFieldChange] = useForm(name)

  useEffect(() => {
    if (!Array.isArray(fieldValue) || fieldValue.length === 0) handleFieldChange(name, [{ day: 'Monday', comment: '' }])
  }, [fieldValue]) // eslint-disable-line

  const updateOccurence = (idx, type, val) => {
    const currentOccurences = fieldValue
    if (type === 'day') currentOccurences[idx].day = val
    else currentOccurences[idx].comment = val
    handleFieldChange(name, currentOccurences)
  }

  const addOccurence = () => {
    const currentOccurences = fieldValue
    currentOccurences.push({ day: 'Monday', comment: '' })
    handleFieldChange(name, currentOccurences)
  }

  const handleDeleteOccurence = idx => {
    const currentOccurences = fieldValue
    currentOccurences.splice(idx, 1)
    handleFieldChange(name, currentOccurences)
  }

  return (
    <>
      <label className='label'>Days</label>
      {fieldValue.map && fieldValue.map((occ, idx) => {
        return (
          <React.Fragment key={idx}>
            <div className='field has-addons'>
              <div className='control'>
                <div className='select'>
                  <select value={fieldValue[idx].day} onChange={ev => updateOccurence(idx, 'day', ev.target.value)}>
                    <option value='Monday'>Monday</option>
                    <option value='Tuesday'>Tuesday</option>
                    <option value='Wednesday'>Wednesday</option>
                    <option value='Thursday'>Thursday</option>
                    <option value='Friday'>Friday</option>
                    <option value='Saturday'>Saturday</option>
                    <option value='Sunday'>Sunday</option>
                  </select>
                </div>
              </div>
              <div className='control'>
                <input value={fieldValue[idx].comment} className='input' type='text' placeholder='Instructions' onChange={ev => updateOccurence(idx, 'comment', ev.target.value)} />
              </div>
              {(idx > 0) &&
                <div className='control'>
                  <Button icon={['fas', 'times-circle']} onClick={() => handleDeleteOccurence(idx)} />
                </div>}
            </div>
          </React.Fragment>
        )
      })}
      <div className='field'>
        <div className='control'>
          <Button onClick={addOccurence} icon={['fas', 'plus']}>Add</Button>
        </div>
      </div>
    </>
  )
}

export default FormCommercialOccurences
