import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

const AccountCard = props => {
  const { account, cardLinkMode } = props

  const history = useHistory()
  const [goEdit, triggerEdit] = useState(false)

  useEffect(() => {
    if (goEdit) cardLinkMode === 'edit' ? history.push(`/accounts/${account.id}`) : history.push(`/affidavits/account/${account.id}/weeks`)
  })

  return (
    <div className='column is-one-third'>
      <div className='card' onClick={() => triggerEdit(true)}>
        <div className='card-image'>
          <small className={classNames('px-2', 'px-2', 'py-1', 'has-text-weight-bold', 'is-block', 'has-text-white', { 'has-background-grey-light': account.type === 'Radio Station' }, { 'has-background-black': account.type === 'Retail Customer' })}>
            {account.type}
          </small>
        </div>
        <div className='card-content'>
          <p className='title is-4'>{account.name}</p>
          <p className='subtitle is-6'>{account.callsign}</p>
        </div>
      </div>
    </div>
  )
}

export default AccountCard
