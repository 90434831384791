import React from 'react'

const FormContext = React.createContext({
  fields: {},
  values: {},
  states: {},
  initialValuesFrom: {},
  isFormStatus: false,
  isSubmitting: false,
  showErrorsList: false,
  status: {
    initial: true,
    success: '',
    error: ''
  },
  handleFieldChange: () => {},
  handleFieldBlur: () => {},
  setIsFormStatus: () => {},
  onSubmit: () => {}
})

export default FormContext
