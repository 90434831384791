import React from 'react'
import moment from 'moment'

import StatCard from '../StatCard'
import { useAction } from '../../frankentangel'

const SubVsOutLastWeek = props => {
  const mondayLastWeek = moment().subtract(7, 'days').startOf('isoWeek').format('YYYY-MM-DD')
  const previousFirstDayOfWeek = moment().subtract(7, 'days').startOf('isoWeek').format('dddd, MMM DD')

  const [affidavitsLastWeek,, affidavitsLastWeekLoading] = useAction('dashboard-stations-assigned', 'affidavitWeekStatus', { week: mondayLastWeek })

  return (
    <StatCard
      title='Compliance'
      firstValue={affidavitsLastWeek?.submitted}
      secondValue={affidavitsLastWeek?.total}
      content={`Submitted vs Outstanding Affidavits - Previous Week ${previousFirstDayOfWeek}`}
      linksTo='/reports/sub-out-last-week'
      loading={affidavitsLastWeekLoading}
    />
  )
}

export default SubVsOutLastWeek
