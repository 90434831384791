import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import useGlobalState from './useGlobalState'
import Button from './Button'
import ButtonLink from './ButtonLink'
import useModal from './useModal'
import { useFrankentangel } from '../frankentangel'
import toWeekday from '../utils/to-weekday'

const CommercialCard = props => {
  const { commercial, isAdd, week } = props
  const history = useHistory()
  const frankentangel = useFrankentangel()
  const [modal, showModal] = useModal()
  const [, setMonday] = useGlobalState('monday', '')

  const handleClone = async () => {
    const cloneSettings = {
      isci: commercial.isci,
      week: commercial.week,
      name: commercial.name
    }

    const snapWeek = (ev) => {
      cloneSettings.week = toWeekday(cloneSettings.week, 'Mon').toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' })
      if (ev) ev.target.value = cloneSettings.week
    }

    const response = await showModal(
      'Clone Commercial',
      <>
        <div className='field'>
          <label className='label'>Name:</label>
          <div className='control'>
            <input className='input' defaultValue={cloneSettings.name} onChange={(ev) => (cloneSettings.name = ev.target.value)} />
          </div>
        </div>
        <div className='field'>
          <label className='label'>Isci:</label>
          <div className='control'>
            <input className='input' defaultValue={cloneSettings.isci} onChange={(ev) => (cloneSettings.isci = ev.target.value)} />
          </div>
        </div>
        <div className='field'>
          <label className='label'>Week:</label>
          <div className='control'>
            <input className='input' defaultValue={cloneSettings.week} onChange={(ev) => (cloneSettings.week = ev.target.value)} onBlur={snapWeek} />
          </div>
        </div>
      </>,
      [{
        text: 'Cancel',
        response: 'no',
        isCancel: true
      }, {
        text: 'Clone',
        className: 'is-primary',
        response: 'yes'
      }]
    )
    if (response !== 'yes') return

    snapWeek()
    if (cloneSettings.week === 'Invalid Date') {
      throw new Error('The week entered is not a valid Monday')
    }

    const cloneCommercial = { ...commercial }

    delete cloneCommercial.id
    cloneCommercial.markets = (cloneCommercial.markets || []).map(market => market.id).join(', ')
    cloneCommercial.regions = (cloneCommercial.regions || []).map(region => region.id).join(', ')
    cloneCommercial.formats = (cloneCommercial.formats || []).map(format => format.id).join(', ')
    cloneCommercial.agency = cloneCommercial.agency ? cloneCommercial.agency.id : 0
    cloneCommercial.accountRule = cloneCommercial.accountRule !== 0

    cloneCommercial.isci = cloneSettings.isci
    cloneCommercial.name = cloneSettings.name
    cloneCommercial.week = cloneSettings.week

    const result = await frankentangel.createDocument('commercials', { ...cloneCommercial })

    if (result.id) history.push(`/commercials/${result.id}`)
  }

  const handleDelete = async () => {
    const response = await showModal(
      'Clone Commercial',
      `Are you sure you'd like to delete commercial "${commercial.name}" (${commercial.isci})?`,
      [{
        text: 'Cancel',
        response: 'no',
        isCancel: true
      }, {
        text: 'Delete',
        className: 'is-danger',
        response: 'yes'
      }]
    )
    if (response !== 'yes') return

    await frankentangel.deleteDocument('commercials', commercial.id)
  }

  const setMondayAndCreate = () => {
    setMonday(new Date(week))
    history.push('commercials/create')
  }

  if (isAdd) {
    return (
      <div className='column is-one-third'>
        <div className='card comms-add'>
          <div className='card-content'>
            <Button icon={['fas', 'plus']} classes={['is-fullwidth', 'comms-add__btn', 'has-background-white']} onClick={setMondayAndCreate} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='column is-one-third'>
      <Link to={`/commercials/${commercial.id}`} style={{ display: 'block' }} className='card'>
        <div className='card-content'>
          <p className='title is-4'>{commercial.name}</p>
          <p className='subtitle is-6'>{commercial.isci}</p>
          <Button preventDefault onClick={handleClone} icon={['fas', 'clone']} classes={['is-fullwidth', 'has-medium-top-margin']}>Clone Commercial</Button>
          <ButtonLink target={`/commercials/${commercial.id}/report/market`} icon={['fas', 'file-chart-pie']} classes={['is-fullwidth', 'has-medium-top-margin']} linkText='Report by Market' />
          <ButtonLink target={`/commercials/${commercial.id}/report/call-letters`} icon={['fas', 'file-chart-line']} classes={['is-fullwidth', 'has-medium-top-margin']} linkText='Report by Call Letters' />
          <Button preventDefault onClick={handleDelete} icon={['fas', 'trash']} classes={['is-fullwidth', 'has-medium-top-margin']}>Delete Commercial</Button>
        </div>
      </Link>
      {modal}
    </div>
  )
}

export default CommercialCard
