import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import MainLayout from '../../containers/MainLayout'
import ButtonLink from '../../components/ButtonLink'
import FormWrapper from '../../components/FormWrapper'
import FromInputField from '../../components/FormInputField'
import FormJoinSelect from '../../components/FormJoinSelect'
import SubmitButton from '../../components/SubmitButton'
import FormErrorsList from '../../components/FormErrorsList'
import FormStatus from '../../components/FormStatus'
import { useFrankentangel } from '../../frankentangel'
import ObjectDelete from '../../components/ObjectDelete'

const Library = props => {
  const { id } = useParams()
  const [library, setLibrary] = useState({})
  const frankentangel = useFrankentangel()

  const fetchLibrary = async () => {
    const fetchedLibrary = await frankentangel.viewDocument('library', Number(id), 'withAlbums')
    fetchedLibrary.albums = (fetchedLibrary.albums || []).map(album => album.id).join(', ')
    console.log(fetchedLibrary)
    setLibrary(fetchedLibrary)
  }

  useEffect(() => {
    fetchLibrary()
  }, []) // eslint-disable-line

  const submitLibrary = async values => {
    await frankentangel.updateDocument('library', Number(id), values)
    fetchLibrary()
    return 'Library settings saved'
  }

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns'>
          <div className='column is-full'>
            <div className='columns'>
              <div className='column is-12'>
                <h1 className='title'>Library</h1>
                <ButtonLink target='/library-manager' classes={['is-dark']} icon={['fas', 'arrow-left']} linkText='Back to Library Manager' />&nbsp;
                <ObjectDelete objectName='library' tableName='library' objectId={library.id} objectTitle={library.library_name} returnTo='/library-manager' />
              </div>
            </div>
          </div>
        </div>
        <div className='columns'>
          <div className='column is-half'>
            <FormWrapper
              fields={yup.object({
                library_name: yup.string().required('Required'),
                albums: yup.string()
              })}
              initialValuesFrom={library}
              submit={submitLibrary}
            >
              <FromInputField name='library_name' placeholder='Libary Name' label='Library Name' iconLeft={['fas', 'file']} />
              <FormJoinSelect
                name='albums'
                placeholder='Albums Connected'
                label='Albums Connected'
                table='album'
                rows={album => {
                  if (album.library.id === Number(id)) return album.album_name
                  return `${album.album_name} (${album.library ? `Currently in ${album.library.library_name}` : 'Currently Unlinked'})`
                }}
                multi
              />
              <FormErrorsList />
              <SubmitButton buttonText='Save' classes={['has-medium-top-margin']} />
              <FormStatus />
            </FormWrapper>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Library
