import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt, faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'
import * as yup from 'yup'

import MainLayout from '../../containers/MainLayout'
import ReportTable from '../../components/ReportTable'
import ErrorPage from '../ErrorPage'
import ButtonLink from '../../components/ButtonLink'

import FormWrapper from '../../components/FormWrapper'
import FormJoinSelect from '../../components/FormJoinSelect'
import FormContext from '../../components/FormContext'
import { useList, useFrankentangel } from '../../frankentangel'

const SubOutLastWeek = props => {
  const frankentangel = useFrankentangel()
  const mondayLastWeek = moment().subtract(7, 'days').startOf('isoWeek').format('YYYY-MM-DD')
  const [selectedWeek, selectWeek] = useState(mondayLastWeek)
  const [unicorn] = useState({})
  const [Paginator, results, error] = useList('dashboard-stations-with-affidavit', 'affidavitWeekList', { week: selectedWeek || mondayLastWeek })
  const previousFirstDayOfWeek = moment(selectedWeek?.replaceAll('-', '/')).format('dddd, MMM DD, YYYY')

  if (error) {
    return <ErrorPage error={error} />
  }

  const findCommDate = async idVal => {
    if (unicorn.lastIdVal === idVal) return
    unicorn.lastIdVal = idVal
    const result = await frankentangel.viewDocument('commercial-weeks', idVal)
    selectWeek(result?.actualDate)
  }

  const searchResults = results
    ? results.map(aff => aff.doc).map(aff => {
        return [
          `${aff.account?.callsign} - ${aff.account?.name}`,
          aff.affidavitSubmitted ? 'Yes' : 'No',
          aff.playbacksRequested,
          aff.playbacksCompleted,
          aff.submittedAt ? new Date(aff.submittedAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric' }) : '\xa0',
          aff.submittedBy ? `${aff.submittedBy.firstName} ${aff.submittedBy.lastName} (${aff.submittedBy.email})` : '\xa0'
        ]
      })
    : null

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns'>
          <div className='column is-full'>
            <div className='columns'>
              <div className='column is-6'>
                <h1 className='title'>Submitted Vs Outstanding Affidavits</h1>
                <ButtonLink target='/' icon={faArrowLeft} linkText='Back' />
              </div>
              <div className='column is-6'>
                <h2 className='title has-text-right-tablet is-size-5-mobile'>{previousFirstDayOfWeek}</h2>
                <FormWrapper fields={yup.object({ week: yup.string() })}>
                  <FormJoinSelect
                    name='week' placeholder='Select Commercial Week' table='commercial-weeks' rows={
                    week => new Date(week?.actualDate.replaceAll('-', '/')).toLocaleString('en-US', { weekday: 'long', month: 'short', day: '2-digit', year: 'numeric' })
                  }
                  />
                  <FormContext.Consumer>
                    {
                      formValues => {
                        if (formValues.values.week !== '') findCommDate(formValues.values.week)
                      }
                    }
                  </FormContext.Consumer>
                </FormWrapper>
              </div>
            </div>
          </div>
        </div>
        <div className='columns'>
          <div className='column is-full'>
            <Paginator spinner={<div className='has-text-centered my-4'><FontAwesomeIcon icon={faSyncAlt} spin size='3x' /></div>}>
              <ReportTable
                headings={['Account', 'Submitted', 'Air Times Requested', 'Air Times Completed', 'Submitted On', 'Submitted By']}
                tableData={searchResults}
              />
            </Paginator>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default SubOutLastWeek
