import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamationTriangle, faSyncAlt, faUpload } from '@fortawesome/pro-solid-svg-icons'
import { useFrankentangel } from '../frankentangel'
import classNames from 'classnames'

import config from '../config'
import useForm from './useForm'

const FormUploadField = (props) => {
  const { id, name, placeholder, disabled, label, help, getTicket } = props
  const [fieldValue, fieldState, handleFieldChange, handleFieldBlur] = useForm(name)

  const frankentangel = useFrankentangel()

  const [file, setFile] = useState(null)
  const [uploadState, setUploadState] = useState({ uploading: false, progress: 0, done: false, error: false })

  let originalName = null
  try {
    originalName = JSON.parse(fieldValue).originalName
  } catch (e) {
    // don't care
  }

  const defaultFilename = fieldValue && originalName

  const uploadFile = async (file) => {
    setFile(file)
    setUploadState(state => ({ ...state, uploading: true, progress: 0, done: false, error: false }))

    try {
      const ticket = await getTicket(frankentangel, file)

      const uploadEvent = await new Promise((resolve, reject) => {
        const uploadRequest = new window.XMLHttpRequest()

        uploadRequest.addEventListener('load', resolve)
        uploadRequest.addEventListener('error', reject)

        uploadRequest.upload.addEventListener('progress', event => setUploadState(state => ({ ...state, progress: event.loaded / event.total })))

        uploadRequest.open('POST', new URL('upload', config.apiRoot))

        uploadRequest.setRequestHeader('upload-ticket', ticket)

        const formData = new window.FormData()
        formData.append('file', file)

        uploadRequest.send(formData)
      })

      if (uploadEvent.target.responseText === 'Not Found') throw new Error('Upload route returned 404')

      const uploadResponse = JSON.parse(uploadEvent.target.responseText)
      if (!uploadResponse.ok) throw new Error(uploadResponse.error || uploadResponse)

      const descriptor = JSON.stringify(uploadResponse.descriptor)

      handleFieldChange(name, descriptor)
      handleFieldBlur(name, descriptor)

      setUploadState(state => ({ ...state, uploading: false, done: true }))
    } catch (err) {
      console.log('[FormUploadField] error while uploading:', err)
      setUploadState(state => ({ ...state, uploading: false, error: err.message || String(err) }))
    }
  }

  const [focused, setFocused] = useState(false)
  const handleBlur = () => {
    setFocused(false)
    // handleFieldBlur(name, fieldValue)
  }

  return (
    <div className='field'>
      <label className='label'>{label}</label>
      <div className='control'>
        <div className={classNames('file has-name', { 'is-danger': fieldState.state === 'invalid', 'is-focused': focused })}>
          <label className='file-label'>
            <input className='file-input' type='file' {...{ id, name, disabled }} onChange={event => uploadFile(event.target.files[0])} onFocus={() => setFocused(true)} onBlur={handleBlur} />
            <span className='file-cta'>
              <span className='file-icon'>
                <FontAwesomeIcon icon={faUpload} />
              </span>
              <span className='file-label'>
                {placeholder || 'Choose a file...'}
              </span>
            </span>
          </label>
          <span className={classNames('file-name', { 'has-text-danger': fieldState.state === 'invalid' })}>
            {
              uploadState.uploading
                ? (
                  <>
                    <span className='icon mr-1'>
                      <FontAwesomeIcon icon={faSyncAlt} spin />
                    </span>
                    Uploading... {(uploadState.progress * 100).toFixed(1)}%
                  </>
                  )
                : (
                    uploadState.error
                      ? (
                        <>
                          <span className='icon mr-1'>
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                          </span>
                          <span title={uploadState.error}>
                            {uploadState.error}
                          </span>
                        </>
                        )
                      : (
                        <>
                          {
                            uploadState.done
                              ? (
                                <span className='icon mr-1'>
                                  <FontAwesomeIcon icon={faCheck} />
                                </span>
                                )
                              : undefined
                          }
                          {file ? file.name : (defaultFilename || 'No file selected')}
                        </>
                        )
                  )
            }
          </span>
        </div>
      </div>
      {
        fieldState.state === 'invalid'
          ? (
            <p className='help is-danger'>{fieldState.errorMsg}</p>
            )
          : (
              help
                ? (
                  <p className='help'>{help}</p>
                  )
                : undefined
            )
      }
    </div>
  )
}

export default FormUploadField
