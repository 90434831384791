import React from 'react'
import classNames from 'classnames'
import DesktopNavbar from '../components/DesktopNavbar'
import Footer from '../components/Footer'

const MainLayout = props => {
  const outerClasses = classNames('hero', props.outerClasses, { 'is-fullheight-with-navbar': props.fullheight })
  const innerClasses = classNames('hero-body', props.innerClasses, { 'is-paddingless': props.paddingless }, { 'align-to-start': props.alignstart })
  return (
    <>
      <DesktopNavbar />
      <div className={outerClasses}>
        <div className={innerClasses}>
          {props.children}
        </div>
        <Footer />
      </div>
    </>
  )
}

export default MainLayout
