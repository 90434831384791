import React, { useState, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSession, useFrankentangel } from '../frankentangel'
import classNames from 'classnames'
import Button from './Button'
import InputField from './InputField'
import useGlobalState from './useGlobalState'

const DesktopNavbar = props => {
  const [currentSession] = useSession()
  const frankentangel = useFrankentangel()
  const [dropDownStates, setDropDownStates] = useState({
    accounts: false,
    commercials: false,
    music: false,
    debug: false
  })
  const history = useHistory()

  const dropDownSwitcher = (ev, which) => {
    if (!ev.target.href || new URL(ev.target.href, window.location.href).host === window.location.host) ev.preventDefault()
    const currentState = dropDownStates
    currentState[which] = !dropDownStates[which]
    setDropDownStates({ ...currentState })
  }

  const [searchQuery, setSearchQuery] = useGlobalState('search', '')
  const [burgerOpen, setBurgerOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)

  const searchInput = useRef()

  const handleKeyUp = ev => {
    if ((ev.key === 'Enter')) handleSearch(ev)
    if (ev.metaKey && ev.key === 'Enter') handleSearch(ev)
    if (ev.ctrlKey && ev.key === 'Enter') handleSearch(ev)
  }

  const handleSearch = ev => {
    if (searchQuery === '' && window.innerWidth <= 480) {
      ev.preventDefault()
      setSearchOpen(!searchOpen)
      searchInput.current.focus()
      return
    }
    history.push('/search')
  }

  const handleQueryChange = ev => {
    setSearchQuery(ev.target.value)
  }

  const logOut = () => frankentangel.runAction('users', 'logout')

  const navigationLinks = (
    <>
      <Link to='/' className='navbar-item'>
        Dashboard
      </Link>
      <div className={classNames('navbar-item', 'has-dropdown', { 'is-active': dropDownStates.accounts })} onClick={ev => dropDownSwitcher(ev, 'accounts')}>
        <a href='/' className='navbar-link'>
          Accounts
        </a>

        <div className='navbar-dropdown'>
          <Link to='/contracts' className='navbar-item'>
            Contracts
          </Link>
          <Link to='/accounts' className='navbar-item'>
            Accounts
          </Link>
          <Link to='/users' className='navbar-item'>
            Users
          </Link>
        </div>
      </div>
      <div className={classNames('navbar-item', 'has-dropdown', { 'is-active': dropDownStates.commercials })} onClick={ev => dropDownSwitcher(ev, 'commercials')}>
        <a href='/' className='navbar-link'>
          Commercials
        </a>

        <div className='navbar-dropdown'>
          <Link to='/commercials' className='navbar-item'>
            Commercials
          </Link>
          <Link to='/affidavits' className='navbar-item'>
            Affidavits
          </Link>
        </div>
      </div>
      <div className={classNames('navbar-item', 'has-dropdown', { 'is-active': dropDownStates.music })} onClick={ev => dropDownSwitcher(ev, 'music')}>
        <a href='/' className='navbar-link'>
          Music
        </a>

        <div className='navbar-dropdown'>
          <Link to='/library-manager' className='navbar-item'>
            Library Manager
          </Link>
          <Link to='/album-manager' className='navbar-item'>
            Album Manager
          </Link>
          <Link to='/track-manager' className='navbar-item'>
            Track Manager
          </Link>
        </div>
      </div>
      <Link to='/admin-users' className='navbar-item'>
        Admin Users
      </Link>
      {
        (window.localStorage && window.localStorage['entangel-debug'])
          ? (
            <div className={classNames('navbar-item', 'has-dropdown', { 'is-active': dropDownStates.debug })} onClick={ev => dropDownSwitcher(ev, 'debug')}>
              <a href='/' className='navbar-link'>
                Debug
              </a>

              <div className='navbar-dropdown'>
                <a href='https://db.taamusic.com' target='_blank' rel='noopener noreferrer' className='navbar-item'>
                  Database
                </a>
                <a href='https://kibana.taamusic.com' target='_blank' rel='noopener noreferrer' className='navbar-item'>
                  Kibana
                </a>
                <Link to='/debug/reindex' className='navbar-item'>
                  Search Indexing
                </Link>
              </div>
            </div>
            )
          : undefined
      }
      <div className='navbar-item'>
        <Button onClick={logOut} icon={['fas', 'sign-out']}>
          Log out
        </Button>
      </div>
    </>
  )

  const navigation = (currentSession || {}).adminLevel ? navigationLinks : undefined

  const navigationSearchNBurger = (
    <>
      <div className='navbar-item navbar-search__search-field'>
        <InputField
          classes={['is-rounded', 'is-ghost']}
          type='search'
          onChange={handleQueryChange}
          onKeyUp={handleKeyUp}
          placeholder='Search'
          value={searchQuery}
          ref={searchInput}
          buttonProps={{
            onClick: handleSearch,
            classes: ['is-rounded', 'is-ghost'],
            icon: window.innerWidth <= 480 && searchOpen ? ['fas', 'times'] : ['fas', 'search']
            // icon: ['fas', 'search']
          }}
        />
        <Button
          icon={['fas', 'times-circle']}
          onClick={() => {
            setSearchQuery('')
            searchInput.current.focus()
          }}
          classes={['has-text-grey-light', 'is-rounded', 'navbar-search__search-reset', { 'show-reset': searchOpen }]}
        />
      </div>

      <p role='button' className={classNames('navbar-burger', 'burger', { 'is-active': burgerOpen })} aria-label='menu' aria-expanded='false' data-target='navMenu' onClick={() => setBurgerOpen(!burgerOpen)}>
        <span aria-hidden='true' />
        <span aria-hidden='true' />
        <span aria-hidden='true' />
      </p>
    </>
  )

  const topSearchNBurger = (currentSession || {}).adminLevel ? navigationSearchNBurger : undefined

  return (
    <nav className='navbar is-fixed-top has-shadow' role='navigation' aria-label='main navigation'>
      <div className={classNames('navbar-brand', 'navbar-search', { openSearch: searchOpen })}>
        <a className='navbar-item' href='/' tabIndex={-1}>
          <img src='https://cdn.taamusic.com/Admin-Header-Logo.png' alt='TAA Logo' />
        </a>

        {topSearchNBurger}
      </div>

      <div className={classNames('navbar-menu', { 'is-active': burgerOpen })}>
        <div className='navbar-end'>
          {navigation}
        </div>
      </div>
    </nav>
  )
}

export default DesktopNavbar
