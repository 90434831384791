import { useState, useEffect } from 'react'
import { EventEmitter } from 'events'

const globalState = {}
const emitter = new EventEmitter()
emitter.setMaxListeners(Infinity)

const setGlobalState = (name, val) => {
  globalState[name] = val
  emitter.emit(name, val)
}

const useGlobalState = (name, defaultValue = null) => {
  if (globalState[name] === undefined) setGlobalState(name, defaultValue)
  const [state, setState] = useState(globalState[name])

  useEffect(() => {
    setState(globalState[name])
    const updateState = val => setState(val)
    emitter.on(name, updateState)
    return () => emitter.removeListener(name, updateState)
  }, [name, defaultValue])

  return [state, value => setGlobalState(name, value)]
}

export default useGlobalState
