import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import * as yup from 'yup'

import MainLayout from '../../containers/MainLayout'
import { useFrankentangel } from '../../frankentangel'
import FormWrapper from '../../components/FormWrapper'
import FormInputField from '../../components/FormInputField'
import SimpleSelect from '../../components/SimpleSelect'
import SubmitButton from '../../components/SubmitButton'
import FormErrorsList from '../../components/FormErrorsList'
import FormStatus from '../../components/FormStatus'
import FormJoinSelect from '../../components/FormJoinSelect'
import useModal from '../../components/useModal'
import usePasswordInput from '../../components/usePasswordInput'
import Button from '../../components/Button'
import ButtonLink from '../../components/ButtonLink'
import CheckCompliance from './CheckCompliance'
import { Tabs, Tab } from '../../components/Tabs'
import ObjectDelete from '../../components/ObjectDelete'

const User = props => {
  const { id } = useParams()
  const frankentangel = useFrankentangel()
  const [user, setUser] = useState({})
  const [userLoading, setUserLoading] = useState(false)
  const [passwordPopup, setPasswordPopup] = useState(false)
  const [passwordLoading, setPasswordLoading] = useState(false)
  const [userAccounts, setUserAccounts] = useState()

  const [passwordInput, getPassword, resetPassword] = usePasswordInput()
  const [modal, showModal] = useModal()
  const startPassword = () => {
    resetPassword()
    setPasswordPopup(true)
  }

  const cancelPassword = () => {
    setPasswordPopup(false)
    resetPassword()
  }

  const savePassword = async () => {
    const { password, ready } = getPassword()
    if (!ready) return console.error('Password not ready yet')

    setPasswordLoading(true)

    const result = await frankentangel.updateDocument('users', user.id, { password })

    if (result.ok) {
      setPasswordPopup(false)
      setPasswordLoading(false)
      showModal(
        'Password saved',
        `New password for user ${user.firstName} ${user.lastName} successfully saved`,
        [{
          text: 'OK',
          response: 'ok',
          isCancel: true
        }]
      )
    } else {
      const errorMsg = result.error ? (result.error.message || result.error) : 'Unknown Error'
      await showModal(
        'Error',
        `Unknown error while saving password for user ${user.firstName} ${user.lastName}: ${errorMsg}`,
        [{
          text: 'OK',
          response: 'ok',
          isCancel: true
        }]
      )
      setPasswordLoading(false)
    }
  }

  const fetchUser = async () => {
    setUserLoading(true)
    const fetchedUser = await frankentangel.viewDocument('users', Number(id))
    if (fetchedUser.station) setUserAccounts(fetchedUser.station)
    fetchedUser.station = (fetchedUser.station || []).map(account => account.id).join(', ')
    setUser(fetchedUser)
    setUserLoading(false)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    fetchUser()
  }, []) // eslint-disable-line

  const submitUserData = async values => {
    await frankentangel.updateDocument('users', user.id, values)
    fetchUser()
    return 'User settings saved'
  }

  const sendPasswordReset = async () => {
    const response = await showModal(
      'Password Reset',
      "This will reset the user's password and email them a new temporary password. Continue?",
      [{
        text: 'Cancel',
        response: 'no',
        isCancel: true
      }, {
        text: 'Reset',
        className: 'is-danger',
        response: 'yes'
      }]
    )

    if (response !== 'yes') return

    await frankentangel.runAction('users', 'resetPassword', { id: user.id })
  }

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns is-multiline'>
          <div className='column is-full'>
            <h1 className='title'>User Profile</h1>
            <ButtonLink target='/users' icon={['fas', 'arrow-left']} linkText='Back to Users' />&nbsp;
            <ObjectDelete objectName='user' tableName='users' objectId={user.id} objectTitle={`${user.firstName} ${user.lastName}`} />
          </div>
        </div>
        <div className='columns'>
          <div className='column is-half'>
            <FormWrapper
              fields={yup.object({
                firstName: yup.string().max(128).required('Required'),
                lastName: yup.string().max(128).required('Required'),
                phone: yup.string().max(255).phone('Invalid phone number'),
                email: yup.string().email().required('Required'),
                title: yup.string(),
                station: yup.string(),
                isActive: yup.boolean().required('Required'),
                receivesEmails: yup.boolean()
              })}
              initialValuesFrom={user}
              submit={submitUserData}
            >
              <Tabs
                mode='is-boxed'
                headers={['Basic Information', 'Connections']}
                headerIcons={[['fas', 'info-square'], ['fas', 'link']]}
                loading={userLoading}
              >
                <Tab>
                  <FormInputField name='firstName' placeholder='First Name' label='First Name' iconLeft={['fas', 'user']} />
                  <FormInputField name='lastName' placeholder='Last Name' label='Last Name' iconLeft={['fas', 'user']} />
                  <FormInputField
                    name='phone'
                    placeholder='Phone'
                    label='Phone'
                    iconLeft={['fas', 'phone']}
                    phoneType
                    type='tel'
                  />
                  <FormInputField name='email' placeholder='Email Address' label='Email Address' iconLeft={['fas', 'at']} />
                  <FormInputField name='title' placeholder='Title' label='Title' iconLeft={['fas', 'id-card']} suggestions={{ table: 'users', action: 'listTitles' }} />
                  <SimpleSelect name='isActive' opts={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} label='Active' />
                  <SimpleSelect
                    name='receivesEmails'
                    label='Receives Emails'
                    opts={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                    preSelectedValue
                  />
                  <FormErrorsList />
                  <Button onClick={startPassword} classes={['mt-1']} icon={['fas', 'lock-alt']}>Change Password</Button>
                  <Button onClick={sendPasswordReset} classes={['mt-1 ml-2']} icon={['fas', 'sync-alt']}>Reset Password</Button>
                  <br />
                  {userAccounts && (
                    <CheckCompliance accounts={userAccounts} userId={Number(id)}>
                      {
                        user.emailBounced
                          ? (
                            <div className='control'>
                              <div className='tags has-addons'>
                                <span className='tag is-warning'><span className='icon'><FontAwesomeIcon icon={faExclamationTriangle} /></span>&nbsp;Email Bounced</span>
                              </div>
                            </div>
                            )
                          : undefined
                      }
                    </CheckCompliance>
                  )}
                </Tab>
                <Tab>
                  <FormJoinSelect
                    name='station'
                    placeholder='Select Accounts'
                    label='Accounts'
                    table='accounts'
                    multi
                    rows={account => `${account.callsign} ${account.name} (${account.type})`}
                    url='/accounts/%id'
                  />
                </Tab>
              </Tabs>
              <div className='divider has-no-gap' />
              <FormErrorsList />
              <SubmitButton buttonText='Save' />
              <FormStatus />
            </FormWrapper>
          </div>
        </div>
      </div>
      <div className={classNames('modal', {
        'is-active': passwordPopup
      })}
      >
        <div className='modal-background' onClick={cancelPassword} />
        <div className='modal-card'>
          <header className='modal-card-head'>
            <p className='modal-card-title'>Set password for user {user.firstName} {user.lastName}</p>
            <button className='delete' aria-label='close' onClick={cancelPassword} />
          </header>
          <section className='modal-card-body user-row__password-prompt'>
            {passwordInput}
            <div className={classNames('user-row__password-loading', {
              'user-row__password-loading--enabled': passwordLoading
            })}
            >
              <FontAwesomeIcon icon={['fas', 'sync-alt']} spin size='10x' />
            </div>
          </section>
          <footer className='modal-card-foot'>
            <Button classes={['is-dark', 'mod-disabled']} disabled={!getPassword().ready || passwordLoading} icon={['fas', 'save']} onClick={savePassword}>
              Save password
            </Button>
            &nbsp;
            <Button icon={['fas', 'times-circle']} onClick={cancelPassword}>
              Cancel
            </Button>
          </footer>
        </div>
      </div>
      {modal}
    </MainLayout>
  )
}

export default User
