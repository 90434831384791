import React from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons'

const StatCard = props => {
  const { title, firstValue, secondValue, content, linksTo, mode, loading } = props
  const history = useHistory()

  const statValue = secondValue ? `${firstValue}/${secondValue}` : firstValue

  const handleNavigation = () => history.push(linksTo)

  if (mode === 'wide') {
    return (
      <div className='column is-full'>
        <div className='columns is-clickable' onClick={handleNavigation}>
          <div className='column is-full'>
            <p className='is-size-4-mobile has-text-weight-bold has-text-centered-mobile is-inline-block-tablet statcard__wide-number'>{loading ? <FontAwesomeIcon icon={faSyncAlt} spin /> : statValue}</p>
            <p className='has-text-centered-mobile is-inline-block-tablet'>{content}</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='column is-one-fifth-widescreen is-one-third-desktop is-half-tablet is-flex'>
      <div className='card has-text-centered stat-card' onClick={handleNavigation}>
        <div className='card-image'>
          <p className={classNames('py-2', 'has-text-weight-bold', 'has-text-white', 'has-background-black', 'is-size-5')}>
            {title}
          </p>
        </div>
        <div className='card-content'>
          <p className='title is-2'>{loading ? <FontAwesomeIcon icon={faSyncAlt} spin /> : statValue}</p>
          <p className='is-6'>{content}</p>
        </div>
      </div>
    </div>
  )
}

export default StatCard
