import React from 'react'
import MainLayout from '../containers/MainLayout'
import FormWrapper from '../components/FormWrapper'
import FormInputField from '../components/FormInputField'
import FormStatus from '../components/FormStatus'
import SubmitButton from '../components/SubmitButton'
import { useFrankentangel } from '../frankentangel'
import * as yup from 'yup'

const Login = props => {
  const frankentangel = useFrankentangel()

  const submitFunction = async values => {
    const result = await frankentangel.runAction('users', 'login', values)
    if (!result.adminLevel) {
      await frankentangel.runAction('users', 'logout')
      throw new Error('The account is with you, young user, but you are not an Admin yet!')
    }
    return 'User login was successful'
  }

  return (
    <MainLayout fullheight>
      <div className='container has-text-centered'>
        <div className='columns is-centered'>
          <div className='column is-one-third'>
            <div className='box has-text-left'>
              <h1 className='title'>Login</h1>
              <FormWrapper
                fields={yup.object({
                  email: yup.string('Letters only').required('Required').email('Please provide a valid email address'),
                  password: yup.string('Letters only').required('Required').min(2, 'Min. 2 chars').max(50, 'Max. 50 chars')
                })}
                submit={submitFunction}
              >
                <FormInputField name='email' type='email' placeholder='Your Email Address' label='Email Address' />
                <FormInputField name='password' placeholder='Your Password' label='Password' type='password' />
                <SubmitButton buttonText='Login' buttonIcon={['fas', 'sign-in']} />
                <FormStatus />
              </FormWrapper>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Login
