import React from 'react'

// import MainLayout from '../../containers/MainLayout'
import LoadingScreen from '../LoadingScreen'

const AffidavitsAccountWeek = props => {
  return <LoadingScreen />
}

export default AffidavitsAccountWeek
