import React from 'react'

import StatCard from '../StatCard'
import { useAction } from '../../frankentangel'

const EmailBouncedBox = props => {
  const [emailBounced,, emailBouncedLoading] = useAction('users', 'numEmailBounced')

  return (
    <StatCard
      title='Emails'
      firstValue={emailBounced?.total}
      content='Bounced Emails'
      linksTo='/reports/bounced-emails'
      loading={emailBouncedLoading}
      mode='wide'
    />
  )
}

export default EmailBouncedBox
