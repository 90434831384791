import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

const ContractCard = props => {
  const { contract } = props
  const history = useHistory()
  const [goEdit, triggerEdit] = useState(false)

  useEffect(() => {
    if (goEdit) history.push(`/contracts/${contract.id}`)
  })

  return (
    <div className='column is-one-third'>
      <div className='card' onClick={() => triggerEdit(true)}>
        <div className='card-image'>
          <small className={classNames('px-2 px-2 py-1 has-text-weight-bold is-block has-text-white', {
            'has-background-grey-light': contract.contractType === 'Barter',
            'has-background-dark': contract.contractType === 'Barter Settlement',
            'has-background-black': contract.contractType === 'Cash',
            'has-background-grey': contract.contractType === 'Library Rights Only'
          })}
          >
            {contract.contractType}
          </small>
        </div>
        <div className='card-content'>
          <p className='title is-4'>{contract.name}</p>
          <p className='subtitle is-6'>{`${moment(contract.startDate).format('MMM Do YYYY')} - ${moment(contract.expirationDate).format('MMM Do YYYY')}`}</p>
        </div>
      </div>
    </div>
  )
}

export default ContractCard
