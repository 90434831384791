import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import MainLayout from '../../containers/MainLayout'
import InputField from '../../components/InputField'
import toWeekDay from '../../utils/to-weekday'
import useGlobalState from '../../components/useGlobalState'
import CommsManager from './CommsManager'

const Commercials = props => {
  const [, setMonday] = useGlobalState('monday', '')
  const [date, setDate] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const history = useHistory()

  // get comms -> get weeks -> sort by weeks

  const handleDayChange = ev => {
    setDate(ev.target.value)
    if (errorMsg !== '') setErrorMsg('')
  }

  const handleSelectDate = () => {
    if (!date.match(/^[0-1]?[0-9]\/[0-3][0-9]\/[0-9][0-9]$/)) {
      setErrorMsg('Invalid date')
      return false
    }
    if (toWeekDay(date, 'Mon').toString() !== 'Invalid Date') {
      setMonday(toWeekDay(date, 'Mon'))
      history.push('/commercials/create')
    } else {
      setErrorMsg('Invalid date')
    }
  }

  const handleKeyUp = ev => {
    if ((ev.key === 'Enter')) handleSelectDate()
    if (ev.metaKey && ev.key === 'Enter') handleSelectDate()
    if (ev.ctrlKey && ev.key === 'Enter') handleSelectDate()
  }

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns is-multiline'>
          <div className='column is-full'>
            <h1 className='title'>Commercials</h1>
          </div>
        </div>
        <div className='columns'>
          <div className='column is-half'>
            <InputField
              classes={['is-rounded', 'is-dark']}
              type='text'
              onChange={handleDayChange}
              onKeyUp={handleKeyUp}
              placeholder='Enter date MM/DD/YY'
              value={date}
              buttonProps={{
                onClick: handleSelectDate,
                classes: ['is-rounded', 'is-dark']
              }}
              buttonText='Add New Commercial'
            />
            {errorMsg !== '' && (<p className='help is-danger'>{errorMsg}</p>)}
          </div>
        </div>
        <CommsManager />
      </div>
    </MainLayout>
  )
}

export default Commercials
