import React, { useState } from 'react'
import classNames from 'classnames'
import useModal from './useModal'
import unwrap from 'async-unwrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Button = props => {
  const { id, disabled, children, classes, type, onClick, icon, preventDefault, stopPropagation } = props

  const [isLoading, setLoading] = useState(false)

  const [modal, showModal] = useModal()

  const handleOnClick = async (ev) => {
    if (preventDefault) ev.preventDefault()
    if (stopPropagation) ev.stopPropagation()

    setLoading(true)
    const [error] = await Promise.resolve(onClick())[unwrap]
    if (error) {
      console.log('got error on button', error)
      const errorMsg = error ? (error.message || error) : 'Unknown Error'
      await showModal('Error', errorMsg, [{ text: 'OK', className: 'is-danger', response: 'OK' }])
    }
    setLoading(false)
  }

  const buttonProperties = {
    type: type || 'button',
    disabled: disabled || isLoading,
    className: classNames('button', { 'is-loading': isLoading }, classes),
    id,
    onClick: onClick ? handleOnClick : null
  }

  return (
    <>
      <button {...buttonProperties}>
        {icon && (<span className='icon'><FontAwesomeIcon icon={icon} /></span>)}
        {children && <span>{children}</span>}
      </button>
      {modal}
    </>
  )
}

export default Button
