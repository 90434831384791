import React from 'react'

import StatCard from '../StatCard'
import { useAction } from '../../frankentangel'

const TotalTracksDownloadedSum = props => {
  const [totalTracksDownloaded,, totalTracksDownloadedLoading] = useAction('dashboard-music-download', 'numRecently')

  return (
    <StatCard
      title='Music'
      firstValue={totalTracksDownloaded?.last7Days}
      secondValue={totalTracksDownloaded?.last30Days}
      content='Total Tracks Downloaded Past 7 Days / Past 30 Days'
      linksTo='/reports/total-tracks-downloaded'
      loading={totalTracksDownloadedLoading}
    />
  )
}

export default TotalTracksDownloadedSum
