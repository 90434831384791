import React, { useState } from 'react'
import * as yup from 'yup'
import classNames from 'classnames'

import MainLayout from '../../containers/MainLayout'
import ErrorPage from '../ErrorPage'

import FormWrapper from '../../components/FormWrapper'
import FormInputField from '../../components/FormInputField'
import FormStatus from '../../components/FormStatus'
import SubmitButton from '../../components/SubmitButton'

import { useAction, useFrankentangel, useList } from '../../frankentangel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faSync, faServer } from '@fortawesome/pro-solid-svg-icons'

const ReindexTask = ({ task }) => {
  const elapsed = new Date().getTime() - new Date(task.startedAt).getTime()
  const averageLength = elapsed / task.rowsDone
  const remaining = (task.totalRows - task.rowsDone) * averageLength

  const elapsedSeconds = String(Math.floor(elapsed / 1000) % 60).padStart(2, '0') + '.' + Math.floor(elapsed / 100) % 10
  const elapsedMinutes = Math.floor(elapsed / 60000) % 60

  const remainingSeconds = String(Math.floor(remaining / 1000) % 60).padStart(2, '0') + '.' + Math.floor(remaining / 100) % 10
  const remainingMinutes = Math.floor(remaining / 60000) % 60

  return (
    <div className='column is-full'>
      <div className='card'>
        <div className='card-header'>
          <div className='card-header-title'>
            <span className={classNames('tag', { 'is-dark': task.done })}>
              {task.done ? 'Done' : 'Pending'}
            </span>
            <span className='ml-1'>
              {task.tableName}
            </span>
          </div>
          <div className='card-header-icon'>
            <FontAwesomeIcon icon={task.done ? faCheckCircle : faSync} spin={!task.done} />
          </div>
        </div>
        <div className='card-content'>
          <div className='level'>
            <div className='level-left'>
              <div className='block'>
                <p>
                  Reindexing table <strong>{task.tableName}</strong>
                </p>
                <p>
                  Started at {new Date(task.startedAt).toLocaleString()}
                </p>
                <p>
                  <strong>{task.rowsDone}</strong> of <strong>{task.totalRows}</strong> rows indexed
                </p>
              </div>
            </div>
            <div className='level-right'>
              <div className='block'>
                <p>Elapsed time: {elapsedMinutes}:{elapsedSeconds}</p>
                {
                  isNaN(remainingMinutes)
                    ? undefined
                    : (
                      <p>Remaining: {remainingMinutes}:{remainingSeconds}</p>
                      )
                }
              </div>
            </div>
          </div>
          <progress className='progress' value={task.rowsDone} max={task.totalRows} />
        </div>
      </div>
    </div>
  )
}

const ReindexTaskWithID = ({ id }) => {
  const [task] = useAction('admin', 'showReindexTask', { id })

  if (!task) return null
  return <ReindexTask task={task} />
}

const Reindex = props => {
  const [Paginator, tasks, error] = useList('admin', 'listReindexTasks')
  const frankentangel = useFrankentangel()

  const [startedTasks, setStartedTasks] = useState([])

  // if (loading) return <LoadingScreen />
  if (error) return <ErrorPage error={error} />

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <Paginator className='columns is-multiline'>
          <div className='column is-full'>
            <h1 className='title'>Reindex Table</h1>
          </div>
          <div className='column is-full'>
            <FormWrapper
              fields={yup.object({
                table: yup.string()
              })}
              submit={async (values) => {
                const taskStarted = await frankentangel.runAction('admin', 'reindexTable', { tableName: values.table })
                if (taskStarted.ok) setStartedTasks(tasks => [...tasks, taskStarted.id])

                return 'Reindex started'
              }}
            >
              <FormInputField name='table' type='string' placeholder='Table Name' label='Table' />
              <SubmitButton buttonText='Reindex' buttonIcon={faServer} />
              <FormStatus />
            </FormWrapper>
          </div>
          {startedTasks.map(id => <ReindexTaskWithID id={id} key={id} />)}
          <div className='column is-full'>
            <h1 className='title'>Existing Reindex Tasks</h1>
          </div>
          {tasks.map(task => <ReindexTaskWithID id={task.id} key={task.id} />)}
        </Paginator>
      </div>
    </MainLayout>
  )
}

export default Reindex
