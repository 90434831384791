import React, { useState } from 'react'
import * as yup from 'yup'

import MainLayout from '../../containers/MainLayout'
import { useFrankentangel } from '../../frankentangel'
import FormWrapper from '../../components/FormWrapper'
import FromInputField from '../../components/FormInputField'
import SubmitButton from '../../components/SubmitButton'
import FormErrorsList from '../../components/FormErrorsList'
import FormStatus from '../../components/FormStatus'
import FormUsePasswordInput from '../../components/FormUsePasswordInput'
import ButtonLink from '../../components/ButtonLink'
import SimpleSelect from '../../components/SimpleSelect'

const CreateAdminUser = props => {
  const frankentangel = useFrankentangel()

  const [passwordState, setPasswordState] = useState({
    password: '',
    confirmPassword: '',
    strength: 0,
    matching: true
  })

  const resetPasswordState = (enabled) => setPasswordState({
    password: '',
    confirmPassword: '',
    strength: 0,
    matching: true
  })

  const resetPassword = () => resetPasswordState()

  const submitUserData = async values => {
    const additionalValues = {
      isAdmin: true,
      isSuperAdmin: false,
      emailBounced: false
    }
    await frankentangel.createDocument('users', { ...values, ...additionalValues })
    resetPassword()
    return 'New User is Created'
  }

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns is-multiline'>
          <div className='column is-full'>
            <h1 className='title'>Create Admin User</h1>
            <ButtonLink target='/admin-users' icon={['fas', 'arrow-left']} linkText='Back to Admin Users' />
          </div>
        </div>
        <div className='columns'>
          <div className='column is-half'>
            <FormWrapper
              fields={yup.object({
                firstName: yup.string().max(128, 'Max 128 chars').required('Required'),
                lastName: yup.string().max(128, 'Max 128 chars').required('Required'),
                phone: yup.string().max(255).phone('Invalid phone number'),
                email: yup.string().email('Please provide a valid email address').required('Required'),
                password: yup.string().required('Required').min(2, 'Min 2 chars').max(50, 'Max 50 chars'),
                isActive: yup.boolean(),
                receivesEmails: yup.boolean()
              })}
              submit={submitUserData}
            >
              <FromInputField name='firstName' placeholder='First Name' label='First Name' iconLeft={['fas', 'user']} />
              <FromInputField name='lastName' placeholder='Last Name' label='Last Name' iconLeft={['fas', 'user']} />
              <FromInputField
                name='phone'
                placeholder='Phone'
                label='Phone'
                iconLeft={['fas', 'phone']}
                phoneType
                type='tel'
              />
              <FromInputField name='email' placeholder='Email Address' label='Email Address' iconLeft={['fas', 'at']} />
              <FormUsePasswordInput passwordState={passwordState} setPasswordState={setPasswordState} resetPasswordState={resetPasswordState} />
              <SimpleSelect
                name='isActive'
                label='Active'
                opts={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                preSelectedValue
              />
              <SimpleSelect
                name='receivesEmails'
                label='Receives Emails'
                opts={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                preSelectedValue
              />
              <FormErrorsList />
              <SubmitButton buttonText='Save' classes={['has-medium-top-margin']} />
              <FormStatus />
            </FormWrapper>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CreateAdminUser
