import React, { useState } from 'react'
import MainLayout from '../../containers/MainLayout'
import { useSearchableList } from '../../frankentangel'
import ButtonLink from '../../components/ButtonLink'
import InputField from '../../components/InputField'
import ErrorPage from '../ErrorPage'
import UserCard from '../../components/UserCard'

const Users = props => {
  const [searchText, setSearchText] = useState('')

  const [Paginator, result, error, loading] = useSearchableList('users', searchText)

  if (error) {
    return <ErrorPage error={error} />
  }

  const users = result ? result.map(user => <UserCard user={user.doc} key={user.doc.id} />) : null

  return (
    <MainLayout fullheight alignstart>
      <div className='container'>
        <div className='columns is-multiline'>
          <div className='column is-full'>
            <h1 className='title'>Users</h1>
            <ButtonLink target='/users/create' classes={['is-dark']} icon={['fas', 'user-plus']} linkText='Create User' />
          </div>
        </div>
        <div className='columns is-multiline'>
          <div className='column is-half'>
            <InputField loading={loading} label='Search Users' placeholder='Type here' value={searchText} onChange={ev => setSearchText(ev.target.value)} />
          </div>
        </div>
        <Paginator className='columns is-multiline'>
          {users}
        </Paginator>
      </div>
    </MainLayout>
  )
}

export default Users
